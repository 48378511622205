<template>
	<div class="p-10">
		<div class="text-xl font-bold"><i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>钓法钓技管理</div>
		<div class="flex items-baseline">
			<div class="pr-10" style="border-right:rgba(200, 200, 200, 1) solid 1px;height:650px;width:250px">
				<div class=" flex justify-between items-center mt-5 mb-3 ml-5">
					<div><p class=" font-bold text-xl" style="color:rgba(80, 80, 80, 1)">分类</p></div>
					<div><i class="el-icon-circle-plus text-xl cursor-pointer" @click=";(isClass = 2), addClass()"></i></div>
				</div>
				<el-tree ref="treeBox" :data="data" node-key="treeId" default-expand-all :expand-on-click-node="true" :highlight-current="true">
					<span
						class="custom-tree-node"
						slot-scope="{ node, data }"
						style="width:150px;font-size:18px"
						@click="data.children ? '' : getSkill(node)"
					>
						<span v-if="data" style="margin-top:10px;">{{ data.class }}</span>
						<span style="float:right" @click.stop>
							<el-dropdown trigger="click" size="mini" placement="bottom-start">
								<i class="el-icon-more"></i>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item v-if="data.children" @click.native=";(isClass = 3), addClass(data)"
										>新增子分类</el-dropdown-item
									>
									<el-dropdown-item
										@click.native="data.children ? ((isClass = 2), updClass(data)) : ((isClass = 3), updThreeClass(node))"
										>编辑分类</el-dropdown-item
									>
									<el-dropdown-item @click.native="data.children ? delSkillClass(data) : delSkillType(node)"
										>删除分类</el-dropdown-item
									>
									<el-dropdown-item>取消</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</span>
					</span>
				</el-tree>
			</div>
			<div class="flex-auto ml-5">
				<div>
					<el-breadcrumb separator-class="el-icon-arrow-right" style="font-size:18px;">
						<el-breadcrumb-item>全部</el-breadcrumb-item>
						<el-breadcrumb-item>钓法钓技</el-breadcrumb-item>
						<el-breadcrumb-item>{{ !isSearch ? this.class : "搜索" }}</el-breadcrumb-item>
						<el-breadcrumb-item v-if="!isSearch">{{ this.type }}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="mt-5 flex">
					<div>
						<el-input v-model="input" placeholder="搜索名称" style="width: 170px"></el-input>
					</div>
					<div class="search ml-4 mt-1 cursor-pointer" @click="clickSearch()">
						<i class="cuIcon-search"></i>
					</div>
					<div class="search ml-4 mt-1 text-white cursor-pointer" style="background: rgba(117, 199, 77, 1)" @click="reset()">
						<i class="el-icon-refresh"></i>
					</div>
					<div class="search ml-4 mt-1 bg-blue-500 text-white cursor-pointer" @click="openFish()">
						<i class="cuIcon-add"></i>
					</div>
				</div>
				<div class="mt-5 border-2">
					<el-table :data="tableData" stripe :header-cell-style="{ background: '#fafafa' }" style="width: 100%">
						<el-table-column prop="skill" label="钓法类型"></el-table-column>
						<el-table-column label="启用状态">
							<template slot-scope="scope">
								<el-switch
									v-model="scope.row.status"
									active-value="1"
									inactive-value="0"
									active-color="#13ce66"
									inactive-color="#bebebe"
									@change="updSkill(scope.row)"
								></el-switch>
							</template>
						</el-table-column>
						<el-table-column prop="createTime" label="创建时间"></el-table-column>
						<el-table-column label="操作" width="150">
							<template slot-scope="scope">
								<el-button style="text-decoration: underline" @click="handleClick(scope.row)" type="text">编辑</el-button>
								<el-button style="text-decoration: underline; color: red" type="text" @click="delSkill(scope.row)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<pagination :currentPage="pages" :total="total" @pageChange="changePages"></pagination>
			</div>
		</div>

		<!-- 新增对话框 -->
		<el-dialog :visible.sync="dialog" width="400px">
			<el-form>
				<el-form-item :label="isClass == 2 ? '二级分类名称' : '三级分类名称'" label-width="100px">
					<el-input v-model="formClass" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div class="mt-5 text-center">
				<el-button size="small" class="mr-10" @click="dialog = false">取消</el-button>
				<el-button type="primary" size="small" @click="isClass == 2 ? addSkillClass() : addSkillType()">确定</el-button>
			</div>
		</el-dialog>

		<!-- 编辑对话框 -->
		<el-dialog :visible.sync="dialog1" width="400px" :close-on-click-modal="false">
			<el-form>
				<el-form-item :label="isClass == 2 ? '二级分类名称' : '三级分类名称'" label-width="100px">
					<el-input v-model="formClass" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div class="mt-5 text-center">
				<el-button size="small" class="mr-10" @click="dialog1 = false">取消</el-button>
				<el-button type="primary" size="small" @click="isClass == 2 ? updSkillClass() : updSkillType()">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import pagination from "../../../components/Pagination/pagination.vue"
export default {
	components: { pagination },
	data() {
		return {
			input: "",
			tableData: [],
			dialog: false,
			dialog1: false,
			isClass: 2,
			form: { skill: "", status: "" },
			total: 0, //总页数
			pages: 1, //当前页数
			limit: 5,
			data: [],
			formData: "",
			formClass: "",
			class: "",
			type: "",
			isSearch: false,
			treeId: "",
		}
	},

	mounted() {
		this.getSkillClass()
	},
	activated() {
		if (!this.input) {
			this.getSkill()
		} else {
			this.searchSkill()
		}
	},
	methods: {
		//分页
		changePages(data) {
			this.pages = data._currentPage
			this.limit = data._pageSize
			if (!this.input) {
				this.getSkill()
			} else {
				this.searchSkill()
			}
		},
		//点击查询
		clickSearch() {
			this.pages = 1
			// console.log(!this.input)
			if (this.input == "") {
				this.getSkill()
			} else {
				this.searchSkill()
			}
		},
		//点击重置
		reset() {
			this.input = ""
			this.pages = 1
			this.getSkill()
		},
		//搜索钓法钓技
		async searchSkill() {
			const res = await this.http
				.post("admin/skill/searchSkill", {
					keyword: this.input,
					pages: this.pages,
					limit: this.limit,
				})
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.isSearch = true
				this.tableData = res.data.skill
				this.total = res.data.total
			}
		},
		//获取钓法钓技分类
		async getSkillClass() {
			const res = await this.http.post("admin/skill/getSkillClass", {}, false).catch((err) => {
				console.log(err)
			}, false)
			if (res.code == 1) {
				this.data = JSON.parse(JSON.stringify(res.data))
				var num = 0
				for (let i in this.data) {
					for (let item in this.data[i].children) {
						this.data[i].children[item].treeId = ++num
					}
				}
				if (this.treeId == "") {
					this.class = this.data[0].class
					this.type = this.data[0].children[0].class
					this.treeId = this.data[0].children[0].treeId
				}
				this.getSkill()
			}
		},
		//新增分类
		addClass(data) {
			this.formClass = ""
			this.dialog = true
			if (data) {
				this.formData = data
			}
		},
		//新增二级分类
		async addSkillClass() {
			if (this.formClass.length > 5) {
				this.$message("字数不能超过5个字！")
			} else {
				const res = await this.http
					.post("admin/skill/addSkillClass", {
						class: this.formClass,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.getSkillClass()
					this.dialog = false
				}
			}
		},

		//新增三级分类
		async addSkillType() {
			if (this.formClass.length > 5) {
				this.$message("字数不能超过5个字！")
			} else {
				const res = await this.http
					.post("admin/skill/addSkillType", {
						class: this.formData.class,
						type: this.formClass,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.getSkillClass()
					this.dialog = false
				}
			}
		},
		//编辑分类
		updClass(data) {
			this.formClass = data.class
			this.dialog1 = true
			if (data) {
				this.formData = data
			}
		},
		//编辑二级分类
		async updSkillClass() {
			if (this.formClass.length > 5) {
				this.$message("字数不能超过5个字！")
			} else {
				const res = await this.http
					.post("admin/skill/updSkillClass", {
						classId: this.formData.id,
						oldClass: this.formData.class,
						newClass: this.formClass,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.getSkillClass()
					this.dialog1 = false
				}
			}
		},

		//打开编辑三级分类
		updThreeClass(node) {
			this.formClass = node.data.class
			this.parentClass = node.parent.data.class
			this.dialog1 = true
			if (node) {
				this.formData = node.data
			}
		},

		//编辑三级分类
		async updSkillType() {
			if (this.formClass.length > 5) {
				this.$message("字数不能超过5个字！")
			} else {
				const res = await this.http
					.post("admin/skill/updSkillType", {
						typeId: this.formData.id,
						oldType: this.formData.class,
						newType: this.formClass,
						class: this.parentClass,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.getSkillClass()
					this.dialog1 = false
				}
			}
		},

		//删除工具二级分类
		async delSkillClass(data) {
			this.$confirm("此操作将永久删除该二级分类, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/skill/delSkillClass", {
							class: data.class,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						this.getSkillClass()
					}
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					})
				})
		},

		//删除工具三级分类
		async delSkillType(node) {
			this.$confirm("此操作将永久删除该三级分类, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/skill/delSkillType", {
							class: node.parent.data.class,
							type: node.data.class,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						this.getSkillClass()
					}
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					})
				})
		},

		//获取钓法钓技
		async getSkill(node) {
			if (node) {
				this.class = node.parent.data.class
				this.type = node.data.class
				this.treeId = node.data.treeId
			}
			const res = await this.http
				.post(
					"admin/skill/getSkill",
					{
						class: this.class,
						type: this.type,
						pages: this.pages,
						limit: this.limit,
					},
					false
				)
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.isSearch = false
				this.tableData = res.data.skill
				this.total = res.data.total
				this.$nextTick(() => {
					this.$refs["treeBox"].setCurrentKey(this.treeId)
				})
			}
		},

		//打开新建钓法
		openFish() {
			this.$router.push({
				path: "fishMeSkDetail",
			})
		},
		//点击编辑
		handleClick(row) {
			this.$router.push({
				path: "fishMeSkDetail",
				query: { id: row.id, treeId: this.treeId },
			})
		},
		//删除钓法钓技
		delSkill(row) {
			this.$confirm("此操作将永久删除该钓法钓技, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/skill/delSkill", {
							skillId: row.id,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						this.getSkill()
					}
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					})
				})
		},
		//开启&关闭状态
		async updSkill(row) {
			let flag = row.status
			row.status == "1" ? (row.status = "0") : (row.status = "1")
			this.$confirm("是否改变该状态", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/skill/updSkill", {
							skillId: row.id,
							status: flag,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						flag == "1" ? (row.status = "1") : (row.status = "0") // 这一步很重要，row.showState会根据flag的值开启或关闭开关
					}
				})
				.catch(() => {
					this.$message({ type: "info", showClose: true, message: "已取消修改！" })
				})
		},
	},
}
</script>

<style lang="scss" scoped>
.search {
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	border-radius: 50%;
	border: rgb(175, 174, 174) solid 1px;
}
/deep/.el-breadcrumb::after,
.el-breadcrumb::before {
	display: inline-block;
}
</style>
