<template>
	<div class="pl-10 pt-3 pb-4">
		<el-tabs v-model="activeName" type="card">
			<el-tab-pane label="用户管理" name="first">
				<div class="mt-3">
					<div style="font-size:22px" class=" mt-5 flex items-center">
						<div class="mr-10" style="font-size:20px;">
							当前在线人数：<span class="font-bold" style="color:rgba(24, 144, 255, 1);">{{ userData.nowOnline }}</span>
						</div>
						<div class="mr-10">
							<el-select v-if="options" v-model="value" placeholder="请选择年份" @change="changeYear">
								<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
							</el-select>
						</div>
						<div>
							<el-select
								v-model="value1"
								multiple
								filterable
								allow-create
								default-first-option
								:collapse-tags="true"
								placeholder="请选择月份"
								@change="changeMonths"
							>
								<el-option v-for="item in months" :key="item.value" :label="item.label" :value="item.value"> </el-option>
							</el-select>
						</div>
					</div>
					<Hiscard v-if="userData" :monthOnline="monthOnline" />
					<div class="mt-3" style="font-size:20px" v-if="userData">
						近月新增用户人数：<span class="font-bold" style="color:rgba(24, 144, 255, 1);">{{ userData.addUser.total }}</span>
					</div>
					<Linecard v-if="userData" :monthAdd="userData.monthAdd" />

					<div style="font-size:20px">历史新增记录：</div>
					<div class=" mt-10 flex ml-20">
						<div class=" w-56 h-28 border shadow-sm mr-20">
							<div class=" pt-4 pl-4">上个月用户数量</div>
							<div class="flex items-center pl-7 pt-3">
								<div v-if="userData">
									<i
										class="text-4xl"
										:class="
											userData.lastPct.trend == 1
												? 'el-icon-top green-zi'
												: userData.lastPct.trend == -1
												? 'el-icon-bottom text-red-700'
												: 'el-icon-minus text-red-700'
										"
									></i>
								</div>
								<div
									class=" text-xl ml-4"
									:class="
										userData.lastPct.trend == 1 ? 'green-zi' : userData.lastPct.trend == -1 ? ' text-red-700' : ' text-red-700'
									"
									v-if="userData"
								>
									{{ userData.lastPct.pct }}
								</div>
							</div>
						</div>
						<div class=" w-56 h-28 border shadow-sm">
							<div class=" pt-4 pl-4">这个月用户数量</div>
							<div class="flex items-center pl-7 pt-3">
								<div v-if="userData">
									<i
										class="el-icon-top text-red-700 text-4xl"
										:class="
											userData.nowPct.trend == 1
												? 'el-icon-top green-zi'
												: userData.nowPct.trend == -1
												? 'el-icon-bottom text-red-700'
												: 'el-icon-minus text-red-700'
										"
									></i>
								</div>
								<div
									class=" text-xl ml-4"
									:class="userData.nowPct.trend == 1 ? 'green-zi' : userData.nowPct.trend == -1 ? 'text-red-700' : 'text-red-700'"
									v-if="userData"
								>
									{{ userData.nowPct.pct }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="约钓数据" name="second" :lazy="true">
				<div style="width:100%">
					<Catecard v-if="inviteData" :monthData="inviteMonthData"></Catecard>
					<div style="font-size:22px">当月约钓数：</div>
					<div class=" mt-10 flex ml-20">
						<div class=" w-56 h-28 border shadow-sm mr-20">
							<div class=" pt-4 pl-4">成功数量</div>
							<div class="flex items-center pl-7 pt-3">
								<div v-if="inviteData">
									<i
										class="text-4xl"
										:class="
											inviteData.suc.trend == 1
												? 'el-icon-top green-zi'
												: inviteData.suc.trend == -1
												? 'el-icon-bottom text-red-700'
												: 'el-icon-minus text-red-700'
										"
									></i>
								</div>
								<div
									class=" text-xl ml-4"
									:class="inviteData.suc.trend == 1 ? 'green-zi' : inviteData.suc.trend == -1 ? ' text-red-700' : ' text-red-700'"
									v-if="inviteData"
								>
									{{ inviteData.suc.pct }}
								</div>
							</div>
						</div>
						<div class=" w-56 h-28 border shadow-sm">
							<div class=" pt-4 pl-4">失败数量</div>
							<div class="flex items-center pl-7 pt-3">
								<div v-if="inviteData">
									<i
										class="el-icon-top text-red-700 text-4xl"
										:class="
											inviteData.fail.trend == 1
												? 'el-icon-top green-zi'
												: inviteData.fail.trend == -1
												? 'el-icon-bottom text-red-700'
												: 'el-icon-minus text-red-700'
										"
									></i>
								</div>
								<div
									class=" text-xl ml-4"
									:class="inviteData.fail.trend == 1 ? 'green-zi' : inviteData.fail.trend == -1 ? 'text-red-700' : 'text-red-700'"
									v-if="inviteData"
								>
									{{ inviteData.fail.pct }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import Hiscard from "../statistics/components/Hiscard.vue"
import Linecard from "../statistics/components/Linecard.vue"
import Catecard from "../statistics/components/Catecard.vue"
export default {
	components: {
		Hiscard,
		Linecard,
		Catecard,
	},
	data() {
		return {
			userData: "",
			monthOnline: [],
			inviteData: "",
			inviteMonthData: [],
			activeName: "first",
			options: [],
			value: "请选择年份",
			months: [
				{
					value: "1",
					label: "1月",
				},
				{
					value: "2",
					label: "2月",
				},
				{
					value: "3",
					label: "3月",
				},
				{
					value: "4",
					label: "4月",
				},
				{
					value: "5",
					label: "5月",
				},
				{
					value: "6",
					label: "6月",
				},
				{
					value: "7",
					label: "7月",
				},
				{
					value: "8",
					label: "8月",
				},
				{
					value: "9",
					label: "9月",
				},
				{
					value: "10",
					label: "10月",
				},
				{
					value: "11",
					label: "11月",
				},
				{
					value: "12",
					label: "12月",
				},
			],
			value1: "请选择月份",
		}
	},
	mounted() {
		this.getUserData()
		this.getInvite()
		this.getYear()
	},
	methods: {
		//获取年份
		getYear() {
			let date = new Date()
			date.getFullYear()
			let ops = [
				{
					value: date.getFullYear() - 2,
					label: date.getFullYear() - 2,
				},
				{
					value: date.getFullYear() - 1,
					label: date.getFullYear() - 1,
				},
				{
					value: date.getFullYear(),
					label: date.getFullYear(),
				},
				{
					value: date.getFullYear() + 1,
					label: date.getFullYear() + 1,
				},
			]
			this.options = ops
		},
		//选择月份
		changeMonths() {
			let arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
			if (this.value1.length == 0) {
				this.monthOnline = this.userData.monthOnline
			} else {
				for (let i of this.value1) {
					arr[Number(i) - 1] = this.userData.monthOnline[Number(i) - 1]
				}
				this.monthOnline = arr
			}
		},
		//选择年份
		changeYear() {
			this.getUserData(this.value)
		},
		//用户数据
		async getUserData(e) {
			const res = await this.http.post("admin/statistics/getUserData", { year: e }, false).catch((err) => {
				console.log(err)
			})
			if (res.code == 1) {
				this.userData = res.data
				this.monthOnline = res.data.monthOnline
			}
		},
		//约钓数据
		async getInvite() {
			const res = await this.http.post("admin/statistics/getInvite", {}, false).catch((err) => {
				console.log(err)
			})
			if (res.code == 1) {
				this.inviteData = res.data
				let arr = []
				for (let i in res.data.monthData) {
					let obj = { product: "", 不顺路: "", 其他: "", 太多装备放不下: "", 距离太远: "" }
					obj.product = Number(i) + 1 + "月"
					let a = Object.assign(obj, res.data.monthData[i])
					// res.data.monthData[i].product = Number(i) + 1 + "月"
					arr.push(a)
				}
				this.inviteMonthData = arr
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.green-zi {
	color: rgba(67, 207, 124, 1);
}
</style>
