<template>
	<div id="tags-view-container" class="tags-view-container">
		<scroll-pane ref="scrollPane" class="tags-view-wrapper">
			<router-link
				v-for="i in visitedView"
				:key="i.path"
				class="tags-view-item"
				:class="isActive(i) ? 'active' : ''"
				:to="{ path: i.path, query: i.query }"
			>
				{{ i.meta.title }}
				<span v-if="i.name != 'userdata'" @click.prevent.stop="closeSelectedTag(i)" class="el-icon-close" />
			</router-link>
		</scroll-pane>
	</div>
</template>

<script>
import ScrollPane from "./ScrollPane"
export default {
	components: {
		ScrollPane,
	},
	data() {
		return {}
	},
	computed: {
		//通过计算属性获取用户访问过的页面，用计算属性的好处时当数值没变化的时候不用执行计算逻辑，就是缓存
		visitedView() {
			return this.$store.state.visitedView
		},
	},
	watch: {
		//监听路由变化，生成标签导航
		$route() {
			let { name, fullPath, path, meta, query } = this.$route
			let viewObj = { name: name, fullPath: fullPath, path: path, meta: meta, query: query }
			this.$store.commit("addVisitedView", viewObj) //将此路由储存在vuex
		},
	},
	methods: {
		isActive(route) {
			return route.path === this.$route.path
		},
		//关闭标签
		closeSelectedTag(view) {
			this.$store.commit("delVisitedView", view)
			if (this.isActive(view)) {
				this.toLastView(this.$store.state.visitedView)
			}
		},
		//如果在最后一个标签页则跳转到倒数第二标签
		toLastView(visitedView) {
			const latestView = visitedView.slice(-1)[0]
			console.log(latestView)
			if (latestView) {
				this.$router.push(latestView.fullPath)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.tags-view-container {
	height: 40px;
	width: 100%;
	background: #fff;
	border-bottom: 1px solid #d8dce5;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
	overflow: hidden;
	overflow-x: auto;
	.tags-view-item {
		display: inline-block;
		position: relative;
		cursor: pointer;
		height: 26px;
		line-height: 26px;
		border: 1px solid #d8dce5;
		color: #495060;
		background: #fff;
		padding: 0 8px;
		font-size: 12px;
		margin-left: 5px;
		margin-top: 4px;
		&:first-of-type {
			margin-left: 15px;
		}
		&:last-of-type {
			margin-right: 15px;
		}
		&.active {
			background-color: rgba(36, 123, 255, 1);
			color: #fff;
			border-color: rgba(36, 123, 255, 1);
			&::before {
				content: "";
				background: #fff;
				display: inline-block;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				position: relative;
				margin-right: 2px;
			}
		}
	}
}
.scroll-container {
	white-space: nowrap;
	position: relative;
	overflow: hidden;
	width: 100%;
	::v-deep {
		.el-scrollbar__bar {
			bottom: 0px;
		}
		.el-scrollbar__wrap {
			height: 49px;
		}
	}
}
</style>

<style lang="scss">
//reset element css of el-icon-close
.tags-view-item {
	.el-icon-close {
		width: 16px;
		height: 16px;
		vertical-align: 2px;
		border-radius: 50%;
		text-align: center;
		transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
		transform-origin: 100% 50%;
		&:before {
			transform: scale(0.6);
			display: inline-block;
			vertical-align: -3px;
		}
		&:hover {
			background-color: #b4bccc;
			color: #fff;
		}
	}
}
</style>
