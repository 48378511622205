<template>
	<div class="fenge pb-5 inline-block mt-8">
		<div class="flex font-bold text-xl" style="color:rgba(128, 128, 128, 1);">
			<div class="w-48">任务类型</div>
			<div style="width:26.5rem">任务名称</div>
			<div class="w-48">可获得鱼币</div>
			<div class="w-48">可获得经验值</div>
			<div class="w-48">可获得额外鱼币奖励</div>
		</div>
		<div class="flex text-black text-xl mt-5" v-for="(item, index) in task" :key="index">
			<div class="w-48">{{ index == 0 ? "日常任务" : "" }}</div>
			<div class="flex items-center">
				<div class="mr-5">
					<el-checkbox
						true-label="1"
						false-label="0"
						fill="#000000"
						v-model="item.status"
						:disabled="!isUpd"
					></el-checkbox>
				</div>
				<div class="w-96 mt-1">
					{{ item.name | delNum }}
					<el-select
						:disabled="!isUpd"
						v-if="index == 0"
						v-model="value.value7"
						multiple
						placeholder="请选择"
						style="width:150px"
					>
						<el-option
							v-for="item in days"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						>
						</el-option>
					</el-select>
					<el-input
						:disabled="!isUpd"
						v-if="index != 0"
						v-model="value['value' + index]"
						style="width:80px;font-size:20px;"
						:class="!isUpd ? 'isDisable' : ''"
						type="number"
						onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
					></el-input>
					{{ index == 0 ? "" : "次" }}
				</div>
				<div class="w-48">
					<el-input
						v-model="item.coin"
						style="width:95px;"
						:disabled="!isUpd"
						type="number"
						onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
					></el-input>
				</div>
				<div class="w-48">
					<el-input
						v-model="item.exp"
						style="width:95px"
						:disabled="!isUpd"
						type="number"
						onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
					></el-input>
				</div>
				<div class="w-48" v-if="index == 0">
					<el-input
						v-model="signIn[7].coin"
						style="width:95px"
						:disabled="!isUpd"
						type="number"
						onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
					></el-input>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		task: Array,
		isUpd: Boolean,
		signIn: Array,
	},
	data() {
		return {
			days: [
				{
					value: "签到第1天",
					label: "第1天",
				},
				{
					value: "签到第2天",
					label: "第2天",
				},
				{
					value: "签到第3天",
					label: "第3天",
				},
				{
					value: "签到第4天",
					label: "第4天",
				},
				{
					value: "签到第5天",
					label: "第5天",
				},
				{
					value: "签到第6天",
					label: "第6天",
				},
				{
					value: "签到第7天",
					label: "第7天",
				},
			],
			additional: 100,
			value: {
				value1: "",
				value2: "",
				value3: "",
				value4: "",
				value5: "",
				value6: "",
				value7: [],
			},
		}
	},
	watch: {
		task: {
			handler() {
				if (this.task[1].name) {
					this.value.value1 = this.task[1].name.replace(/[^0-9]/gi, "")
					this.value.value2 = this.task[2].name.replace(/[^0-9]/gi, "")
					this.value.value3 = this.task[3].name.replace(/[^0-9]/gi, "")
					this.value.value4 = this.task[4].name.replace(/[^0-9]/gi, "")
					this.value.value5 = this.task[5].name.replace(/[^0-9]/gi, "")
					this.value.value6 = this.task[6].name.replace(/[^0-9]/gi, "")
				}
			},
		},
	},
	mounted() {},
	filters: {
		delNum: function(val) {
			return val.replace(/[0-9]+/g, "").replace(/(.*)次/, "$1")
		},
	},
	methods: {
		getVal() {
			return this.value
		},
		changeInput(val) {
			let pattern = /^[1-9][0-9]*$/ // 正整数的正则表达式
			// 不符合正整数时
			if (!pattern.test(val)) {
				// input 框绑定的内容为空
				val = ""
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.fenge {
	border-bottom: rgba(166, 166, 166, 1) solid 1px;
}
/deep/.el-checkbox__inner {
	width: 25px;
	height: 25px;
	border-radius: 50%;
}
/deep/.el-checkbox__inner::after {
	width: 8px;
	height: 12px;
	left: 6px;
	top: 2px;
}
/deep/.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
	background-color: #409eff;
	border-color: white;
}
/deep/.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
	border-color: white;
}
/deep/.el-input.is-disabled .el-input__inner {
	background-color: white;
	color: black;
}
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
}
/deep/ input[type="number"] {
	-moz-appearance: textfield !important;
}
</style>
