<template>
	<div class="p-10">
		<div class="flex justify-between text-xl font-bold">
			<div><i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>鱼塘攻略</div>
			<div class="text-center">
				<el-button type="primary" class="w-32 text-white" @click="updHelp()">保存</el-button>
			</div>
		</div>

		<div class="flex ml-5 mt-20 text-xl items-center">
			<div class="font-bold">标题：</div>
			<div><el-input v-model="courseDetail.title"></el-input></div>
		</div>
		<div class="text-xl font-bold mt-20"><i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>攻略内容</div>
		<wangEdit :content="midData" @getContent="getContent"></wangEdit>
	</div>
</template>

<script>
import wangEdit from "../../components/wangEdit/wangEdit.vue"
export default {
	components: {
		wangEdit,
	},
	data() {
		return {
			courseDetail: {
				title: "",
				content: "",
				releaseTime: "",
			},
			midData: "",
		}
	},
	mounted() {
		this.getHelpDetails()
	},
	methods: {
		//新手教程详情
		async getHelpDetails() {
			const res = await this.http.post("admin/Strategy/getStrategy", {}).catch((err) => {
				console.log(err)
			})
			this.courseDetail = res.data
			this.midData = res.data.content
			console.log(this.courseDetail)
		},
		//获取富文本
		getContent(data) {
			this.courseDetail.content = data
		},
		//编辑教程详情
		async updHelp() {
			const res = await this.http
				.post("admin/Strategy/updStrategy", {
					strategyId: 1,
					title: this.courseDetail.title,
					content: this.courseDetail.content,
				})
				.catch((err) => {
					console.log(err)
				})
		},
	},
}
</script>

<style lang="scss" scoped></style>
