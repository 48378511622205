<template>
	<div class="p-10">
		<div class="flex justify-between">
			<div class="text-xl font-bold">
				<i class="cuIcon-titles" style="color:rgba(42, 130, 228, 1)"></i>活动管理
			</div>
			<div class="mr-10">
				<el-button type="primary" @click="changStatus1()">通过</el-button>
				<el-button type="danger" @click="dialogVisible = true">驳回</el-button>
			</div>
		</div>

		<div>
			<el-form ref="form" :model="form" label-width="150px">
				<el-form-item label="活动名称">
					<div class=" text-lg mt-1.5 ">{{ form.title }}</div>
				</el-form-item>
				<el-form-item label="钓场" class=" text-lg">
					<div class=" text-lg h-7 mt-1 ml-4">{{ form.site }}</div>
					<iframe
						id="mapPage"
						width="500"
						height="500"
						frameborder="0"
						:src="
							`https://apis.map.qq.com/tools/poimarker?type=1&key=ZC2BZ-RFWK6-BDPSX-MPWGJ-PO4MV-ZQBKA&referer=myapp&marker=coord:${latLng}&keyword=${form.site}&center=${latLng}&radius=1000&tonav=0`
						"
					></iframe>
				</el-form-item>
				<el-form-item label="报名截止日期">
					<div class=" text-lg mt-1.5 ">
						{{ form.endTime }}
					</div>
				</el-form-item>
				<el-form-item label="作钓时间">
					<div class=" text-lg mt-1.5 ">{{ form.activityTime }}</div>
				</el-form-item>
				<el-form-item label="参与人数">
					<div class=" text-lg mt-1.5 ">{{ form.num }}</div>
				</el-form-item>
				<el-form-item label="钓法"
					><div class=" text-lg mt-1.5 ">{{ form.skill }}</div></el-form-item
				>
				<el-form-item label="钓场类型">
					<div class=" text-lg mt-1.5 ">{{ form.siteType }}</div></el-form-item
				>
				<el-form-item label="预估费用">
					<div class=" text-lg mt-1.5 ">
						{{
							form.charge != "免费" && form.charge != "未知"
								? form.charge + "元/人"
								: form.charge
						}}
					</div>
				</el-form-item>
			</el-form>
		</div>
		<el-dialog title="驳回理由" :visible.sync="dialogVisible" width="55%">
			<div style="border-bottom:rgba(229, 229, 229, 1) solid 1px;margin-top: -30px;"></div>
			<div class="flex items-center mt-8">
				<div class=" text-lg mr-5">头像</div>
				<div class="mr-48">
					<img class="img1" :src="avatarUrl" alt="" />
				</div>
				<div class=" text-lg">发起者：</div>
				<div class=" text-lg mr-5">{{ name }}</div>
			</div>
			<div class="mt-5">
				<el-input type="textarea" rows="10" placeholder="请输入驳回理由" v-model="reason">
				</el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false" type="danger">取 消</el-button>
				<el-button type="primary" @click="changStatus2()">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import pagination from "../../components/Pagination/pagination.vue"
export default {
	components: { pagination },
	data() {
		return {
			activityId: this.$route.query.activityId,
			form: {
				title: "",
				site: "",
				endTime: "",
				activityTime: "",
				num: "",
				skill: "",
				siteType: "",
				charge: "1",
				latitude: "",
				longitude: "",
			},
			latLng: "",
			tableData: [],
			dialogVisible: false,
			avatarUrl: "",
			name: "",
			reason: "",
		}
	},
	mounted() {
		this.getActivityDetails()
	},
	methods: {
		//获取活动详情
		async getActivityDetails() {
			const res = await this.http
				.post("admin/activity/getActivityDetails", { activityId: this.activityId }, false)
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.form.title = res.data.activity.title
				this.form.site = res.data.activity.site
				this.form.endTime = res.data.activity.endTime
				this.form.activityTime = res.data.activity.activityTime
				let str = res.data.activity.activityTime.split("-")
				this.date = str
				this.form.num = res.data.activity.num
				this.form.skill = res.data.activity.skill
				this.form.siteType = res.data.activity.siteType
				let arr = res.data.activity.siteType.split("/")
				this.value1 = arr
				this.form.latitude = res.data.activity.latitude
				this.form.longitude = res.data.activity.longitude
				this.latLng = res.data.activity.latitude + "," + res.data.activity.longitude
				this.form.charge = res.data.activity.charge
				this.tableData = res.data.user
				this.avatarUrl = res.data.activity.avatarUrl
				this.name = res.data.activity.name
			}
		},
		//跳转活动编辑
		toDetail() {
			this.$router.push({
				path: "activityDetail",
				query: { activityId: this.activityId },
			})
		},
		//删除活动
		async delActivity() {
			this.$confirm("此操作将永久删除该活动, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/activity/delActivity", {
							activityId: this.activityId,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						this.$router.push({
							path: "release",
						})
					}
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					})
				})
		},
		//编辑审核状态&&通过
		async changStatus1() {
			const res = await this.http
				.post("admin/activity/updActivity", {
					activityId: Number(this.activityId),
					audit: 1,
				})
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.$router.push({
					name: "activityExamine",
				})
			}
		},
		//编辑审核状态&&驳回
		async changStatus2() {
			if (!this.reason) {
				this.$message("请将数据填写完整")
			} else {
				const res = await this.http
					.post("admin/activity/updActivity", {
						activityId: Number(this.activityId),
						audit: -1,
						reason: this.reason,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.$router.push({
						name: "activityExamine",
					})
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
/deep/.el-form-item__label {
	color: rgba(128, 128, 128, 1);
	font-size: 18px;
	font-weight: bold;
}
.img1 {
	width: 65px;
	height: 65px;
	border-radius: 50%;
}
</style>
