<template>
	<div class="fenge pb-5 inline-block mt-8">
		<div class="flex font-bold text-xl" style="color:rgba(128, 128, 128, 1);">
			<div class="w-48">任务类型</div>
			<div style="width:26.5rem">任务名称</div>
			<div class="w-48">可获得鱼币</div>
			<div class="w-48">可获得经验值</div>
		</div>
		<div class="flex text-black text-xl mt-5" v-for="(item, index) in task" :key="index">
			<div class="w-48">{{ index == 0 ? "发布任务" : "" }}</div>
			<div class="mr-5"><el-checkbox true-label="1" false-label="0" fill="#000000" v-model="item.status" :disabled="!isUpd"></el-checkbox></div>
			<div class="w-96">
				{{ index == 1 || index == 2 || index == 3 ? "获得" : "" }}
				<el-input
					:disabled="!isUpd"
					v-if="index == 1 || index == 2 || index == 3"
					v-model="value['value' + index]"
					style="width:80px;font-size:20px"
					type="number"
					onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
				></el-input>
				{{ item.name | delNum }}
			</div>
			<div class="w-48">
				<el-input
					v-model="item.coin"
					style="width:95px"
					:disabled="!isUpd"
					type="number"
					onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
				></el-input>
			</div>
			<div class="w-48">
				<el-input
					v-model="item.exp"
					style="width:95px"
					:disabled="!isUpd"
					type="number"
					onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
				></el-input>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		task: Array,
		isUpd: Boolean,
	},
	data() {
		return {
			value: {
				value1: "",
				value2: "",
				value3: "",
			},
		}
	},
	filters: {
		delNum: function(val) {
			return val
				.replace(/[0-9]+/g, "")
				.replace("获得", "")
				.replace("获取", "")
		},
	},
	watch: {
		task: {
			handler() {
				if (this.task[1].name) {
					this.value.value1 = this.task[1].name.replace(/[^0-9]/gi, "")
					this.value.value2 = this.task[2].name.replace(/[^0-9]/gi, "")
					this.value.value3 = this.task[3].name.replace(/[^0-9]/gi, "")
				}
			},
		},
	},
	mounted() {},
	methods: {
		getVal() {
			return this.value
		},
	},
}
</script>

<style lang="scss" scoped>
.fenge {
	border-bottom: rgba(166, 166, 166, 1) solid 1px;
}
/deep/.el-checkbox__inner {
	width: 25px;
	height: 25px;
	border-radius: 50%;
}
/deep/.el-checkbox__inner::after {
	width: 8px;
	height: 12px;
	left: 6px;
	top: 2px;
}
/deep/.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
	background-color: #409eff;
	border-color: white;
}
/deep/.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
	border-color: white;
}
/deep/.el-input.is-disabled .el-input__inner {
	background-color: white;
	color: black;
}
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
}
/deep/ input[type="number"] {
	-moz-appearance: textfield !important;
}
</style>
