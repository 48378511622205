<template>
	<div>
		<div class="bg-white pt-4 pb-4 pl-5 text-xl font-bold text-black">
			自定义标题
		</div>
		<el-card class="mt-4 ml-4 mr-4 bg-white border-white">
			<div class="flex justify-between">
				<div></div>
				<div class="">
					<el-button type="primary" size="small" @click=";(isAdd = true), (dialog = true), handleAdd()">+ 新建</el-button>
				</div>
			</div>
			<!-- 表格 -->
			<el-table :data="tableData" height="70vh" class="mt-6">
				<el-table-column label="图片">
					<template slot-scope="scope">
						<el-image :src="scope.row.imgUrl" class="w-40 h-28"></el-image>
					</template>
				</el-table-column>
				<el-table-column label="序号"
					><template slot-scope="scope">{{ scope.$index + 1 }}</template></el-table-column
				>
				<el-table-column prop="platName" label="自定义字段"></el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="text" @click=";(isAdd = false), (dialog = true), handleEdit(scope.row)"
							><span class="text-blue-500">编辑</span></el-button
						><el-divider direction="vertical"></el-divider>
						<el-button type="text" @click="handleDel(scope.row)"><span class="text-red-500">删除</span></el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-card>

		<!-- 对话框 -->
		<el-dialog :visible.sync="dialog" :close-on-click-modal="false" width="600px" :title="isAdd ? '自定义' : '自定义'">
			<!-- 分割线 -->
			<el-divider></el-divider>

			<div class="flex items-center ml-6 mr-6 mt-6">
				<div>
					<el-upload
						class="avatar-uploader"
						ref="upload"
						action="#"
						:show-file-list="false"
						:multiple="false"
						:auto-upload="false"
						:on-change="imgSaveToUrl"
					>
						<img v-if="form.imgUrl" :src="form.imgUrl" class="avatar" />
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</div>
				<div class="ml-10">
					<el-form label-position="right" label-width="100px" v-model="form" class="mt-3">
						<el-form-item label="自定义字段 :">
							<el-input size="small" v-model="form.platName"></el-input>
						</el-form-item>
					</el-form>
				</div>
			</div>
			<div class="text-center pb-6 pt-6">
				<el-button size="small" @click="dialog = false" class="mr-36">
					取消
				</el-button>
				<el-button type="primary" size="small" @click="handleConfirm()">
					确定
				</el-button>
			</div>
		</el-dialog>

		<!-- 分页 -->
		<div class="flex justify-between bg-white ml-4 mr-4 pt-4 pb-4">
			<div></div>
			<Pagination :currentPage="currentPage" :total="total" @pageChange="pageChange" />
		</div>
	</div>
</template>

<script>
import Pagination from "../../components/Pagination/pagination"
export default {
	components: { Pagination },
	data() {
		return {
			tableData: [{ platName: "第一条数据为展示数据" }], //table数据
			form: {
				imgUrl: "", //图片字段
				platName: "", //自定义字段
			},
			// 对话框
			isAdd: false, //区分添加和编辑
			dialog: false,
			// 分页
			total: 0,
			currentPage: 1,
		}
	},

	computed: {},

	watch: {},
	mounted() {
		// this.getList();  //一开始获取数据
	},
	methods: {
		// 获取基本数据
		async getList() {
			const RES = await this.http.post("/XXXX/getXXXX", {
				limit: 10,
				page: this.currentPage,
			})
			if (RES.code == 1) {
				// this.tableData = RES.data.data;
				// this.total = RES.data.total;
			}
		},
		// 图片上传
		async imgSaveToUrl(event) {
			if (this.beforeUploadPicture(event.raw)) {
				var formData = new FormData()
				formData.append("file", event.raw)
				this.http.upload("/user/uploadFile", formData).then((res) => {
					if (res.code == 1) {
						this.form.imgUrl = res.data
						this.$message.success("上传成功")
					}
				})
			}
		},
		// 图片上传之前
		beforeUploadPicture(file) {
			const isJPG = file.type === "image/jpeg"
			const isPNG = file.type === "image/png"
			const isLt10M = file.size / 1024 / 1024 < 10
			if (!isJPG && !isPNG) {
				this.$message.error("上传图片只能是 JPG 或者 PNG 格式!")
			}
			if (isJPG && isPNG) {
				if (!isLt10M) {
					this.$message.error("上传图片大小不能超过 10MB!")
				}
			}
			return (isJPG || isPNG) && isLt10M
		},

		// 新建
		handleAdd() {
			this.form.imgUrl = "" //轮播图添加时，需要把form里面的数据清空，然后单独给轮播图的字段清理为空，防止图片不回显
		},

		// 编辑
		handleEdit(row) {
			this.form = JSON.parse(JSON.stringify(row)) //编辑给表单赋值的时候，需要把数据深度拷贝
		},

		//分页
		pageChange(index) {
			this.currentPage = index._currentPage
			this.getList()
		},
		// 确认
		handleConfirm() {
			var a = { imgUrl: this.form.imgUrl, platName: this.form.platName } //检查必填字段
			if (this.check.checkHasEmpty(a)) {
				//调用函数checkHasEmpty() 检查必填字段是否有为空的
				this.$message("请将数据填写完整")
			} else {
				this.addImg()
			}
		},
		// 添加&&编辑
		async addImg() {
			const RES = await this.http.post(this.isAdd ? "/XXXX/addXXXX" : "/XXXX/updXXXX", {
				imgUrl: this.form.imgUrl,
				id: this.isAdd ? "" : this.form.id, //添加时，不给id，编辑时给id
				platName: this.form.platName,
			})
			if (RES.code == 1) {
				this.getList()
				this.$message.success(this.isAdd ? "添加成功" : "编辑成功")
				this.dialog = false //关闭对话框
			}
		},
		//删除
		handleDel(row) {
			this.$confirm("是否删除平台", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const RES = await this.http.post("/XXXX/delXXXX", {
						id: row.id,
					})
					if (RES.code == 1) {
						this.getList()
						this.$message({
							type: "success",
							message: "删除成功!",
						})
					}
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					})
				})
		},
	},
}
</script>
<style lang="scss" scoped>
/deep/.el-table td,
.el-table th {
	text-align: center;
}
/deep/.el-table th {
	text-align: center;
	background: rgb(236, 235, 235);
}
/deep/ .avatar-uploader .el-upload {
	border: 1px dashed hsl(0, 0%, 85%);
	cursor: pointer;
	position: relative;
	overflow: hidden;
	background: rgb(204, 204, 204);
}
/deep/.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 200px;
	height: 150px;
	line-height: 150px;
	text-align: center;
}
/deep/ .avatar {
	width: 200px;
	height: 150px;
	display: block;
}
/deep/.el-form-item {
	margin-bottom: 5px;
}

/deep/.el-dialog__body {
	padding: 0px;
}
/deep/.el-divider--horizontal {
	margin: 0px;
}
</style>
