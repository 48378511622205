<template>
	<div class="p-10">
		<div class="flex justify-between">
			<div class="text-xl font-bold"><i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>教程详情</div>
			<div class="text-center">
				<el-button type="primary" class="w-32 text-white" @click="typeof articleId == 'undefined' ? addCourse() : updCourse()"
					>保存</el-button
				>
				<el-button type="info" class="w-32 text-white" @click="toBack()">返回</el-button>
			</div>
		</div>

		<div class="flex ml-5 mt-5 text-xl items-center">
			<div class="font-bold">封面：</div>
			<div>
				<el-upload
					class="avatar-uploader"
					ref="upload"
					action="#"
					:show-file-list="false"
					:multiple="false"
					:auto-upload="false"
					:on-change="imgSaveToUrl"
				>
					<img v-if="courseDetail.img" :src="courseDetail.img" class="avatar" />
					<i v-else class="el-icon-picture avatar-uploader-icon"></i>
				</el-upload>
			</div>
		</div>

		<div class="flex ml-5 mt-5 text-xl items-center">
			<div class="font-bold">标题：</div>
			<div><el-input v-model="courseDetail.title"></el-input></div>
		</div>
		<div class="flex ml-5 mt-5 text-xl items-center">
			<div class="font-bold">时间：</div>
			<div>
				<el-date-picker
					v-model="courseDetail.releaseTime"
					type="datetime"
					placeholder="选择日期时间"
					@change="changeData"
					value-format="yyyy-MM-dd HH:mm:ss"
				>
				</el-date-picker>
			</div>
		</div>
		<div class="flex ml-5 mt-5 text-xl items-center">
			<div class="font-bold">分类：</div>
			<div><el-cascader v-if="value" v-model="value" :options="data" :props="optionProps" @change="handleChange"></el-cascader></div>
		</div>
		<div class="text-xl font-bold mt-10"><i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>教程内容</div>
		<!-- 富文本编辑器 -->
		<wangEdit :content="midData" @getContent="getContent"></wangEdit>
	</div>
</template>

<script>
import wangEdit from "../../../components/wangEdit/wangEdit.vue"
export default {
	components: {
		wangEdit,
	},
	data() {
		return {
			articleId: this.$route.query.articleId,
			courseDetail: {
				title: "",
				img: "",
				content: "",
				releaseTime: "",
				class: "",
				type: "",
			},
			value: [],
			data: [],
			optionProps: {
				value: "class",
				label: "class",
				children: "children",
				expandTrigger: "hover",
			},
			midData: "",
		}
	},
	mounted() {
		this.getCourseDetails()
		this.getCourseClass()
	},
	methods: {
		//新手教程详情
		async getCourseDetails() {
			if (typeof this.articleId === "undefined") {
				return
			}
			const res = await this.http
				.post(
					"admin/course/getCourseDetails",
					{
						articleId: this.articleId,
					},
					false
				)
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.courseDetail = res.data
				this.midData = res.data.content
				let tempType = []
				if (res.data.type == "0") {
					let type = "文章"
					tempType.push(res.data.class, type)
					this.value = tempType
					this.courseDetail.type = type
				} else {
					let type = "视频"
					tempType.push(res.data.class, type)
					this.value = tempType
					this.courseDetail.type = type
				}
			}
		},
		//获取钓鱼技巧分类
		async getCourseClass() {
			const res = await this.http.post("admin/course/getCourseClass", {}, false).catch((err) => {
				console.log(err)
			})
			if (res.code == 1) {
				this.data = JSON.parse(JSON.stringify(res.data))
			}
		},

		//联级选择器
		handleChange(value) {
			this.courseDetail.class = value[0]
			this.courseDetail.type = value[1]
		},
		//获取富文本
		getContent(data) {
			this.courseDetail.content = data
		},
		//编辑教程详情
		async updCourse() {
			if (this.check.checkHasEmpty(this.courseDetail)) {
				//调用函数checkHasEmpty() 检查必填字段是否有为空的
				this.$message("请将数据填写完整")
			} else {
				const res = await this.http
					.post("admin/course/updCourse", {
						articleId: this.articleId,
						img: this.courseDetail.img,
						title: this.courseDetail.title,
						content: this.courseDetail.content,
						releaseTime: this.courseDetail.releaseTime,
						class: this.courseDetail.class,
						type: this.courseDetail.type,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.$router.push({
						path: "novice",
					})
				}
			}
		},
		//新建教程详情
		async addCourse() {
			if (this.check.checkHasEmpty(this.courseDetail)) {
				//调用函数checkHasEmpty() 检查必填字段是否有为空的
				this.$message("请将数据填写完整")
			} else {
				const res = await this.http
					.post("admin/course/addCourse", {
						title: this.courseDetail.title,
						img: this.courseDetail.img,
						content: this.courseDetail.content,
						releaseTime: this.courseDetail.releaseTime,
						class: this.courseDetail.class,
						type: this.courseDetail.type,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.$router.push({
						path: "novice",
					})
				}
			}
		},

		// 图片上传
		async imgSaveToUrl(event) {
			if (this.beforeUploadPicture(event.raw)) {
				var formData = new FormData()
				formData.append("img", event.raw)
				console.log(formData)
				this.http.upload("admin/upload/uploadImg", formData).then((res) => {
					if (res.code == 1) {
						this.courseDetail.img = res.data
						this.$message.success("上传成功")
					}
				})
			}
		},
		// 图片上传之前
		beforeUploadPicture(file) {
			const isJPG = file.type === "image/jpeg"
			const isPNG = file.type === "image/png"
			const isLt10M = file.size / 1024 / 1024 < 10
			if (!isJPG && !isPNG) {
				this.$message.error("上传图片只能是 JPG 或者 PNG 格式!")
			}
			if (isJPG && isPNG) {
				if (!isLt10M) {
					this.$message.error("上传图片大小不能超过 10MB!")
				}
			}
			return (isJPG || isPNG) && isLt10M
		},

		//选择日期
		changeData(event) {
			this.courseDetail.releaseTime = event
		},
		//返回
		toBack() {
			this.$router.push({
				path: "novice",
			})
		},
	},
}
</script>

<style lang="scss" scoped>
/deep/ .avatar-uploader .el-upload {
	border: 1px dashed hsl(0, 0%, 85%);
	cursor: pointer;
	position: relative;
	overflow: hidden;
	background: rgb(204, 204, 204);
}
/deep/.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 150px; //上传图片的宽
	height: 150px; //上传图片的高
	line-height: 150px;
	text-align: center;
}
/deep/ .avatar {
	width: 150px; //图片回显的宽
	height: 150px; //图片回显的高
	display: block;
}
</style>
