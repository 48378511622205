<template>
	<div class="p-10">
		<div class="text-xl font-bold">
			<i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>海报管理
		</div>
		<div class="mt-5">
			<div class="search ml-4 mt-1 bg-blue-500 text-white cursor-pointer" @click="clickAdd()">
				<i class="cuIcon-add"></i>
			</div>
		</div>
		<div class="mt-5 border-2">
			<el-table
				:cell-style="{ 'text-align': 'center' }"
				:header-cell-style="{ 'text-align': 'center', background: '#fafafa' }"
				:data="tableData"
				stripe
				style="width: 100%"
			>
				<!-- <el-table-column prop="name" label="群聊名称"></el-table-column> -->
				<el-table-column label="海报">
					<template slot-scope="scope">
						<el-image
							:src="scope.row.img"
							style="width:450px;height:240px"
							fit="fit"
						></el-image>
					</template>
				</el-table-column>
				<el-table-column label="状态">
					<template slot-scope="scope">
						<el-switch
							v-model="scope.row.status"
							active-value="1"
							inactive-value="0"
							active-color="#13ce66"
							inactive-color="#bebebe"
							@change="changStatus0(scope.row)"
						></el-switch>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="150">
					<template slot-scope="scope">
						<el-button
							style="text-decoration: underline"
							@click="handleClick(scope.row)"
							type="text"
							>编辑</el-button
						>
						<el-button
							style="text-decoration: underline; color: red"
							type="text"
							@click="delChat(scope.row)"
							>删除</el-button
						>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<el-dialog
			:title="isAdd ? '编辑海报图' : '新增海报图'"
			:visible.sync="dialog"
			width="400px"
			:close-on-click-modal="false"
		>
			<div class=" font-bold flex items-center" style="color: rgba(128, 128, 128, 1)">
				<div style="padding-top: 3px">上传海报</div>
				<div>
					<i class="el-icon-upload" style="font-size: 30px; padding-left: 10px"></i>
				</div>
			</div>
			<div style="width: 100%; height: 160px; border: black solid 1px" class="mt-3">
				<div class="text-center">
					<el-upload
						ref="upload"
						action="#"
						:show-file-list="false"
						:multiple="false"
						:auto-upload="false"
						:on-change="imgSaveToUrl"
					>
						<img v-if="form.img" :src="form.img" class="imgSize"/>
						<i v-else class="el-icon-plus addUpload"></i
					></el-upload>
				</div>
			</div>
			<div class="mt-10">显示状态</div>
			<div class="mt-5">
				<el-switch
					v-model="form.status"
					active-value="1"
					inactive-value="0"
					active-color="#13ce66"
					inactive-color="#bebebe"
				></el-switch>
			</div>
			<div class="mt-5 text-center">
				<el-button size="small" class="mr-10" @click="dialog = false">取消</el-button>
				<el-button type="primary" size="small" @click="isAdd ? updChat() : addChat()"
					>确定</el-button
				>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	data() {
		return {
			input: "",
			tableData: [],
			isAdd: false,
			dialog: false,
			form: {
				// name: "",
				img: "",
				status: "1",
			},
		}
	},

	mounted() {
		this.getChat()
	},
	methods: {
		//获取海报
		async getChat() {
			const res = await this.http.post("admin/Poster/getPoster", {}, false).catch((err) => {
				console.log(err)
			})
			if (res.code == 1) {
				this.tableData = res.data
			}
		},
		//点击新增
		clickAdd() {
			this.dialog = true
			this.isAdd = false
			this.form = { img: "", status: "1" }
			console.log(this.form)
		},
		//点击编辑
		handleClick(row) {
			this.dialog = true
			this.isAdd = true
			this.form = JSON.parse(JSON.stringify(row))
		},
		//添加本地群聊
		async addChat() {
			if (this.check.checkHasEmpty(this.form)) {
				//调用函数checkHasEmpty() 检查必填字段是否有为空的
				this.$message("请将数据填写完整")
			} else {
				const res = await this.http
					.post("admin/Poster/addPoster", {
						img: this.form.img,
						status: this.form.status,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.dialog = false
					this.getChat()
				}
			}
		},
		//编辑海报
		async updChat() {
			if (this.check.checkHasEmpty(this.form)) {
				//调用函数checkHasEmpty() 检查必填字段是否有为空的
				this.$message("请将数据填写完整")
			} else {
				const res = await this.http
					.post("admin/Poster/updPoster", {
						posterId: this.form.id,
						img: this.form.img,
						status: this.form.status,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.dialog = false
					this.getChat()
				}
			}
		},
		//删除海报
		async delChat(row) {
			this.$confirm("此操作将永久删除该海报, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/Poster/delPoster", {
							posterId: row.id,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						this.getChat()
					}
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					})
				})
		},
		// 图片上传
		async imgSaveToUrl(event) {
			if (this.beforeUploadPicture(event.raw)) {
				var formData = new FormData()
				formData.append("img", event.raw)
				console.log(formData)
				this.http.upload("admin/upload/uploadImg", formData).then((res) => {
					if (res.code == 1) {
						this.form.img = res.data
						this.$message.success("上传成功")
					}
				})
			}
		},
		// 图片上传之前
		beforeUploadPicture(file) {
			const isJPG = file.type === "image/jpeg"
			const isPNG = file.type === "image/png"
			const isLt10M = file.size / 1024 / 1024 < 10
			if (!isJPG && !isPNG) {
				this.$message.error("上传图片只能是 JPG 或者 PNG 格式!")
			}
			if (isJPG && isPNG) {
				if (!isLt10M) {
					this.$message.error("上传图片大小不能超过 10MB!")
				}
			}
			return (isJPG || isPNG) && isLt10M
		},
		//编辑海报状态
		changStatus0(row) {
			let flag = row.status
			row.status == "1" ? (row.status = "0") : (row.status = "1")
			this.$confirm("是否改变该状态", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/Poster/updPoster", {
							posterId: row.id,
							status: flag,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						flag == "1" ? (row.status = "1") : (row.status = "0") // 这一步很重要，row.showState会根据flag的值开启或关闭开关
					}
				})
				.catch(() => {
					this.$message({ type: "info", showClose: true, message: "已取消修改！" })
				})
		},
	},
}
</script>

<style lang="scss" scoped>
.search {
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	border-radius: 50%;
	border: rgb(175, 174, 174) solid 1px;
}
.addUpload {
	height: 160px;
	width: 150px;
	line-height: 160px;
	font-size: 50px;
	margin: auto;
}
.imgSize {
	width: 350px;
	height: 157px;
}
</style>
