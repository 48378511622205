<template>
	<div class="p-10">
		<div class="text-xl font-bold"><i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>钓法管理</div>
		<div class="mt-5 flex">
			<div>
				<el-input v-model="input" placeholder="搜索名称" style="width: 170px"></el-input>
			</div>
			<div class="search ml-4 mt-1 cursor-pointer" @click="clickSearch()">
				<i class="cuIcon-search"></i>
			</div>
			<div class="search ml-4 mt-1 text-white cursor-pointer" style="background: rgba(117, 199, 77, 1)" @click="reset()">
				<i class="el-icon-refresh"></i>
			</div>
			<div class="search ml-4 mt-1 bg-blue-500 text-white cursor-pointer" @click="clickAdd()">
				<i class="cuIcon-add"></i>
			</div>
		</div>
		<div class="mt-5 border-2">
			<el-table :data="tableData" stripe :header-cell-style="{ background: '#fafafa' }" style="width: 100%">
				<el-table-column prop="skill" label="钓法" width="300px"></el-table-column>
				<el-table-column label="启动状态">
					<template slot-scope="scope">
						<el-switch
							v-model="scope.row.status"
							active-value="1"
							inactive-value="0"
							active-color="#13ce66"
							inactive-color="#bebebe"
							@change="updSkillStatus(scope.row)"
						></el-switch>
					</template>
				</el-table-column>
				<el-table-column prop="createTime" label="创建时间"></el-table-column>
				<el-table-column label="操作" width="150">
					<template slot-scope="scope">
						<el-button style="text-decoration:underline" type="text" @click="clickUpd(scope.row)">编辑</el-button>
						<el-button style="text-decoration:underline;color:red" type="text" @click="delSkill(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<pagination :currentPage="pages" :total="total" @pageChange="changePages"></pagination>

		<el-dialog :destroy-on-close="true" :close-on-click-modal="false" :title="isEdit ? '编辑' : '新增'" :visible.sync="dialog" width="350px">
			<el-form>
				<el-form-item label="钓法名称" label-width="70px">
					<el-input placeholder="请输入" v-model="form.skill"></el-input>
				</el-form-item>
				<el-form-item label="启动状态" label-width="70px">
					<el-switch v-model="form.status" active-value="1" inactive-value="0"></el-switch>
				</el-form-item>
			</el-form>
			<div class="mt-5 text-center">
				<el-button size="small" class="mr-10" @click="dialog = false">取消</el-button>
				<el-button type="primary" size="small" @click="isEdit ? updSkill() : addSkill()">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import pagination from "../../components/Pagination/pagination.vue"
export default {
	components: { pagination },
	data() {
		return {
			input: "",
			pages: 1,
			total: 0,
			limit: 5,
			tableData: [],
			dialog: false,
			isEdit: false,
			form: {
				skill: "",
				status: "",
			},
		}
	},
	mounted() {
		this.getUserSkill()
	},
	methods: {
		//分页
		changePages(data) {
			this.pages = data._currentPage
			this.limit = data._pageSize
			if (!this.input) {
				this.getUserSkill()
			} else {
				// this.searchHarvest()
			}
		},

		//钓法列表
		async getUserSkill() {
			const res = await this.http
				.post(
					"admin/user_skill/getUserSkill",
					{
						pages: this.pages,
						limit: this.limit,
					},
					false
				)
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.data
				this.total = res.data.total
			}
		},

		//点击新增
		clickAdd() {
			this.isEdit = false
			this.form = {
				skill: "",
				status: "1",
			}
			this.dialog = true
		},
		//新增
		async addSkill() {
			const res = await this.http.post("admin/user_skill/addUserSkill", {
				skill: this.form.skill,
				status: this.form.status,
			})
			if (res.code == 1) {
				this.dialog = false
				this.getUserSkill()
			}
		},

		//点击编辑
		clickUpd(row) {
			this.isEdit = true
			this.form = JSON.parse(JSON.stringify(row))
			console.log(this.form)
			this.dialog = true
		},

		//编辑
		async updSkill() {
			console.log(this.form)
			const res = await this.http.post("admin/user_skill/updUserSkill", {
				skill: this.form.skill,
				skillId: this.form.id,
				status: this.form.status,
			})
			if (res.code == 1) {
				this.dialog = false
				this.getUserSkill()
			}
		},

		//删除本地群聊
		async delSkill(row) {
			this.$confirm("此操作将永久删除该钓法, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/user_skill/delUserSkill", {
							skillId: row.id,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						this.getUserSkill()
					}
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					})
				})
		},

		//开启&关闭状态
		async updSkillStatus(row) {
			let flag = row.status
			row.status == "1" ? (row.status = "0") : (row.status = "1")
			this.$confirm("是否改变该状态", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/user_skill/updUserSkill", {
							skillId: row.id,
							status: flag,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						flag == "1" ? (row.status = "1") : (row.status = "0") // 这一步很重要，row.showState会根据flag的值开启或关闭开关
					}
				})
				.catch(() => {
					this.$message({ type: "info", showClose: true, message: "已取消修改！" })
				})
		},

		//搜索
		async searchSkill() {
			const res = await this.http
				.post("admin/user_skill/searchUserSkill", {
					pages: this.pages,
					limit: this.limit,
					skill: this.input,
				})
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.data
				this.total = res.data.total
			}
		},

		//点击查询
		clickSearch() {
			this.pages = 1
			if (this.input == "") {
				this.getUserSkill()
			} else {
				this.searchSkill()
			}
		},

		//刷新
		reset() {
			this.input = ""
			this.pages = 1
			this.getUserSkill()
		},
	},
}
</script>

<style scoped lang="scss">
.search {
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	border-radius: 50%;
	border: rgb(175, 174, 174) solid 1px;
}
</style>
