<template>
	<div class="p-10">
		<div class="text-xl font-bold">
			<i class="cuIcon-titles" style="color:rgba(42, 130, 228, 1)"></i>活动管理
		</div>
		<div class="mt-5 flex">
			<div>
				<el-input v-model="input" placeholder="搜索名称" style="width:170px"></el-input>
			</div>
			<div class="ml-5 flex items-center">
				<div class="selectName">发布设置</div>
				<div class="elSelect">
					<el-select
						v-model="value"
						placeholder="请选择"
						style="width:120px"
						@change="updReleaseMode($event)"
					>
						<el-option
							v-for="item in options"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
				</div>
			</div>
			<div class="search ml-4 mt-1 cursor-pointer" @click="clickSearch()">
				<i class="cuIcon-search"></i>
			</div>
			<div
				class="search ml-4 mt-1 text-white cursor-pointer"
				style="background:rgba(117, 199, 77, 1)"
				@click="reset()"
			>
				<i class="el-icon-refresh"></i>
			</div>
			<div class="search ml-4 mt-1 bg-blue-500 text-white cursor-pointer" @click="edit()">
				<i class="cuIcon-add"></i>
			</div>
		</div>
		<div class="mt-5 border-2">
			<el-table
				:data="tableData"
				stripe
				:header-cell-style="{ background: '#fafafa' }"
				style="width: 100%"
			>
				<el-table-column label="头像">
					<template slot-scope="scope">
						<img class="img1" :src="scope.row.avatarUrl" alt="" />
					</template>
				</el-table-column>
				<el-table-column label="发起者">
					<template slot-scope="scope">
						{{ scope.row.name ? scope.row.name : scope.row.nickName }}
					</template>
				</el-table-column>
				<el-table-column prop="endTime" label="报名截止日期"></el-table-column>
				<el-table-column prop="skill" label="钓法"></el-table-column>
				<el-table-column prop="charge" label="预估费用"></el-table-column>
				<el-table-column prop="site" label="活动场地"></el-table-column>
				<el-table-column label="审核状态">
					<template slot-scope="scope">{{
						scope.row.audit == "-1"
							? "已驳回"
							: scope.row.audit == "0"
							? "未审核"
							: "已通过"
					}}</template>
				</el-table-column>
				<el-table-column label="操作" width="200">
					<template slot-scope="scope">
						<el-button
							style="text-decoration:underline;color:rgba(0, 186, 173, 1)"
							@click="toDetail(scope.row)"
							type="text"
							>详情</el-button
						>
						<el-button
							style="text-decoration:underline;color:red"
							type="text"
							@click="delActivity(scope.row)"
							>删除</el-button
						>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<pagination :currentPage="pages" :total="total" @pageChange="changePages"></pagination>
		<del-dialog
			:dialogVisible="dialogVisible"
			:name="name"
			:avatarUrl="avatarUrl"
			:id="actId"
			@closeDig="closeDig"
		></del-dialog>
	</div>
</template>

<script>
import pagination from "../../components/Pagination/pagination.vue"
import DelDialog from "./components/DelDialog.vue"
import myMixin from "./minix/minix"
export default {
	components: { pagination, DelDialog },
	data() {
		return {
			input: "",
			tableData: [],
			total: 0, //总页数
			pages: 1, //当前页数
			limit: 5, //每页条数
			options: [
				{
					value: "0",
					label: "免审",
				},
				{
					value: "1",
					label: "需审核",
				},
			],
			value: "免审",
		}
	},
	mixins: [myMixin],

	mounted() {
		this.getActivity()
		this.getReleaseMode()
	},
	activated() {
		if (!this.input) {
			this.getActivity()
		} else {
			this.searchActivity()
		}
	},
	methods: {
		handleClick(row) {
			console.log(row)
		},
		//分页
		changePages(data) {
			this.pages = data._currentPage
			this.limit = data._pageSize
			if (!this.input) {
				this.getActivity()
			} else {
				this.searchActivity()
			}
		},
		//点击查询
		clickSearch() {
			this.pages = 1
			if (this.input == "") {
				this.getActivity()
			} else {
				this.searchActivity()
			}
		},
		//点击重置
		reset() {
			this.input = ""
			this.pages = 1
			this.getActivity()
		},
		//获取活动
		async getActivity() {
			const res = await this.http
				.post(
					"admin/activity/getActivity",
					{
						audit: 0,
						pages: this.pages,
						limit: this.limit,
					},
					false
				)
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.activity
				this.total = res.data.total
			}
		},
		//搜索活动
		async searchActivity() {
			const res = await this.http
				.post("admin/activity/searchActivity", {
					audit: 0,
					title: this.input,
					pages: this.pages,
					limit: this.limit,
				})
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.chat
				this.total = res.data.total
			}
		},
		//跳转新发布活动
		edit() {
			this.$router.push({
				path: "activityDetail",
			})
		},
		//跳转活动编辑
		detail(row) {
			this.$router.push({
				path: "activityDetail",
				query: { activityId: row.id },
			})
		},
		//跳转活动详情
		toDetail(row) {
			this.$router.push({
				path: "actExamineDetail",
				query: { activityId: row.id },
			})
		},
		//获取发布模式
		async getReleaseMode() {
			const res = await this.http.get("admin/article/getReleaseMode", {
				class: 1,
			})
			if (res.code == 1) {
				this.value = res.data
			}
		},
		//修改发布模式
		async updReleaseMode(e) {
			const res = await this.http.post("admin/article/updReleaseMode", {
				class: 1,
				type: Number(e),
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.search {
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	border-radius: 50%;
	border: rgb(175, 174, 174) solid 1px;
}
.selectName {
	height: 40px;
	line-height: 20px;
	border: 1px solid #dcdfe6;
	border-right: none;
	padding: 10px;
	box-sizing: border-box;
	color: rgba(128, 128, 128, 1);
}
/deep/.elSelect .el-input__inner {
	border-left: none;
}
.img1 {
	width: 65px;
	height: 65px;
	border-radius: 50%;
}
</style>
