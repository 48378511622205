<template>
	<div class="p-10">
		<div class="text-xl font-bold"><i class="cuIcon-titles" style="color:rgba(42, 130, 228, 1)"></i>用户管理</div>
		<div class="mt-5 flex">
			<div>
				<el-input v-model="input" placeholder="搜索名称" style="width:170px"></el-input>
			</div>
			<div class="search ml-4 mt-1 cursor-pointer" @click="clickSearch()">
				<i class="cuIcon-search"></i>
			</div>
			<div class="search ml-4 mt-1 text-white cursor-pointer" style="background:rgba(117, 199, 77, 1)" @click="reset()">
				<i class="el-icon-refresh"></i>
			</div>
			<!-- <div class="search ml-4 mt-1 bg-blue-500 text-white cursor-pointer">
				<i class="cuIcon-add"></i>
			</div> -->
		</div>
		<div class="mt-5 border-2">
			<el-table :data="tableData" stripe :header-cell-style="{ background: '#fafafa' }" style="width: 100%">
				<el-table-column label="头像">
					<template slot-scope="scope">
						<el-image :src="scope.row.avatarUrl" class="w-12 h-12" style="border-radius:50%" fit="fit"></el-image>
					</template>
				</el-table-column>
				<el-table-column prop="account" label="账号"></el-table-column>
				<el-table-column label="昵称"
					><template slot-scope="scope">{{ scope.row.name ? scope.row.name : scope.row.nickName }}</template></el-table-column
				>
				<el-table-column prop="level" label="等级"></el-table-column>
				<el-table-column prop="age" label="钓龄"></el-table-column>
				<el-table-column prop="skill" label="钓法"></el-table-column>
				<el-table-column label="地区">
					<template slot-scope="scope">{{ scope.row.area | delArr }}</template>
				</el-table-column>
				<el-table-column label="操作" width="150">
					<template slot-scope="scope">
						<el-button style="text-decoration:underline" @click="seeDetail(scope.row)" type="text">详情</el-button>
						<el-button style="text-decoration:underline;color:red" type="text" @click="delUser(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<pagination :currentPage="pages" :total="total" @pageChange="changePages"></pagination>
	</div>
</template>

<script>
import pagination from "../../components/Pagination/pagination.vue"
export default {
	components: { pagination },
	data() {
		return {
			input: "",
			tableData: [],
			total: 0, //总页数
			pages: 1, //当前页数
			limit: 5,
		}
	},
	filters: {
		delArr: function(val) {
			if (val) {
				let arr = eval("(" + val + ")")
				return arr[0] + "," + arr[1]
			}
		},
	},

	mounted() {
		this.getUser()
	},
	activated() {
		console.log(this)
		if (!this.input) {
			this.getUser()
		} else {
			this.searchUser()
		}
	},
	methods: {
		//点击查看用户详情
		seeDetail(row) {
			this.$router.push({
				name: "userDetail",
				query: { id: row.id },
			})
		},
		//分页
		changePages(data) {
			this.limit = data._pageSize
			this.pages = data._currentPage
			console.log(data)
			if (!this.input) {
				this.getUser()
			} else {
				this.searchUser()
			}
		},
		//点击查询
		clickSearch() {
			this.pages = 1
			console.log(!this.input)
			if (this.input == "") {
				this.getUser()
			} else {
				this.searchUser()
			}
		},
		//点击重置
		reset() {
			this.input = ""
			this.pages = 1
			this.getUser()
		},
		//获取用户信息
		async getUser() {
			const res = await this.http
				.post(
					"/admin/user/getUser",
					{
						pages: this.pages,
						limit: this.limit,
					},
					false
				)
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.user
				this.total = res.data.total
			}
		},
		//搜索用户
		async searchUser() {
			const res = await this.http
				.post("admin/user/searchUser", {
					name: this.input,
					pages: this.pages,
					limit: this.limit,
				})
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.user
				this.total = res.data.total
			}
		},
		//删除用户
		delUser(row) {
			this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/user/delUser", {
							userId: row.id,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						this.getUser()
					}
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					})
				})
		},
	},
}
</script>

<style lang="scss" scoped>
.search {
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	border-radius: 50%;
	border: rgb(175, 174, 174) solid 1px;
}
</style>
