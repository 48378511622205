<template>
	<div class="p-10">
		<div class="flex justify-between">
			<div class="text-xl font-bold">
				<i class="cuIcon-titles" style="color:rgba(42, 130, 228, 1)"></i>活动管理
			</div>
			<div class="mr-10">
				<el-button type="primary" @click="toDetail()">编辑</el-button>
				<el-button type="danger" @click="delActivity(delObj)">删除</el-button>
			</div>
		</div>

		<div>
			<el-form ref="form" :model="form" label-width="150px">
				<el-form-item label="头像">
					<div>
						<img class="img1" :src="delObj.avatarUrl" alt="" />
					</div>
				</el-form-item>
				<el-form-item label="发起者">
					<div class="text-lg mt-1.5">{{ delObj.name }}</div>
				</el-form-item>
				<el-form-item label="钓场" class=" text-lg">
					<div class=" text-lg h-7 mt-1 ml-4">{{ form.site }}</div>
					<iframe
						id="mapPage"
						width="500"
						height="500"
						frameborder="0"
						:src="
							`https://apis.map.qq.com/tools/poimarker?type=1&key=ZC2BZ-RFWK6-BDPSX-MPWGJ-PO4MV-ZQBKA&referer=myapp&marker=coord:${latLng}&keyword=${form.site}&center=${latLng}&radius=1000&tonav=0`
						"
					></iframe>
				</el-form-item>
				<el-form-item label="报名截止日期">
					<div class=" text-lg mt-1.5 ">
						{{ form.endTime }}
					</div>
				</el-form-item>
				<el-form-item label="作钓时间">
					<div class=" text-lg mt-1.5 ">{{ form.activityTime }}</div>
				</el-form-item>
				<el-form-item label="参与人数">
					<div class=" text-lg mt-1.5 ">{{ form.num }}</div>
				</el-form-item>
				<el-form-item label="钓法"
					><div class=" text-lg mt-1.5 ">{{ form.skill }}</div></el-form-item
				>
				<el-form-item label="钓场类型">
					<div class=" text-lg mt-1.5 ">{{ form.siteType }}</div></el-form-item
				>
				<el-form-item label="预估费用">
					<div class=" text-lg mt-1.5 ">
						{{
							form.charge != "免费" && form.charge != "未知"
								? form.charge + "元/人"
								: form.charge
						}}
					</div>
				</el-form-item>
			</el-form>
		</div>

		<div class="mt-5 border-2">
			<el-table
				:data="tableData"
				stripe
				:header-cell-style="{ background: '#fafafa' }"
				style="width: 100%"
			>
				<el-table-column label="用户名称">
					<template slot-scope="scope">{{
						scope.row.name ? scope.row.name : scope.row.nickName
					}}</template>
				</el-table-column>
				<el-table-column prop="tel" label="手机号"></el-table-column>
				<el-table-column label="是否需要拼车">
					<template slot-scope="scope">{{
						scope.row.nCarpool == "1" ? "是" : "否"
					}}</template>
				</el-table-column>
				<el-table-column label="有无提供拼车">
					<template slot-scope="scope">{{
						scope.row.pCarpool == "1" ? "是" : "否"
					}}</template>
				</el-table-column>
				<el-table-column prop="address" label="上车地址"></el-table-column>
				<el-table-column prop="pName" label="拼车用户"></el-table-column>
				<el-table-column prop="pNum" label="拼车人数"></el-table-column>
				<el-table-column prop="equipment" label="装备数量"></el-table-column>
			</el-table>
		</div>
		<del-dialog
			:dialogVisible="dialogVisible"
			:name="name"
			:avatarUrl="avatarUrl"
			:id="actId"
			@closeDig="closeDig"
		></del-dialog>
	</div>
</template>

<script>
import pagination from "../../components/Pagination/pagination.vue"
import DelDialog from "./components/DelDialog.vue"
import myMixin from "./minix/minix"
export default {
	components: { pagination, DelDialog },
	data() {
		return {
			activityId: this.$route.query.activityId,
			form: {
				title: "",
				site: "",
				endTime: "",
				activityTime: "",
				num: "",
				skill: "",
				siteType: "",
				charge: "1",
				latitude: "",
				longitude: "",
			},
			latLng: "",
			tableData: [],
			delObj: {
				name: "",
				avatarUrl: "",
				id: this.$route.query.activityId,
			},
		}
	},
	mixins: [myMixin],
	mounted() {
		this.getActivityDetails()
	},
	methods: {
		//获取活动详情
		async getActivityDetails() {
			const res = await this.http
				.post("admin/activity/getActivityDetails", { activityId: this.activityId }, false)
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.form.title = res.data.activity.title
				this.form.site = res.data.activity.site
				this.form.endTime = res.data.activity.endTime
				this.form.activityTime = res.data.activity.activityTime
				let str = res.data.activity.activityTime.split("-")
				this.date = str
				this.form.num = res.data.activity.num
				this.form.skill = res.data.activity.skill
				this.form.siteType = res.data.activity.siteType
				let arr = res.data.activity.siteType.split("/")
				this.value1 = arr
				this.form.latitude = res.data.activity.latitude
				this.form.longitude = res.data.activity.longitude
				this.latLng = res.data.activity.latitude + "," + res.data.activity.longitude
				this.form.charge = res.data.activity.charge
				this.tableData = res.data.user
				this.delObj.name = res.data.activity.name
				this.delObj.avatarUrl = res.data.activity.avatarUrl
			}
		},
		//跳转活动编辑
		toDetail() {
			this.$router.push({
				path: "activityDetail",
				query: { activityId: this.activityId },
			})
		},
	},
}
</script>

<style lang="scss" scoped>
/deep/.el-form-item__label {
	color: rgba(128, 128, 128, 1);
	font-size: 18px;
	font-weight: bold;
}
.img1 {
	width: 65px;
	height: 65px;
	border-radius: 50%;
}
</style>
