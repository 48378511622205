<template>
	<div class="p-10">
		<div class="text-xl font-bold"><i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>鱼币商城管理</div>
		<div class="mt-5 flex">
			<div>
				<el-input v-model="input" placeholder="搜索名称" style="width: 170px"></el-input>
			</div>
			<div class="search ml-4 mt-1 cursor-pointer" @click="clickSearch()">
				<i class="cuIcon-search"></i>
			</div>
			<div class="search ml-4 mt-1 text-white cursor-pointer" style="background: rgba(117, 199, 77, 1)" @click="reset()">
				<i class="el-icon-refresh"></i>
			</div>
			<div class="search ml-4 mt-1 bg-blue-500 text-white cursor-pointer" @click="openAdd()">
				<i class="cuIcon-add"></i>
			</div>
		</div>
		<div class="mt-6">
			<el-table :data="tableData" border style="width: 100%">
				<el-table-column label="主图" width="150">
					<template slot-scope="scope">
						<img :src="getFirstImg(scope.row.img)" alt style="width: 35px; height: 35px" />
					</template>
				</el-table-column>
				<el-table-column prop="name" label="礼品名称" width="300"></el-table-column>
				<!-- <el-table-column prop="integral" label="兑换积分"></el-table-column> -->
				<el-table-column prop="coin" label="鱼币"></el-table-column>
				<el-table-column prop="stock" label="库存"></el-table-column>
				<el-table-column prop="status" label="上架状态">
					<template slot-scope="scope">
						<el-switch
							v-model="scope.row.status"
							active-color="#13ce66"
							active-value="1"
							inactive-value="0"
							:active-text="scope.row.status == '1' ? '上架中' : '已下架'"
							inactive-color="#bebebe"
							@change="updGoodStatus(scope.row)"
						></el-switch>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="200">
					<template slot-scope="scope">
						<el-button style="text-decoration: underline; color: rgba(160, 217, 17, 1)" @click="openDetail(scope.row)" type="text"
							>详情</el-button
						>
						<el-button style="text-decoration: underline" @click="openEdit(scope.row)" type="text">编辑</el-button>
						<el-button style="text-decoration: underline; color: red" type="text" @click="delGood(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<el-dialog
			@opened="getData"
			:destroy-on-close="true"
			:close-on-click-modal="false"
			:title="isEdit ? '编辑礼品' : '新增礼品'"
			:visible.sync="dialog"
			width="700px"
			@close="getGoods()"
		>
			<el-form>
				<el-form-item label="主图" label-width="70px">
					<div class="flex">
						<div style="text-align:right;margin-top:-10px;margin-left:8px" v-for="(item, index) in imgArr" :key="index">
							<i class="el-icon-circle-close" @click="delImg(index)"></i>
							<img v-if="imgArr" :src="item" class="avatar mr-4" />
						</div>
						<div style="margin-top:5px;margin-left:8px" v-if="imgArr.length < 5">
							<el-upload
								class="avatar-uploader"
								ref="upload"
								action="#"
								:show-file-list="false"
								:multiple="false"
								:auto-upload="false"
								:on-change="imgSaveToUrl"
							>
								<!-- <img v-if="form.img" :src="form.img" class="avatar" /> -->
								<i class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="礼品名称" label-width="70px">
					<el-input placeholder="请输入" style="width: 330px" v-model="form.name"></el-input>
				</el-form-item>
				<el-form-item label="鱼币" label-width="70px">
					<el-input type="number" placeholder="请输入" style="width: 160px" v-model="form.coin"></el-input>
					<div class="mt-1" style="color:rgba(198, 198, 198, 1)">0~99999</div>
				</el-form-item>
				<div class="flex">
					<div class="mr-10">
						<el-form-item label="设置库存" label-width="70px">
							<el-input type="number" placeholder="请输入" style="width: 160px" v-model="form.stock"></el-input>
							<div class="mt-1" style="color:rgba(198, 198, 198, 1)">0~99999</div>
						</el-form-item>
					</div>
					<div>
						<el-form-item label="上架状态" label-width="70px">
							<el-switch v-model="form.status" active-value="1" inactive-value="0"></el-switch>
						</el-form-item>
					</div>
				</div>
				<el-form-item label="礼品描述" label-width="70px">
					<!-- <el-input
						v-model="form.intro"
						type="textarea"
						:autosize="{ minRows: 6, maxRows: 6 }"
						placeholder="请输入"
						style="width: 330px"
					></el-input> -->
					<wangEdit :content="introData" @getContent="getContent"></wangEdit>
				</el-form-item>
			</el-form>
			<div class="mt-5 text-center">
				<el-button size="small" class="mr-10" @click=";(dialog = false), getGoods()">取消</el-button>
				<el-button type="primary" size="small" @click="isEdit ? updGood() : addGood()">确定</el-button>
			</div>
		</el-dialog>

		<el-dialog :destroy-on-close="true" :close-on-click-modal="false" title="礼品详情" :visible.sync="dialog1" width="500px">
			<div class="flex items-center">
				<div style="width: 70px; font-weight: bold">主图</div>
				<div style="text-align:right;margin-left:8px;margin-top:15px" v-for="(item, index) in imgArr" :key="index">
					<img v-if="imgArr" :src="item" class="avatar mr-4" />
				</div>
			</div>
			<div class="flex items-center mt-12">
				<div style="width: 70px; font-weight: bold">礼品名称</div>
				<div class="text-black">{{ form1.name }}</div>
			</div>
			<div class="flex items-center mt-12">
				<div style="width: 70px; font-weight: bold">鱼币</div>
				<div class="text-black">{{ form1.coin }}</div>
			</div>
			<div class="flex justify-between mt-12">
				<!-- <div class="flex items-center">
					<div style="width:70px;font-weight:bold">兑换积分</div>
					<div class="text-black">{{ form1.integral }}</div>
				</div> -->
				<div class="flex items-center">
					<div class="mr-4 font-bold">库存</div>
					<div class="text-black">{{ form1.stock }}</div>
				</div>
				<div class="flex items-center mr-4">
					<div class="mr-4 font-bold">上架状态</div>
					<div class="text-black" style="color: rgba(24, 144, 255, 1)">
						{{ form1.status == "1" ? "上架中" : "已下架" }}
					</div>
				</div>
			</div>
			<div class="flex items-center mt-12">
				<div style="width: 70px; font-weight: bold">礼品描述</div>
				<div class="text-black w-96" v-html="form1.intro"></div>
			</div>
		</el-dialog>

		<div class="flex justify-between bg-white ml-4 mr-4 pt-4 pb-4">
			<div></div>
			<!-- 分页器 -->
			<Pagination :currentPage="pages" :total="total" @pageChange="changePages" />
		</div>
	</div>
</template>

<script>
import Pagination from "../../components/Pagination/pagination.vue"
import wangEdit from "../../components/wangEdit/wangEdit.vue"
export default {
	components: { Pagination, wangEdit },
	data() {
		return {
			tableData: [],
			input: "",
			//分页
			total: 0, //总页数
			pages: 1, //当前页数
			limit: 5,
			dialog: false,
			dialog1: false,
			form: {
				img: "",
				name: "",
				// integral: "",
				coin: "",
				stock: "",
				status: "1",
				intro: "",
			},
			form1: {
				imgUrl: "",
				name: "",
				coin: "",
				// integral: "",
				stock: "",
				state: "",
				intro: "",
			},
			isEdit: false,
			midData: "",
			introData: "",
			imgArr: [],
		}
	},

	computed: {},

	watch: {},
	mounted() {
		this.getGoods() //一开始获取数据
	},
	methods: {
		getFirstImg(val) {
			if (Array.isArray(val)) {
				return val[0]
			}
			let img = JSON.parse(val)
			return img[0]
		},
		//获取富文本
		getContent(data) {
			this.form.intro = data
		},
		//分页
		changePages(data) {
			this.pages = data._currentPage
			this.limit = data._pageSize
			if (!this.input) {
				this.getGoods()
			} else {
				this.searchGoods()
			}
		},
		//点击查询
		clickSearch() {
			this.pages = 1
			if (this.input == "") {
				this.getGoods()
			} else {
				this.searchGoods()
			}
		},
		//点击重置
		reset() {
			this.input = ""
			this.pages = 1
			this.getGoods()
		},
		//获取商品
		async getGoods() {
			const res = await this.http
				.post(
					"admin/goods/getGoods",
					{
						pages: this.pages,
						limit: this.limit,
					},
					false
				)
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.goods
				this.total = res.data.total
			}
		},
		//打开新增
		openAdd() {
			this.isEdit = false
			this.dialog = true
			this.introData = ""
			this.form = {
				img: "",
				status: true,
				intro: "",
			}
		},
		getData() {
			this.introData = ""
			this.$nextTick(() => {
				this.isEdit ? (this.introData = this.midData) : (this.introData = "")
			})
		},
		panduan(val) {
			if (val >= 0 && val <= 99999) {
				return true
			}
			return false
		},
		//添加商品
		async addGood() {
			if (this.check.checkHasEmpty(this.form) || !this.panduan(this.form.coin) || !this.panduan(this.form.stock)) {
				//调用函数checkHasEmpty() 检查必填字段是否有为空的
				this.$message("请将数据填写正确或者完整")
			} else {
				this.form.img = JSON.stringify(this.imgArr)
				const res = await this.http
					.post("admin/goods/addGood", {
						img: this.form.img,
						name: this.form.name,
						integral: this.form.integral,
						stock: this.form.stock,
						coin: this.form.coin,
						status: this.form.status,
						intro: this.form.intro,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.dialog = false
					this.getGoods()
				}
			}
		},
		//打开编辑
		async openEdit(row) {
			this.imgArr = JSON.parse(row.img)
			this.form = row
			this.midData = row.intro
			this.isEdit = true
			this.dialog = true
		},
		//编辑商品
		async updGood() {
			if (this.check.checkHasEmpty(this.form) || !this.panduan(this.form.coin) || !this.panduan(this.form.stock)) {
				//调用函数checkHasEmpty() 检查必填字段是否有为空的
				console.log(this.form)
				this.$message("请将数据填写正确或者完整")
			} else {
				this.form.img = JSON.stringify(this.imgArr)
				const res = await this.http
					.post("admin/goods/updGood", {
						goodId: this.form.id,
						img: this.form.img,
						name: this.form.name,
						coin: this.form.coin,
						integral: this.form.integral,
						stock: this.form.stock,
						status: this.form.status,
						intro: this.form.intro,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.dialog = false
					this.getGoods()
				}
			}
		},
		//编辑商品状态
		async updGoodStatus(row) {
			console.log(row.status)
			let flag = row.status
			row.status == "1" ? (row.status = "0") : (row.status = "1")
			this.$confirm("是否改变该状态", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/goods/updGood", {
							goodId: row.id,
							status: flag,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						flag == "1" ? (row.status = "1") : (row.status = "0") // 这一步很重要，row.showState会根据flag的值开启或关闭开关
					}
				})
				.catch(() => {
					this.$message({ type: "info", showClose: true, message: "已取消修改！" })
				})
		},
		//删除商品
		delGood(row) {
			this.$confirm("此操作将永久删除该商品, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/goods/delGood", {
							goodId: row.id,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						this.getGoods()
					}
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					})
				})
		},
		//搜索商品
		async searchGoods() {
			const res = await this.http
				.post("admin/goods/searchGoods", {
					name: this.input,
					pages: this.pages,
					limit: this.limit,
				})
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.goods
				console.log(this.tableData)
				this.total = res.data.total
			}
		},
		//打开详情
		openDetail(row) {
			this.dialog1 = true
			this.imgArr = JSON.parse(row.img)
			this.form1 = row
		},
		// 图片上传
		async imgSaveToUrl(event) {
			if (this.beforeUploadPicture(event.raw)) {
				var formData = new FormData()
				formData.append("img", event.raw)
				console.log(formData)
				this.http.upload("admin/upload/uploadImg", formData).then((res) => {
					if (res.code == 1) {
						this.imgArr.push(res.data)
						this.form.img = this.imgArr
						this.$message.success("上传成功")
					}
				})
			}
		},
		// 图片上传之前
		beforeUploadPicture(file) {
			const isJPG = file.type === "image/jpeg"
			const isPNG = file.type === "image/png"
			const isLt10M = file.size / 1024 / 1024 < 10
			if (!isJPG && !isPNG) {
				this.$message.error("上传图片只能是 JPG 或者 PNG 格式!")
			}
			if (isJPG && isPNG) {
				if (!isLt10M) {
					this.$message.error("上传图片大小不能超过 10MB!")
				}
			}
			return (isJPG || isPNG) && isLt10M
		},
		//删除图片数组
		delImg(index) {
			this.imgArr.splice(index, 1)
			console.log(this.imgArr)
		},
	},
}
</script>
<style lang="scss" scoped>
.search {
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	border-radius: 50%;
	border: rgb(175, 174, 174) solid 1px;
}
/deep/ .avatar-uploader .el-upload {
	border: 1px dashed hsl(0, 0%, 85%);
	cursor: pointer;
	position: relative;
	overflow: hidden;
	background: rgb(204, 204, 204);
}
/deep/.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 66px; //上传图片的宽
	height: 66px; //上传图片的高
	line-height: 66px;
	text-align: center;
}
/deep/ .avatar {
	width: 66px; //图片回显的宽
	height: 66px; //图片回显的高
	display: block;
	margin-top: -25px;
}
.imgDetail {
	width: 76px;
	height: 76px;
}
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
}
/deep/ input[type="number"] {
	-moz-appearance: textfield !important;
}
</style>
