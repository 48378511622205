<template>
	<div class="p-10">
		<div class="flex justify-between items-center">
			<div class="text-xl font-bold"><i class="cuIcon-titles" style="color:rgba(42, 130, 228, 1)"></i>任务管理</div>
			<div v-if="isUpd">
				<el-button type="primary" style="height:50px;width:120px;font-size:20px" @click="updTask()">保存</el-button>
				<el-button type="info" @click=";(isUpd = false), getTask()" style="height:50px;width:120px;font-size:20px">取消</el-button>
			</div>
			<div v-else>
				<el-button type="primary" style="height:50px;width:120px;font-size:20px;" @click="isUpd = true">编辑</el-button>
			</div>
		</div>
		<noviceTask :task="task.新手任务" :isUpd="isUpd"></noviceTask>
		<releaseTask :task="task.任务" :isUpd="isUpd" ref="release"></releaseTask>
		<dailyTask :task="task.日常任务" :signIn="task.每日签到" :isUpd="isUpd" ref="daily"></dailyTask>
		<specialTask :task="task.特殊任务" :isUpd="isUpd" ref="special"></specialTask>
	</div>
</template>

<script>
import noviceTask from "../task/components/noviceTask.vue"
import releaseTask from "../task/components/releaseTask.vue"
import dailyTask from "../task/components/dailyTask.vue"
import specialTask from "../task/components/specialTask.vue"

export default {
	components: {
		noviceTask,
		releaseTask,
		dailyTask,
		specialTask,
	},
	data() {
		return {
			task: {
				任务: [],
				新手任务: [],
				日常任务: [],
				特殊任务: [],
				每日签到: [],
			},
			isUpd: false,
		}
	},

	mounted() {
		this.getTask()
	},
	methods: {
		baocun() {
			console.log(this.task.新手任务)
		},
		getNoviceTask(task) {
			this.task.新手任务 = task
			console.log(task)
		},
		//获取任务
		async getTask() {
			const res = await this.http.post("admin/task/getTask", {}, false).catch((err) => {
				console.log(err)
			})
			if (res.code == 1) {
				this.task.任务 = res.data.任务
				this.task.新手任务 = res.data.新手任务
				this.task.日常任务 = res.data.日常任务
				this.task.特殊任务 = res.data.特殊任务
				this.task.每日签到 = res.data.每日签到
				let specialArr = this.task.特殊任务.map((item) => {
					let arr = item.name.split("，")
					for (let i in arr) {
						item.name = arr[i]
						arr[i] = JSON.parse(JSON.stringify(item))
					}
					return arr
				})
				this.task.特殊任务 = specialArr[0]
			}
		},
		//编辑任务
		async updTask() {
			let release = this.$refs.release
			this.task.任务[1].name = "获得" + release.value.value1 + "个收藏"
			this.task.任务[2].name = "获得" + release.value.value2 + "个评论"
			this.task.任务[3].name = "获得" + release.value.value3 + "个喜欢"
			let daily = this.$refs.daily
			this.task.日常任务[1].name = "点赞次数超过" + daily.value.value1 + "次"
			this.task.日常任务[2].name = "评论次数超过" + daily.value.value2 + "次"
			this.task.日常任务[3].name = "分享渔获次数超过" + daily.value.value3 + "次"
			this.task.日常任务[4].name = "看渔获次数超过" + daily.value.value4 + "次"
			this.task.日常任务[5].name = "看教程次数超过" + daily.value.value5 + "次"
			this.task.日常任务[6].name = "参与约钓活动次数超过" + daily.value.value6 + "次"
			let special = this.$refs.special
			this.task.特殊任务 = [this.task.特殊任务.shift()]
			this.task.特殊任务[0].name = `帖子上热门，点赞次数${special.value.value1}次，评论次数${special.value.value2}次`
			// this.task.特殊任务 =
			for (let i of daily.value.value7) {
				switch (i) {
					case "签到第1天":
						this.task.每日签到[0].coin = this.task.日常任务[0].coin
						this.task.每日签到[0].exp = this.task.日常任务[0].exp
						break
					case "签到第2天":
						this.task.每日签到[1].coin = this.task.日常任务[0].coin
						this.task.每日签到[1].exp = this.task.日常任务[0].exp
						break
					case "签到第3天":
						this.task.每日签到[2].coin = this.task.日常任务[0].coin
						this.task.每日签到[2].exp = this.task.日常任务[0].exp
						break
					case "签到第4天":
						this.task.每日签到[3].coin = this.task.日常任务[0].coin
						this.task.每日签到[3].exp = this.task.日常任务[0].exp
						break
					case "签到第5天":
						this.task.每日签到[4].coin = this.task.日常任务[0].coin
						this.task.每日签到[4].exp = this.task.日常任务[0].exp
						break
					case "签到第6天":
						this.task.每日签到[5].coin = this.task.日常任务[0].coin
						this.task.每日签到[5].exp = this.task.日常任务[0].exp
						break
					default:
						this.task.每日签到[6].coin = this.task.日常任务[0].coin
						this.task.每日签到[6].exp = this.task.日常任务[0].exp
						break
				}
			}
			let arr = []
			for (let i in this.task) {
				arr.push(this.task[i])
			}
			let allArr = []
			for (let i in arr) {
				arr[i].map((a) => {
					delete a.class
					allArr.push(a)
				})
			}
			const res = await this.http.post("/admin/task/updTask", { data: allArr }).catch((err) => {
				console.log(err)
			})
			if (res.code == 1) {
				this.getTask()
				this.isUpd = false
			}
		},
	},
}
</script>

<style></style>
