<template>
	<div class="p-10">
		<div class="text-xl font-bold flex justify-between">
			<div><i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>用户详情</div>
			<div class="mr-5" v-if="edit == false">
				<el-button type="primary" @click="clickEdit()">编辑</el-button>
				<el-button type="info" @click="toBack()">返回</el-button>
			</div>
			<div class="mr-5" v-if="edit">
				<el-button type="primary" @click="updUser()">保存</el-button>
				<el-button type="danger" @click=";(edit = false), getUserDetails()">取消</el-button>
			</div>
		</div>
		<div class="mt-7 pl-5" style="color: rgba(128, 128, 128, 1); font-size: 20px">
			<div class="flex">
				<div class="flex items-center mr-10">
					<div class="w-11">头像</div>
					<div>
						<div style="width: 77px; height: 77px" class="ml-4">
							<img style="border-radius: 50%;width: 77px; height: 77px" v-if="userInfo.avatarUrl" :src="userInfo.avatarUrl" alt />
						</div>
						<div v-if="edit" class="ml-4 font-bold" style="color: rgba(42, 130, 228, 1)">
							<el-upload
								ref="upload"
								action="#"
								:show-file-list="false"
								:multiple="false"
								:auto-upload="false"
								:on-change="imgSaveToUrl"
							>
								<p class=" text-lg ml-1">重新上传</p>
							</el-upload>
						</div>
					</div>
				</div>
				<div>
					<div class="flex">
						<div class="mr-3 flex items-center w-56">
							<div style="width: 60px">账号：</div>
							<div class="text-black ml-5" v-if="edit == false">
								{{ userInfo.account }}
							</div>
							<div class="text-black" v-if="edit">
								<el-input placeholder="请输入内容" v-model="userInfo.account"></el-input>
							</div>
						</div>
						<div class="mr-3 flex items-center w-72">
							<div style="width: 100px">微信昵称：</div>
							<div class="text-black ml-5" v-if="edit == false">
								{{ userInfo.nickName }}
							</div>
							<div class="text-black" v-if="edit">
								<el-input placeholder="请输入内容" v-model="userInfo.nickName"></el-input>
							</div>
						</div>
						<div class="mr-3 flex items-center w-64">
							<div style="width: 80px;text-align: right;">昵称：</div>
							<div class="text-black ml-5" v-if="edit == false">
								{{ userInfo.name }}
							</div>
							<div class="text-black" v-if="edit">
								<el-input placeholder="请输入内容" v-model="userInfo.name"></el-input>
							</div>
						</div>
						<div class="mr-3 flex items-center w-56">
							<div style="width: 60px">等级：</div>
							<div class="text-black ml-5">
								{{ userInfo.level }}
							</div>
						</div>
					</div>

					<div class="flex mt-3">
						<div class="mr-3 flex items-center w-56">
							<div style="width: 60px">钓龄：</div>
							<div class="text-black ml-5" v-if="edit == false">
								{{ userInfo.age }}
							</div>
							<div class="text-black h-10" v-if="edit">
								<el-input type="number" placeholder="请输入多少年" v-model="userInfo.age"></el-input>
							</div>
						</div>
						<div class="mr-3 flex items-center w-72">
							<div style="width: 100px;text-align: right;">钓法：</div>
							<div class="text-black ml-5" v-if="edit == false">
								{{ userInfo.skill }}
							</div>
							<div class="text-black" v-if="edit">
								<el-input placeholder="请输入内容" v-model="userInfo.skill"></el-input>
							</div>
						</div>
						<div class="mr-3 flex items-center w-64">
							<div style="width: 80px">手机号：</div>
							<div class="text-black ml-5" v-if="edit == false">
								{{ userInfo.tel }}
							</div>
							<div class="text-black" v-if="edit">
								<el-input
									type="number"
									placeholder="请输入手机号"
									v-model="userInfo.tel"
									onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
								></el-input>
							</div>
						</div>
						<div class="mr-3 flex items-center">
							<div style="width: 60px">地区：</div>
							<div class="text-black ml-5" v-if="edit == false">
								{{ userInfo.area | delArr }}
							</div>
							<div class="text-black" v-if="edit">
								<el-cascader size="large" :options="options" v-model="selectedOptions" @change="handleChange"> </el-cascader>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="fenge"></div>

			<div class="flex">
				<div class="flex items-center mr-20">
					<div style="width: 60px">获赞：</div>
					<div class="text-black ml-5">{{ userInfo.favorNum }}</div>
				</div>
				<div class="flex items-center mr-20">
					<div style="width: 60px">关注：</div>
					<div class="text-black ml-5">{{ userInfo.followNum }}</div>
				</div>
				<div class="flex items-center mr-20">
					<div style="width: 60px">粉丝：</div>
					<div class="text-black ml-5">{{ userInfo.fansNum }}</div>
				</div>
				<div class="flex items-center mr-20">
					<div style="width: 60px">收藏：</div>
					<div class="text-black ml-5">{{ userInfo.collectionNum }}</div>
				</div>
				<div class="flex items-center mr-20">
					<div style="width: 60px">鱼币：</div>
					<div class="text-black ml-5">{{ userInfo.coin }}</div>
				</div>
			</div>

			<div class="fenge"></div>

			<div class="flex">
				<div class="flex items-center mr-20">
					<div class="w-32">等级排行榜：</div>
					<div class="text-black ml-5">{{ rank.level }}</div>
				</div>
				<div class="flex items-center mr-20">
					<div class="w-32">任务排行榜：</div>
					<div class="text-black ml-5">{{ rank.task }}</div>
				</div>
				<div class="flex items-center mr-20">
					<div class="w-32">鱼币排行榜：</div>
					<div class="text-black ml-5">{{ rank.coin }}</div>
				</div>
				<div class="flex items-center mr-20">
					<div class="w-32">粉丝排行榜：</div>
					<div class="text-black ml-5">{{ rank.fans }}</div>
				</div>
			</div>
		</div>
		<div class="flex" style="width: 100%">
			<div class="text-xl font-bold mt-32 flex-1">
				<div><i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>地址管理</div>
				<div class="flex mt-7 items-baseline">
					<div class="bg-red-700 text-white py-1 px-4 mr-5" style="font-size: 14px; border-radius: 10px;">
						<p style="width:60px">默认地址</p>
					</div>
					<div class="text-xl font-normal mr-7">
						<div class="mt-5" v-for="(item, index) in address" :key="index">{{ item.area }}{{ item.address }}{{ item.defAddress }}</div>
					</div>
				</div>
			</div>
			<div class="text-xl font-bold mt-32 flex-1">
				<div><i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>禁言管理</div>
				<div class="flex items-center mt-4 font-normal">
					<div style="width: 100px">用户禁言：</div>
					<div>
						<el-select v-model="userInfo.status" style="width: 100%" @change="changeStatus" :disabled="!edit">
							<el-option label="禁言" value="禁言"></el-option>
							<el-option label="限时间" value="限时间"></el-option>
							<el-option label="解封" value="解封"></el-option>
						</el-select>
					</div>
				</div>
				<div class="mt-6 font-normal flex">
					<div style="width: 100px">限时间：</div>
					<div>
						<el-date-picker
							v-model="date"
							type="datetimerange"
							range-separator="至"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
							value-format="yyyy-MM-dd HH:mm:ss"
							:picker-options="pickerOptions1"
							@change="changData"
							:disabled="!edit || isBan"
						>
						</el-date-picker>
					</div>
				</div>
				<div class="mt-6 font-normal flex">
					<div style="width: 100px">原因：</div>
					<div>
						<el-input type="textarea" rows="6" placeholder="请输入内容" v-model="reason" :disabled="!edit || !isReason"> </el-input>
					</div>
				</div>
			</div>
		</div>
		<div class="text-xl font-bold mt-10">
			<div><i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>发布渔获</div>
			<div class="mt-5">
				<el-table :data="tableData" stripe :header-cell-style="{ background: '#fafafa' }" style="width: 100%">
					<el-table-column prop="content" label="渔获"></el-table-column>
					<el-table-column prop="createTime" label="发布时间"></el-table-column>
					<el-table-column label="置顶状态">
						<template slot-scope="scope">
							<el-switch
								v-model="scope.row.top"
								active-value="1"
								inactive-value="0"
								active-color="#13ce66"
								inactive-color="#bebebe"
								@change="changStatus0(scope.row)"
							></el-switch>
						</template>
					</el-table-column>
					<el-table-column label="精品状态">
						<template slot-scope="scope">
							<el-switch
								v-model="scope.row.boutique"
								active-value="1"
								inactive-value="0"
								active-color="#13ce66"
								inactive-color="#bebebe"
								@change="changStatus1(scope.row)"
							></el-switch>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="150">
						<template slot-scope="scope">
							<el-button style="text-decoration: underline" @click="harvestDetail(scope.row)" type="text">编辑</el-button>
							<el-button style="text-decoration: underline; color: red" type="text" @click="delHarvest(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<pagination :isShow="false" :currentPage="pages" :total="total" @pageChange="changePages"></pagination>
	</div>
</template>

<script>
import pagination from "../../components/Pagination/pagination.vue"
import { provinceAndCityData, CodeToText, TextToCode } from "element-china-area-data"
export default {
	components: { pagination },
	data() {
		return {
			edit: false,
			input: "",
			id: this.$route.query.id,
			userInfo: { avatarUrl: "", status: "解封", vStart: "", vEnd: "" },
			reason: "",
			date: [],
			rank: "",
			address: "",
			banSpeak: "",
			tableData: [],
			total: 0, //总页数
			pages: 1, //当前页数
			limit: 5,
			options: provinceAndCityData,
			selectedOptions: [],
			isBan: false,
			isReason: false,
			pickerOptions1: {
				disabledDate(time) {
					return time.getTime() < Date.now() - 8.64e7
				},
			},
		}
	},
	filters: {
		delArr: function(val) {
			if (val) {
				let arr = eval("(" + val + ")")
				return arr[0] + "," + arr[1]
			}
		},
	},
	methods: {
		//分页
		changePages(data) {
			this.pages = data._currentPage
			this.limit = data._pageSize
			this.getHarvest()
		},
		clickEdit() {
			this.edit = true
			if (this.userInfo.status == "解封") {
				this.isBan = true
				this.isReason = false
			} else if (this.userInfo.status == "禁言") {
				this.isBan = true
				this.isReason = true
			} else {
				this.isReason = true
			}
			console.log(this.isBan || !this.edit)
		},
		//获取用户详情
		async getUserDetails() {
			const res = await this.http
				.post(
					"admin/user/getUserDetails",
					{
						userId: this.id,
					},
					false
				)
				.catch((err) => {
					console.log(err)
				})
			this.userInfo = res.data.userInfo
			if (res.data.userInfo.area != "" && res.data.userInfo.area != null) {
				let str = res.data.userInfo.area
				if (this.selectedOptions.length == 0) {
					const arr = eval("(" + str + ")")
					this.selectedOptions.push(TextToCode[arr[0]].code, TextToCode[arr[0]][arr[1]].code)
				}
			}
			if (res.data.userInfo.vStart != null && res.data.userInfo.vEnd != null) {
				this.date = []
				this.date.push(res.data.userInfo.vStart, res.data.userInfo.vEnd)
			}
			this.rank = res.data.rank
			this.address = res.data.address
			console.log(this.userInfo)
		},
		//获取用户渔获
		async getHarvest() {
			const res = await this.http
				.post(
					"admin/user/getHarvest",
					{
						userId: this.id,
						pages: this.pages,
						limit: this.limit,
					},
					false
				)
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.harvest
				this.total = res.data.total
			}
		},
		//删除渔获
		async delHarvest(row) {
			this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = this.http
						.post("admin/user/delHarvest", {
							harvestId: row.id,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						this.getHarvest()
					}
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					})
				})
		},
		//选择地区
		handleChange() {
			let arr = []
			arr[0] = CodeToText[this.selectedOptions[0]]
			arr[1] = CodeToText[this.selectedOptions[1]]
			this.userInfo.area = JSON.stringify(arr)
		},
		//查看渔获信息
		harvestDetail(row) {
			this.$router.push({
				name: "harvest",
				query: { harvestId: row.id },
			})
		},
		//选择状态
		changeStatus(event) {
			this.userInfo.status = event
			switch (event) {
				case "禁言":
					this.isBan = true
					this.isReason = true
					break
				case "限时间":
					this.isBan = false
					this.isReason = true
					break
				default:
					this.isBan = true
					this.isReason = false
			}
		},
		//选择日期
		changData(event) {
			this.userInfo.vStart = event[0]
			this.userInfo.vEnd = event[1]
		},
		//编辑用户
		async updUser() {
			if (this.userInfo.status != "解封" && this.reason == "") {
				this.$message("请填写禁言原因")
			} else if (!this.userInfo.vEnd && this.userInfo.status == "限时间") {
				this.$message("请填写禁言时间")
			} else {
				const res = await this.http
					.post("admin/user/updUser", {
						userId: this.id,
						avatarUrl: this.userInfo.avatarUrl,
						account: this.userInfo.account,
						name: this.userInfo.name,
						nickName: this.userInfo.nickName,
						age: this.userInfo.age,
						skill: this.userInfo.skill,
						area: this.userInfo.area,
						status: this.userInfo.status,
						vStart: this.userInfo.vStart,
						vEnd: this.userInfo.vEnd,
						tel: this.userInfo.tel,
						reason: this.userInfo.status == "解封" ? "" : this.reason,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.edit = false
					this.getUserDetails()
				}
			}
		},

		//编辑详情
		changStatus0(row) {
			let flag = row.top
			row.top == "1" ? (row.top = "0") : (row.top = "1")
			this.$confirm("是否改变该状态", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/article/updHarvest", {
							harvestId: row.id,
							top: flag,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						flag == "1" ? (row.top = "1") : (row.top = "0") // 这一步很重要，row.showState会根据flag的值开启或关闭开关
					}
				})
				.catch(() => {
					this.$message({ type: "info", showClose: true, message: "已取消修改！" })
				})
		},
		async changStatus1(row) {
			let flag = row.boutique
			row.boutique == "1" ? (row.boutique = "0") : (row.boutique = "1")
			this.$confirm("是否改变该状态", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/article/updHarvest", {
							harvestId: row.id,
							boutique: flag,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						flag == "1" ? (row.boutique = "1") : (row.boutique = "0")
					}
				})
				.catch(() => {
					this.$message({ type: "info", showClose: true, message: "已取消修改！" })
				})
		},
		// 图片上传
		async imgSaveToUrl(event) {
			if (this.beforeUploadPicture(event.raw)) {
				var formData = new FormData()
				formData.append("img", event.raw)
				console.log(formData)
				this.http.upload("admin/upload/uploadImg", formData).then((res) => {
					if (res.code == 1) {
						this.userInfo.avatarUrl = res.data
						this.$message.success("上传成功")
					}
				})
			}
		},
		// 图片上传之前
		beforeUploadPicture(file) {
			const isJPG = file.type === "image/jpeg"
			const isPNG = file.type === "image/png"
			const isLt10M = file.size / 1024 / 1024 < 10
			if (!isJPG && !isPNG) {
				this.$message.error("上传图片只能是 JPG 或者 PNG 格式!")
			}
			if (isJPG && isPNG) {
				if (!isLt10M) {
					this.$message.error("上传图片大小不能超过 10MB!")
				}
			}
			return (isJPG || isPNG) && isLt10M
		},
		toBack() {
			this.$router.go(-1)
		},
	},
	mounted() {
		this.getUserDetails()
		this.getHarvest()
	},
}
</script>

<style lang="scss" scoped>
/deep/.el-input {
	width: 160px;
}
.fenge {
	height: 1px;
	width: 100%;
	background-color: rgba(153, 153, 153, 1);
	margin-top: 2rem;
	margin-bottom: 2rem;
}
/deep/ .avatar {
	width: 77px; //图片回显的宽
	height: 77px; //图片回显的高
	display: block;
	border-radius: 50%;
}
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
}
/deep/ input[type="number"] {
	-moz-appearance: textfield !important;
}
</style>
