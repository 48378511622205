<template>
	<div class="p-10">
		<div class="flex justify-between">
			<div class="text-xl font-bold"><i class="cuIcon-titles" style="color:rgba(42, 130, 228, 1)"></i>活动管理</div>
			<div class="mr-10">
				<el-button type="primary" size="medium" @click="isUpd ? updActivity() : addActivity()">保存</el-button>
				<el-button type="info" size="medium" @click="toBack()">取消</el-button>
			</div>
		</div>
		<div>
			<el-form ref="actForm" :model="form" :rules="rules" label-width="150px" :hide-required-asterisk="true">
				<el-form-item label="活动名称">
					<el-input v-model="form.title" style="width:250px"></el-input>
				</el-form-item>
				<el-form-item label="钓场">
					<div class=" text-lg h-7 mt-1 ml-4">{{ form.site }}</div>
					<iframe
						id="mapPage"
						width="500"
						height="500"
						frameborder="0"
						:src="
							`https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=ZC2BZ-RFWK6-BDPSX-MPWGJ-PO4MV-ZQBKA&referer=myapp&coord=${latLng}`
						"
					></iframe>
				</el-form-item>
				<el-form-item label="报名截止日期">
					<div class="flex">
						<el-date-picker
							placeholder="选择日期"
							v-model="form.endTime"
							style="width: 400px;margin-right:40px"
							value-format="yyyy-MM-dd"
							:picker-options="pickerOptions1"
							@change="getDate"
						></el-date-picker>
					</div>
				</el-form-item>
				<el-form-item label="作钓时间">
					<el-date-picker
						v-model="date"
						type="daterange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						style="width:400px"
						value-format="yyyy/MM/dd"
						:picker-options="pickerOptions1"
						@change="changeDate"
					>
					</el-date-picker>
				</el-form-item>
				<el-form-item label="参与人数" prop="num">
					<el-input
						v-model="form.num"
						style="width:170px"
						type="number"
						onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
					></el-input>
				</el-form-item>
				<el-form-item label="钓法"
					><el-select v-model="form.skill" placeholder="请选择" style="width:170px" @change="getSkill">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select
				></el-form-item>
				<el-form-item label="钓场类型"
					><el-cascader
						v-model="value1"
						:options="options1"
						separator=","
						:props="{ expandTrigger: 'hover' }"
						style="width:170px"
						@change="getType"
					></el-cascader
				></el-form-item>
				<el-form-item label="预估费用">
					<el-radio v-model="form.charge" label="1"
						><input
							type="number"
							v-model="value2"
							style="border-bottom: 1px solid #000;border-top: 0px;border-left: 0px;border-right: 0px;outline: none;width:80px"
							onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
						/>元/人</el-radio
					>
					<el-radio v-model="form.charge" label="免费">免费</el-radio>
					<el-radio v-model="form.charge" label="未知">未知</el-radio>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			activityId: this.$route.query.activityId,
			isUpd: typeof this.$route.query.activityId == "undefined" ? false : true,
			checked: true,
			tableData: [],
			form: {
				title: "",
				site: "",
				endTime: "",
				activityTime: "",
				num: "",
				skill: "",
				siteType: "",
				charge: "1",
				latitude: "",
				longitude: "",
			},
			value1: [],
			options: [],
			options1: [
				{
					value: "钓场",
					label: "钓场",
					children: [
						{
							value: "赌塘-收费",
							label: "赌塘-收费",
						},
						{
							value: "手感塘-收费",
							label: "手感塘-收费",
						},
					],
				},
				{
					value: "野外",
					label: "野外",
					children: [
						{
							value: "水库-收费",
							label: "水库-收费",
						},
						{
							value: "水库",
							label: "水库",
						},
						{
							value: "江流",
							label: "江流",
						},
						{
							value: "河流",
							label: "河流",
						},
						{
							value: "湖泊",
							label: "湖泊",
						},
						{
							value: "海洋",
							label: "海洋",
						},
						{
							value: "溪流",
							label: "溪流",
						},
					],
				},
			],
			value2: 1000,
			latLng: "",
			date: [],
			rules: {
				num: [
					{ required: true, message: "请输入整数", trigger: "blur" },
					{
						required: true,
						pattern: /^\d+$|^\d+[.]?\d+$/,
						message: "请输入整数",
						trigger: "blur",
					},
				],
			},
			pickerOptions1: {
				disabledDate(time) {
					return time.getTime() < Date.now() - 8.64e7
				},
			},
		}
	},
	mounted() {
		window.addEventListener(
			"message",
			(event) => {
				// 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
				if (typeof event.data.latlng != "undefined") {
					console.log(event.data)
					let loc = event.data
					this.form.site = loc.poiname
					let latlng = loc.latlng
					this.form.latitude = latlng.lat
					this.form.longitude = latlng.lng
				}
			},
			false
		)
		this.getActivityDetails()
		this.getMethod()
	},
	methods: {
		//报名截止日期时间
		getDate(event) {
			console.log(event)
			this.form.endTime = event
			// this.form. =
		},
		//作钓时间
		changeDate(event) {
			this.form.activityTime = event[0] + "-" + event[1]
			console.log(this.form.activityTime)
		},
		//获取钓法
		getSkill(event) {
			console.log(event)
			this.form.skill = event
		},
		//获取钓场类型
		getType(event) {
			console.log(event)
			this.form.siteType = event[0] + "," + event[1]
		},
		//获取活动详情
		async getActivityDetails() {
			const res = await this.http
				.post(
					"admin/activity/getActivityDetails",
					{
						activityId: this.activityId,
					},
					false
				)
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.form.title = res.data.activity.title
				this.form.site = res.data.activity.site
				this.form.endTime = res.data.activity.endTime
				this.form.activityTime = res.data.activity.activityTime
				let str = res.data.activity.activityTime.split("-")
				this.date = str
				this.form.num = res.data.activity.num
				this.form.skill = res.data.activity.skill
				this.form.siteType = res.data.activity.siteType
				let arr = res.data.activity.siteType.split(",")
				this.value1 = arr
				console.log(this.value1)
				this.form.latitude = res.data.activity.latitude
				this.form.longitude = res.data.activity.longitude
				this.latLng = res.data.activity.latitude + "," + res.data.activity.longitude
				if (res.data.activity.charge != "免费" && res.data.activity.charge != "未知") {
					this.value2 = res.data.activity.charge
					this.form.charge = "1"
				} else {
					this.form.charge = res.data.activity.charge
				}
			}
		},
		//获取钓法
		async getMethod() {
			const res = await this.http.get("minwx/choice/skill")
			let arr = []
			let obj = { value: "", label: "" }
			if (res.code == 1) {
				for (let i in res.data) {
					obj.value = res.data[i].text
					obj.label = res.data[i].text
					arr.push(JSON.parse(JSON.stringify(obj)))
				}
				console.log(arr)
			}
			this.options = arr
		},
		//新增活动
		addActivity() {
			this.$refs.actForm.validate(async (valid) => {
				if (valid) {
					if (this.form.num < 2 && this.form.num > 99999) {
						this.$message("参与人数大于等于2且小于等于99999！")
					} else if (this.form.charge == "1" && Number(this.value2) > 99999) {
						this.$message("预估费用要小于等于99999！")
					} else {
						if (this.check.checkHasEmpty(this.form)) {
							//调用函数checkHasEmpty() 检查必填字段是否有为空的
							this.$message("请将数据填写完整")
						} else if (new Date(this.form.endTime) > new Date(this.form.activityTime.split("-")[0])) {
							this.$message("报名截止日期不能大于作钓初始时间")
						} else {
							const res = await this.http
								.post("admin/activity/addActivity", {
									managerId: this.$store.state.user.id,
									title: this.form.title,
									site: this.form.site,
									endTime: this.form.endTime,
									activityTime: this.form.activityTime,
									num: this.form.num,
									skill: this.form.skill,
									charge: this.form.charge == "1" ? this.value2 : this.form.charge,
									siteType: this.form.siteType,
									latitude: this.form.latitude,
									longitude: this.form.longitude,
								})
								.catch((err) => {
									console.log(err)
								})
							if (res.code == 1) {
								this.$router.go(-1)
							}
						}
					}
				} else {
					this.$message({
						showClose: true,
						message: "请根据提示填写正确信息",
					})
					return false
				}
			})
		},
		//编辑活动
		async updActivity() {
			this.$refs.actForm.validate(async (valid) => {
				if (valid) {
					if (this.form.num < 2 || this.form.num > 99999) {
						this.$message("参与人数大于等于2且小于等于99999！")
					} else if (this.form.charge == "1" && Number(this.value2) > 99999) {
						this.$message("预估费用要小于等于99999！")
					} else {
						if (this.check.checkHasEmpty(this.form)) {
							//调用函数checkHasEmpty() 检查必填字段是否有为空的
							this.$message("请将数据填写完整")
						} else if (new Date(this.form.endTime) > new Date(this.form.activityTime.split("-")[0])) {
							this.$message("报名截止日期不能大于作钓初始时间")
						} else {
							const res = await this.http
								.post("admin/activity/updActivity", {
									activityId: this.activityId,
									title: this.form.title,
									site: this.form.site,
									endTime: this.form.endTime,
									activityTime: this.form.activityTime,
									num: this.form.num,
									skill: this.form.skill,
									charge: this.form.charge == "1" ? this.value2 : this.form.charge,
									siteType: this.form.siteType,
									latitude: this.form.latitude,
									longitude: this.form.longitude,
								})
								.catch((err) => {
									console.log(err)
								})
							if (res.code == 1) {
								this.$router.go(-1)
							}
						}
					}
				} else {
					this.$message({
						showClose: true,
						message: "请根据提示填写正确信息",
					})
					return false
				}
			})
		},
		//返回
		toBack() {
			this.$router.go(-1)
		},
	},
}
</script>

<style lang="scss" scoped>
.activity {
	width: 300px; //图片回显的宽
	height: 200px; //图片回显的高
	display: block;
}
/deep/.el-checkbox__inner {
	width: 25px;
	height: 25px;
	border-radius: 50%;
}
/deep/.el-checkbox__inner::after {
	width: 8px;
	height: 12px;
	left: 6px;
	top: 2px;
}
#container {
	width: 300px;
	height: 300px;
}
/deep/.el-form-item__label {
	font-size: 18px;
	color: rgba(128, 128, 128, 1);
	font-weight: bold;
}
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
}
/deep/ input[type="number"] {
	-moz-appearance: textfield !important;
}
</style>
