<template>
	<div class="p-10">
		<div class="text-xl font-bold">
			<i class="cuIcon-titles" style="color:rgba(42, 130, 228, 1)"></i>渔获管理
		</div>
		<div class="mt-5 flex">
			<div>
				<el-input v-model="input" placeholder="搜索名称" style="width:170px"></el-input>
			</div>
			<div class="search ml-4 mt-1 cursor-pointer" @click="clickSearch()">
				<i class="cuIcon-search"></i>
			</div>
			<div
				class="search ml-4 mt-1 text-white cursor-pointer"
				style="background:rgba(117, 199, 77, 1)"
				@click="reset()"
			>
				<i class="el-icon-refresh"></i>
			</div>
		</div>
		<div class="mt-5 border-2">
			<el-table
				:data="tableData"
				stripe
				:header-cell-style="{ background: '#fafafa' }"
				style="width: 100%"
			>
				<el-table-column prop="content" label="渔获内容" width="300px"></el-table-column>
				<el-table-column label="发布用户">
					<template slot-scope="scope">{{
						scope.row.name ? scope.row.name : scope.row.nickName
					}}</template></el-table-column
				>
				<el-table-column prop="createTime" label="发布时间"></el-table-column>
				<el-table-column prop="favorNum" label="点赞数"></el-table-column>
				<el-table-column prop="commentNum" label="评论数"></el-table-column>
				<el-table-column label="热门状态">
					<template slot-scope="scope">
						<el-switch
							v-model="scope.row.hot"
							active-value="1"
							inactive-value="0"
							active-color="#13ce66"
							inactive-color="#bebebe"
							@change="changStatus0(scope.row)"
						></el-switch>
					</template>
				</el-table-column>
				<el-table-column label="精选状态">
					<template slot-scope="scope">
						<el-switch
							v-model="scope.row.boutique"
							active-value="1"
							inactive-value="0"
							active-color="#13ce66"
							inactive-color="#bebebe"
							@change="changStatus1(scope.row)"
						></el-switch>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="150">
					<template slot-scope="scope">
						<el-button
							style="text-decoration:underline"
							@click="seeDetail(scope.row)"
							type="text"
							>详情</el-button
						>
						<el-button
							style="text-decoration:underline;color:red"
							type="text"
							@click="clickDel(scope.row)"
							>删除</el-button
						>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-dialog title="删除原因" :visible.sync="dialogVisible" width="30%">
			<div style="border-bottom:rgba(229, 229, 229, 1) solid 1px;margin-top: -30px;"></div>
			<div class="flex mt-5">
				<div style="width: 80px;">删除原因</div>
				<el-input
					type="textarea"
					rows="20"
					placeholder="请输入驳回理由"
					v-model="reason"
				></el-input>
			</div>
			<span slot="footer" class="" style="display:block;text-align:center;">
				<el-button style="margin-right: 50px;" @click="dialogVisible = false" type="danger"
					>取 消</el-button
				>
				<el-button style="margin-left: 50px;" type="primary" @click="delUser()"
					>确 定</el-button
				>
			</span>
		</el-dialog>
		<pagination :currentPage="pages" :total="total" @pageChange="changePages"></pagination>
	</div>
</template>

<script>
import pagination from "../../components/Pagination/pagination.vue"
export default {
	components: { pagination },
	data() {
		return {
			input: "",
			tableData: [],
			total: 0, //总页数
			pages: 1, //当前页数
			limit: 5,
			dialogVisible: false,
			reason: "",
			hId: "",
		}
	},

	mounted() {
		this.getHarvest()
	},
	activated() {
		if (!this.input) {
			this.getHarvest()
		} else {
			this.searchHarvest()
		}
	},
	methods: {
		//点击查看渔获详情
		seeDetail(row) {
			this.$router.push({
				name: "harvest",
				query: { harvestId: row.id },
			})
		},
		//分页
		changePages(data) {
			this.pages = data._currentPage
			this.limit = data._pageSize
			if (!this.input) {
				this.getHarvest()
			} else {
				this.searchHarvest()
			}
		},
		//点击查询
		clickSearch() {
			this.pages = 1
			if (this.input == "") {
				this.getHarvest()
			} else {
				this.searchHarvest()
			}
		},
		//点击重置
		reset() {
			this.input = ""
			this.pages = 1
			this.getHarvest()
		},

		//获取用户信息
		async getHarvest() {
			const res = await this.http
				.post(
					"admin/article/getHarvest",
					{
						audit: 1,
						pages: this.pages,
						limit: this.limit,
					},
					false
				)
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.harvest
				this.total = res.data.total
			}
		},
		//搜索用户信息
		async searchHarvest() {
			const res = await this.http
				.post("admin/article/searchHarvest", {
					audit: 1,
					keyword: this.input,
					pages: this.pages,
					limit: this.limit,
				})
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.harvest
				this.total = res.data.total
			}
		},
		//编辑详情
		changStatus0(row) {
			let flag = row.hot
			row.hot == "1" ? (row.hot = "0") : (row.hot = "1")
			this.$confirm("是否改变该状态", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/article/updHarvest", {
							harvestId: row.id,
							hot: flag,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						flag == "1" ? (row.hot = "1") : (row.hot = "0") // 这一步很重要，row.showState会根据flag的值开启或关闭开关
					}
				})
				.catch(() => {
					this.$message({ type: "info", showClose: true, message: "已取消修改！" })
				})
		},
		async changStatus1(row) {
			let flag = row.boutique
			row.boutique == "1" ? (row.boutique = "0") : (row.boutique = "1")
			this.$confirm("是否改变该状态", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/article/updHarvest", {
							harvestId: row.id,
							boutique: flag,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						flag == "1" ? (row.boutique = "1") : (row.boutique = "0")
					}
				})
				.catch(() => {
					this.$message({ type: "info", showClose: true, message: "已取消修改！" })
				})
		},
		//点击删除
		clickDel(row) {
			this.hId = row.id
			this.reason = ""
			this.dialogVisible = true
		},
		//删除用户
		async delUser() {
			if (!this.reason) {
				this.$message("请将数据填写完整")
			} else {
				const res = await this.http
					.post("admin/article/delHarvest", {
						harvestId: this.hId,
						reason: this.reason,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.dialogVisible = false
					this.getHarvest()
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.search {
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	border-radius: 50%;
	border: rgb(175, 174, 174) solid 1px;
}
</style>
