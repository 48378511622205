<template>
	<div class="p-10">
		<div class="flex justify-between">
			<div class="text-xl font-bold">
				<i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>{{ typeof fishId == "undefined" ? "新增鱼类" : "鱼类详情" }}
			</div>
			<div class="text-center">
				<el-button type="primary" class="w-32 text-white" @click="typeof fishId == 'undefined' ? addFish() : updFish()">保存</el-button>
				<el-button type="info" class="w-32 text-white" @click="toBack()">返回</el-button>
			</div>
		</div>
		<div class="flex ml-5 mt-6">
			<div class="mr-7 mt-5">活动图片</div>
			<div class="flex">
				<div style="text-align:right" v-for="(item, index) in imgArr" :key="index">
					<i class="el-icon-circle-close" @click="delImg(index)"></i>
					<img v-if="imgArr" :src="item" class="activity mr-4" />
				</div>
				<div class="ml-3 mt-5">
					<el-upload
						class="activity-uploader flex"
						ref="upload"
						action="#"
						:show-file-list="false"
						:multiple="false"
						:auto-upload="false"
						:on-change="imgSaveToUrl"
					>
						<i class="el-icon-camera-solid activity-uploader-icon"></i>
					</el-upload>
				</div>
			</div>
		</div>

		<div class="flex ml-5 mt-10 font-bold" style="color: rgba(128, 128, 128, 1)">
			<div class="flex items-center mr-20">
				<div class="mr-8 w-12">中文名</div>
				<div>
					<el-input placeholder="请输入内容" v-model="form.cn" style="width: 150px"></el-input>
				</div>
			</div>
			<div class="flex items-center mr-20">
				<div class="mr-8 w-12">英文名</div>
				<div>
					<el-input placeholder="请输入内容" v-model="form.en" style="width: 150px"></el-input>
				</div>
			</div>

			<div class="flex items-center mr-20">
				<div class="mr-8 w-8">科目</div>
				<div>
					<el-input placeholder="请输入内容" v-model="form.subject" style="width: 150px"></el-input>
				</div>
			</div>
			<!-- 拉丁名 -->
			<div class="flex items-center mr-20">
				<div class="mr-6 w-12">拉丁名</div>
				<div>
					<el-input placeholder="请输入内容" v-model="form.latinName" style="width: 150px"></el-input>
				</div>
			</div>
		</div>

		<!-- 科目 -->
		<div class="flex ml-5 mt-10 font-bold" style="color: rgba(128, 128, 128, 1)">
			<!-- 成年体长 -->
			<div class="flex items-center mr-20">
				<div class="mr-6 w-16">成年体长</div>
				<div>
					<el-input placeholder="请输入内容" v-model="form.long" style="width: 150px"></el-input>
				</div>
			</div>
			<!-- 创伤性 -->
			<div class="flex items-center mr-20">
				<div class="mr-6 w-12">创伤性</div>
				<div>
					<el-input placeholder="请输入内容" v-model="form.traumatic" style="width: 150px"></el-input>
				</div>
			</div>
			<div class="flex items-center mr-20">
				<div class="mr-8 w-16">病危等级</div>
				<div>
					<el-input placeholder="请输入内容" v-model="form.endangered" style="width: 150px"></el-input>
				</div>
			</div>
		</div>
		<div class="flex ml-5 mt-10 font-bold" style="color: rgba(128, 128, 128, 1)">
			<div class="flex items-center mr-20">
				<div class="mr-8 w-8">食性</div>
				<div>
					<el-input placeholder="请输入内容" v-model="form.foodHabit" style="width: 270px"></el-input>
				</div>
			</div>
			<div class="flex items-center mr-20">
				<div class="mr-8 w-8">俗名</div>
				<div>
					<el-input placeholder="请输入内容" v-model="form.alias" style="width: 270px"></el-input>
				</div>
			</div>

			<div class="flex items-center mr-20">
				<div class="mr-8 w-8">分布</div>
				<div>
					<el-input placeholder="请输入内容" v-model="form.distribution" style="width: 270px"></el-input>
				</div>
			</div>
		</div>

		<div class="flex ml-5 mt-10 font-bold" style="color: rgba(128, 128, 128, 1)">
			<div class="flex items-center mr-20">
				<div class="mr-8 w-16">栖息生态</div>
				<div>
					<el-input placeholder="请输入内容" v-model="form.habitat" style="width: 300px"></el-input>
				</div>
			</div>

			<div class="flex items-center mr-20">
				<div class="mr-8 w-8">食用</div>
				<div>
					<el-input placeholder="请输入内容" v-model="form.edible" style="width: 150px"></el-input>
				</div>
			</div>
			<div class="flex items-center mr-20">
				<div class="mr-8 w-8">其他</div>
				<div>
					<el-input placeholder="请输入内容" v-model="form.other" style="width: 150px"></el-input>
				</div>
			</div>
		</div>

		<div class="ml-5 mt-10 font-bold" style="color: rgba(128, 128, 128, 1)">
			<div class="flex">
				<div class="mr-8 w-16">形态特征</div>
				<div>
					<el-input
						type="textarea"
						:autosize="{ minRows: 5, maxRows: 5 }"
						placeholder="请输入内容"
						v-model="form.features"
						style="width: 700px"
					></el-input>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			form: {
				class: this.$route.query.class,
				type: this.$route.query.type,
				img: "", //头像
				cn: "", //中文名
				en: "", //英文名
				subject: "", //科名
				latinName: "", //拉丁名
				long: "", //成年体长
				traumatic: "", //创伤性
				endangered: "", //濒危等级
				foodHabit: "", //食性
				alias: "", //俗名
				distribution: "", //分布
				habitat: "", //栖息生态
				edible: "", //食用
				features: "", //形态特征
				other: "", //其他
			},
			imgArr: [],
			fishId: this.$route.query.fishId,
		}
	},

	mounted() {
		this.getFishDetails()
	},
	methods: {
		//新增鱼类
		async addFish() {
			if (this.check.checkHasEmpty(this.form)) {
				//调用函数checkHasEmpty() 检查必填字段是否有为空的
				this.$message("请将数据填写完整")
			} else {
				this.form.img = JSON.stringify(this.form.img)
				const res = await this.http.post("admin/fish/addFish", this.form).catch((err) => {
					console.log(err)
				})
				if (res.code == 1) {
					this.$router.go(-1)
				}
			}
		},
		//编辑鱼类详情
		async updFish() {
			console.log(this.form)
			if (this.check.checkHasEmpty(this.form)) {
				//调用函数checkHasEmpty() 检查必填字段是否有为空的
				this.$message("请将数据填写完整")
			} else {
				this.form.fishId = this.fishId
				this.form.img = JSON.stringify(this.imgArr)
				console.log(this.form)
				const res = await this.http.post("admin/fish/updFish", this.form).catch((err) => {
					console.log(err)
				})
				if (res.code == 1) {
					this.isEdit = false
					this.getFishDetails()
				}
			}
		},
		//删除图片数组
		delImg(index) {
			this.imgArr.splice(index, 1)
			console.log(this.imgArr)
		},
		//获取鱼类信息
		async getFishDetails() {
			if (typeof this.fishId == "undefined") {
				return
			}
			const res = await this.http
				.post(
					"admin/fish/getFishDetails",
					{
						fishId: this.fishId,
					},
					false
				)
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.form = res.data
				this.form.class = this.$route.query.class
				this.form.type = this.$route.query.type
				if (Array.isArray(res.data.img)) {
					this.imgArr = res.data.img
				} else {
					let a = [res.data.img]
					this.imgArr = a
				}
			}
		},
		// 图片上传
		async imgSaveToUrl(event) {
			if (this.beforeUploadPicture(event.raw)) {
				var formData = new FormData()
				formData.append("img", event.raw)
				console.log(formData)
				this.http.upload("admin/upload/uploadImg", formData).then((res) => {
					if (res.code == 1) {
						this.imgArr.push(res.data)
						this.form.img = this.imgArr
						this.$message.success("上传成功")
					}
				})
			}
		},
		// 图片上传之前
		beforeUploadPicture(file) {
			const isJPG = file.type === "image/jpeg"
			const isPNG = file.type === "image/png"
			const isLt10M = file.size / 1024 / 1024 < 10
			if (!isJPG && !isPNG) {
				this.$message.error("上传图片只能是 JPG 或者 PNG 格式!")
			}
			if (isJPG && isPNG) {
				if (!isLt10M) {
					this.$message.error("上传图片大小不能超过 10MB!")
				}
			}
			return (isJPG || isPNG) && isLt10M
		},
		//返回
		toBack() {
			this.$router.go(-1)
		},
	},
}
</script>

<style lang="scss" scoped>
/deep/.activity-uploader .el-upload {
	border: 1px dashed hsl(0, 0%, 85%);
	cursor: pointer;
	position: relative;
	overflow: hidden;
	background: rgba(241, 241, 241, 1);
}
/deep/.activity-uploader-icon {
	font-size: 75px;
	color: #8c939d;
	width: 150px; //上传图片的宽
	height: 150px; //上传图片的高
	line-height: 150px;
	text-align: center;
}
.activity {
	width: 150px; //图片回显的宽
	height: 150px; //图片回显的高
	display: block;
}
</style>
