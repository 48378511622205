<template>
	<div class="p-10">
		<div class="text-xl font-bold"><i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>本地群聊</div>
		<div class="mt-5 flex">
			<div>
				<el-input v-model="input" placeholder="搜索名称" style="width: 170px"></el-input>
			</div>
			<div class="search ml-4 mt-1 cursor-pointer" @click="clickSearch()">
				<i class="cuIcon-search"></i>
			</div>
			<div class="search ml-4 mt-1 text-white cursor-pointer" style="background: rgba(117, 199, 77, 1)" @click="reset()">
				<i class="el-icon-refresh"></i>
			</div>
			<div class="search ml-4 mt-1 bg-blue-500 text-white cursor-pointer" @click="clickAdd()">
				<i class="cuIcon-add"></i>
			</div>
		</div>
		<div class="mt-5 border-2">
			<el-table :data="tableData" stripe :header-cell-style="{ background: '#fafafa' }" style="width: 100%">
				<el-table-column prop="name" label="群聊名称"></el-table-column>
				<el-table-column prop="area" label="地区" width="250px"></el-table-column>
				<el-table-column label="群聊二维码" width="250px">
					<template slot-scope="scope">
						<el-image :src="scope.row.img" class="w-14 h-14" fit="fit"></el-image>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="150">
					<template slot-scope="scope">
						<el-button style="text-decoration: underline" @click="handleClick(scope.row)" type="text">编辑</el-button>
						<el-button style="text-decoration: underline; color: red" type="text" @click="delChat(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<pagination :currentPage="pages" :total="total" @pageChange="changePages"></pagination>

		<el-dialog :title="isAdd ? '编辑本地群聊' : '新增本地群聊'" :visible.sync="dialog" width="400px" :close-on-click-modal="false">
			<div class="flex items-center">
				<div class="font-bold mr-5 w-24" style="color: rgba(128, 128, 128, 1); text-align: right">
					本地群聊名称
				</div>
				<div>
					<el-input v-model="form.name" placeholder="请输入" style="width: 250px"></el-input>
				</div>
			</div>
			<div class="flex mt-6 items-center">
				<div class="font-bold mr-5 w-24" style="color: rgba(128, 128, 128, 1); text-align: right">
					地区
				</div>
				<div class="text-black">
					<el-cascader size="large" :options="options" v-model="selectedOptions" @change="handleChange"> </el-cascader>
				</div>
			</div>
			<div class="mt-6 font-bold flex items-center" style="color: rgba(128, 128, 128, 1)">
				<div style="padding-top: 3px">上传二维码</div>
				<div>
					<i class="el-icon-upload" style="font-size: 30px; padding-left: 10px"></i>
				</div>
			</div>
			<div style="width: 100%; height: 160px; border: black solid 1px" class="mt-3">
				<div class="text-center addUpload">
					<el-upload ref="upload" action="#" :show-file-list="false" :multiple="false" :auto-upload="false" :on-change="imgSaveToUrl">
						<img v-if="form.img" :src="form.img" class="imgSize"/>
						<i v-else class="el-icon-plus"></i
					></el-upload>
				</div>
			</div>
			<div class="mt-5 text-center">
				<el-button size="small" class="mr-10" @click="dialog = false">取消</el-button>
				<el-button type="primary" size="small" @click="isAdd ? updChat() : addChat()">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import pagination from "../../components/Pagination/pagination.vue"
import { provinceAndCityData, CodeToText, TextToCode } from "element-china-area-data"
export default {
	components: { pagination },
	data() {
		return {
			input: "",
			tableData: [],
			isAdd: false,
			dialog: false,
			form: {
				name: "",
				img: "",
				area: "",
			},
			total: 0, //总页数
			pages: 1, //当前页数
			limit: 5,
			options: provinceAndCityData,
			selectedOptions: [],
		}
	},

	mounted() {
		this.getChat()
	},
	activated() {
		if (!this.value) {
			this.getChat()
		} else {
			this.searchChat()
		}
	},
	methods: {
		//选择地区
		handleChange() {
			let arr = []
			arr[0] = CodeToText[this.selectedOptions[0]]
			arr[1] = CodeToText[this.selectedOptions[1]]
			let str = CodeToText[this.selectedOptions[0]] + "," + CodeToText[this.selectedOptions[1]]
			this.form.area = str
		},
		//分页
		changePages(data) {
			this.pages = data._currentPage
			this.limit = data._pageSize
			if (!this.value) {
				this.getChat()
			} else {
				this.searchChat()
			}
		},
		//点击查询
		clickSearch() {
			this.pages = 1
			console.log(!this.input)
			if (this.input == "") {
				this.getChat()
			} else {
				this.searchChat()
			}
		},
		//点击重置
		reset() {
			this.input = ""
			this.pages = 1
			this.getChat()
		},
		seeDetail(row) {
			this.$router.push({ path: "userDetail" })
		},
		//获取本地群聊
		async getChat() {
			const res = await this.http
				.post(
					"admin/chat/getChat",
					{
						pages: this.pages,
						limit: this.limit,
					},
					false
				)
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.chat
				this.total = res.data.total
			}
		},
		//搜索本地群聊
		async searchChat() {
			const res = await this.http
				.post("admin/chat/searchChat", {
					name: this.input,
					pages: this.pages,
					limit: this.limit,
				})
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.chat
				this.total = res.data.total
			}
		},
		//点击新增
		clickAdd() {
			this.dialog = true
			this.isAdd = false
			this.form = { img: "" }
		},
		//点击编辑
		handleClick(row) {
			this.dialog = true
			this.isAdd = true
			let arr = row.area.split(",")
			console.log(arr)
			this.selectedOptions = []
			this.selectedOptions.push(TextToCode[arr[0]].code, TextToCode[arr[0]][arr[1]].code)
			this.form = JSON.parse(JSON.stringify(row))
		},
		//添加本地群聊
		async addChat() {
			if (this.check.checkHasEmpty(this.form)) {
				//调用函数checkHasEmpty() 检查必填字段是否有为空的
				this.$message("请将数据填写完整")
			} else {
				const res = await this.http
					.post("admin/chat/addChat", {
						img: this.form.img,
						area: this.form.area,
						name: this.form.name,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.dialog = false
					this.getChat()
				}
			}
		},
		//编辑本地群聊
		async updChat() {
			if (this.check.checkHasEmpty(this.form)) {
				//调用函数checkHasEmpty() 检查必填字段是否有为空的
				this.$message("请将数据填写完整")
			} else {
				const res = await this.http
					.post("admin/chat/updChat", {
						chatId: this.form.id,
						img: this.form.img,
						area: this.form.area,
						name: this.form.name,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.dialog = false
					this.getChat()
				}
			}
		},
		//删除本地群聊
		async delChat(row) {
			this.$confirm("此操作将永久删除该本地群聊, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/chat/delChat", {
							chatId: row.id,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						this.getChat()
					}
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					})
				})
		},
		// 图片上传
		async imgSaveToUrl(event) {
			if (this.beforeUploadPicture(event.raw)) {
				var formData = new FormData()
				formData.append("img", event.raw)
				console.log(formData)
				this.http.upload("admin/upload/uploadImg", formData).then((res) => {
					if (res.code == 1) {
						this.form.img = res.data
						this.$message.success("上传成功")
						console.log(this.form.avatarUrl)
					}
				})
			}
		},
		// 图片上传之前
		beforeUploadPicture(file) {
			const isJPG = file.type === "image/jpeg"
			const isPNG = file.type === "image/png"
			const isLt10M = file.size / 1024 / 1024 < 10
			if (!isJPG && !isPNG) {
				this.$message.error("上传图片只能是 JPG 或者 PNG 格式!")
			}
			if (isJPG && isPNG) {
				if (!isLt10M) {
					this.$message.error("上传图片大小不能超过 10MB!")
				}
			}
			return (isJPG || isPNG) && isLt10M
		},
	},
}
</script>

<style lang="scss" scoped>
.search {
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	border-radius: 50%;
	border: rgb(175, 174, 174) solid 1px;
}
.addUpload {
	height: 160px;
	width: 150px;
	line-height: 160px;
	font-size: 50px;
	margin: auto;
}
.imgSize {
	width: 160px;
	height: 157px;
}
</style>
