<template>
	<div class="p-10">
		<div class="text-xl font-bold"><i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>帮助中心</div>
		<div class="mt-5 flex">
			<div>
				<el-input v-model="input" placeholder="搜索名称" style="width: 170px"></el-input>
			</div>
			<div class="search ml-4 mt-1 cursor-pointer" @click="clickSearch()">
				<i class="cuIcon-search"></i>
			</div>
			<div class="search ml-4 mt-1 text-white cursor-pointer" style="background: rgba(117, 199, 77, 1)" @click="reset()">
				<i class="el-icon-refresh"></i>
			</div>
			<div class="search ml-4 mt-1 bg-blue-500 text-white cursor-pointer" @click="toAddCourse">
				<i class="cuIcon-add"></i>
			</div>
		</div>
		<div class="mt-5 border-2">
			<el-table :data="tableData" stripe :header-cell-style="{ background: '#fafafa' }" style="width: 100%">
				<el-table-column prop="title" label="文章标题" width="300"></el-table-column>
				<el-table-column label="内容简介">
					<template slot-scope="scope">{{ scope.row.content | getText }}</template>
				</el-table-column>
				<el-table-column prop="createTime" label="时间" width="300"></el-table-column>
				<el-table-column label="操作" width="150">
					<template slot-scope="scope">
						<el-button style="text-decoration: underline" @click="toCourseDetail(scope.row)" type="text">编辑</el-button>
						<el-button style="text-decoration: underline; color: red" type="text" @click="delHelp(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<pagination :currentPage="pages" :total="total" @pageChange="changePages"></pagination>
	</div>
</template>

<script>
import pagination from "../../components/Pagination/pagination.vue"
export default {
	components: { pagination },
	data() {
		return {
			input: "",
			tableData: [],
			total: 0, //总页数
			pages: 1, //当前页数
			limit: 5,
		}
	},
	filters: {
		getText(val) {
			var re1 = new RegExp("<.+?>", "g") //匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
			var msg = val.replace(re1, "") //执行替换成空字符
			return msg
		},
	},

	mounted() {
		this.getHelp()
	},
	activated() {
		if (!this.input) {
			this.getHelp()
		} else {
			this.searchHelp()
		}
	},
	methods: {
		handleClick(row) {
			console.log(row)
		},
		//分页
		changePages(data) {
			this.pages = data._currentPage
			this.limit = data._pageSize
			if (!this.input) {
				this.getHelp()
			} else {
				this.searchHelp()
			}
		},
		//点击查询
		clickSearch() {
			this.pages = 1
			console.log(!this.input)
			if (this.input == "") {
				this.getHelp()
			} else {
				this.searchHelp()
			}
		},
		//点击重置
		reset() {
			this.input = ""
			this.pages = 1
			this.getHelp()
		},
		//获取帮助列表
		async getHelp() {
			const res = await this.http
				.post(
					"admin/help/getHelp",
					{
						pages: this.pages,
						limit: this.limit,
					},
					false
				)
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.help
				this.total = res.data.total
			}
		},
		//搜索帮助中心
		async searchHelp() {
			const res = await this.http
				.post("admin/help/searchHelp", {
					title: this.input,
					pages: this.pages,
					limit: this.limit,
				})
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.help
				this.total = res.data.total
			}
		},
		//跳转教程详情
		toCourseDetail(row) {
			this.$router.push({
				path: "helpCenterDetail",
				query: { id: row.id },
			})
		},
		//新建帮助中心
		toAddCourse() {
			this.$router.push({
				path: "helpCenterDetail",
			})
		},
		//删除帮助中心
		delHelp(row) {
			this.$confirm("此操作将永久删除该文章, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/help/delHelp", {
							helpId: row.id,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						this.getHelp()
					}
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					})
				})
		},
	},
}
</script>

<style lang="scss" scoped>
.search {
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	border-radius: 50%;
	border: rgb(175, 174, 174) solid 1px;
}
/deep/.el-table .cell {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>
