<!-- 兑换记录 -->
<template>
	<div class="p-10">
		<div class="text-xl font-bold">
			<i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>兑换记录
		</div>
		<div class="mt-5 flex">
			<div>
				<el-input
					v-model.number="input"
					placeholder="请搜索订单id"
					style="width: 170px"
					@keydown.native="inputLimit"
				></el-input>
			</div>
			<div class="search ml-4 mt-1 cursor-pointer" @click="clickSearch()">
				<i class="cuIcon-search"></i>
			</div>
			<div
				class="search ml-4 mt-1 text-white cursor-pointer"
				style="background: rgba(117, 199, 77, 1)"
				@click="reset()"
			>
				<i class="el-icon-refresh"></i>
			</div>
		</div>
		<div class="mt-5 border-2">
			<el-table
				:data="tableData"
				stripe
				:header-cell-style="{ background: '#fafafa' }"
				style="width: 100%"
			>
				<el-table-column prop="id" label="订单编号"></el-table-column>
				<el-table-column label="用户"
					><template slot-scope="scope">{{
						scope.row.name ? scope.row.name : scope.row.nickName
					}}</template></el-table-column
				>
				<el-table-column prop="goodName" label="兑换礼品" width="100px"></el-table-column>
				<el-table-column prop="num" label="数量" width="60px"></el-table-column>
				<el-table-column prop="coin" label="消耗积分" width="100px"></el-table-column>
				<el-table-column prop="address" label="收货地址" width="250px"></el-table-column>
				<el-table-column prop="createTime" label="兑换时间"></el-table-column>
				<el-table-column prop="status" label="状态">
					<template slot-scope="scope">{{
						scope.row.status == "1"
							? "已发货"
							: scope.row.status == "0"
							? "未发货"
							: "已收货"
					}}</template>
				</el-table-column>
				<el-table-column prop="logistics" label="物流单号"></el-table-column>
				<el-table-column label=" 操作" width="150">
					<template slot-scope="scope">
						<el-button
							style="text-decoration: underline"
							@click="handleClick(scope.row)"
							type="text"
							>详情</el-button
						>
						<el-button
							v-if="scope.row.status == '0'"
							style="text-decoration: underline; color: rgba(255, 141, 26, 1)"
							type="text"
							@click="delivery(scope.row)"
							>发货</el-button
						>
						<el-button
							v-if="scope.row.status == '1'"
							style="text-decoration: underline; color: rgba(24, 144, 255, 1)"
							type="text"
							@click="editor(scope.row)"
							>编辑</el-button
						>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 分页器 -->
		<pagination :currentPage="pages" :total="total" @pageChange="changePages"></pagination>

		<!-- 订单详情 -->
		<el-dialog
			v-if="(this.isAdd1 = true)"
			title="兑换记录详情"
			:visible.sync="dialog1"
			width="631px"
			hight="403px"
			@close="closeDialog1"
			:close-on-click-modal="false"
		>
			<el-divider></el-divider>
			<div>
				<el-row>
					<el-col :span="12" class="a"
						>订单编号<span class="b">{{ form.id }}</span></el-col
					>

					<el-col :span="12" class="a">
						<div class="flex">
							用户
							<img
								v-if="form.avatarUrl"
								:src="form.avatarUrl"
								style="
                  width: 35px;
                  height: 35px;
                  border-radius: 18px;
                  margin: 0px 10px;
                "
							/><span class="b">{{ form.name ? form.name : form.nickName }}</span>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24" class="a"
						>兑换礼品 <span class="b">{{ form.goodName }}</span></el-col
					>
				</el-row>
				<el-row>
					<el-col :span="12" class="a"
						>兑换数量<span class="b">{{ form.num }}</span></el-col
					>
					<el-col :span="12" class="a"
						>消耗积分<span class="b">{{ form.coin }}</span></el-col
					>
				</el-row>
				<el-row>
					<el-col :span="24" class="a"
						>收货地址<span class="b">{{ form.area + "," + form.address }}</span></el-col
					>
				</el-row>
				<el-row>
					<el-col :span="24" class="a"
						>收货人<span class="b">{{ form.consignee }}</span></el-col
					>
				</el-row>
				<el-row>
					<el-col :span="24" class="a"
						>电话<span class="b">{{ form.tel }}</span></el-col
					>
				</el-row>
				<el-row>
					<el-col :span="12" class="a"
						>状态<span class="b">{{
							form.status == "1" ? "已发货" : form.status == "0" ? "未发货" : "已收货"
						}}</span></el-col
					>
					<el-col :span="12" class="a"
						>物流单号<span class="b">{{ form.logistics }}</span></el-col
					>
				</el-row>
			</div>
		</el-dialog>
		<!-- 订单发货 -->
		<el-dialog
			v-if="(this.isAdd2 = true)"
			title="订单发货"
			:visible.sync="dialog2"
			width="631px"
			hight="502px"
			:close-on-click-modal="false"
			@close="closeDialog2"
		>
			<el-divider></el-divider>
			<div>
				<el-row>
					<el-col :span="12" class="a"
						>订单编号<span class="b">{{ form.id }}</span></el-col
					>

					<el-col :span="12" class="a">
						<div class="flex">
							用户
							<img
								v-if="form.avatarUrl"
								:src="form.avatarUrl"
								style="
                  width: 35px;
                  height: 35px;
                  border-radius: 18px;
                  margin: 0px 10px;
                "
							/><span class="b">{{ form.name ? form.name : form.nickName }}</span>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24" class="a"
						>兑换礼品 <span class="b">{{ form.goodName }}</span></el-col
					>
				</el-row>
				<el-row>
					<el-col :span="12" class="a"
						>兑换数量<span class="b">{{ form.num }}</span></el-col
					>
					<el-col :span="12" class="a"
						>消耗积分<span class="b">{{ form.coin }}</span></el-col
					>
				</el-row>
				<el-row>
					<el-col :span="24" class="a"
						>收货地址<span class="b">{{ form.area + "," + form.address }}</span></el-col
					>
				</el-row>
				<el-row>
					<el-col :span="24" class="a"
						>收货人<span class="b">{{ form.consignee }}</span></el-col
					>
				</el-row>
				<el-row>
					<el-col :span="24" class="a"
						>电话<span class="b">{{ form.tel }}</span></el-col
					>
				</el-row>
				<el-divider></el-divider>
				<el-row>
					<el-col :span="24" style="font-size: 14px">
						<div class="flex items-center">
							为该订单发货，请填写物流单号：
							<el-input
								style="width: 318px; height: 38px"
								v-model="input2"
							></el-input></div
					></el-col>
				</el-row>
			</div>
			<el-divider></el-divider>
			<div class="mt-5 text-right">
				<el-button size=" medium" class="mr-5" @click="dialog2 = false">取消</el-button>
				<el-button type="primary" size="medium" @click="updOrder">确定</el-button>
			</div>
		</el-dialog>
		<!-- 订单编辑 -->
		<el-dialog
			v-if="(this.isAdd3 = true)"
			title="兑换记录详情"
			:visible.sync="dialog3"
			width="631px"
			hight="403px"
			:close-on-click-modal="false"
			@close="closeDialog3"
		>
			<el-divider></el-divider>
			<div>
				<el-row>
					<el-col :span="12" class="a"
						>订单编号<span class="b">{{ form.id }}</span></el-col
					>

					<el-col :span="12" class="a">
						<div class="flex items-center">
							用户
							<img
								v-if="form.avatarUrl"
								:src="form.avatarUrl"
								style="
                  width: 35px;
                  height: 35px;
                  border-radius: 18px;
                  margin: 0px 10px;
                "
							/><span class="b">{{ form.name ? form.name : form.nickName }}</span>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24" class="a"
						>兑换礼品 <span class="b">{{ form.goodName }}</span></el-col
					>
				</el-row>
				<el-row>
					<el-col :span="12" class="a"
						>兑换数量<span class="b">{{ form.num }}</span></el-col
					>
					<el-col :span="12" class="a"
						>消耗积分<span class="b">{{ form.coin }}</span></el-col
					>
				</el-row>
				<el-row>
					<el-col :span="24" class="a"
						>收货地址<span class="b">{{ form.area + "," + form.address }}</span></el-col
					>
				</el-row>
				<el-row>
					<el-col :span="24" class="a"
						>收货人<span class="b">{{ form.consignee }}</span></el-col
					>
				</el-row>
				<el-row>
					<el-col :span="24" class="a"
						>电话<span class="b">{{ form.tel }}</span></el-col
					>
				</el-row>
				<el-row>
					<el-col :span="12" class="a"
						>状态<span class="b">{{
							form.status == "1" ? "已发货" : form.status == "0" ? "未发货" : "已收货"
						}}</span></el-col
					>
					<el-col :span="12" class="a"
						>物流单号
						<el-input
							style="width: 200px; height: 38px"
							v-model="form.logistics"
							placeholder="请输入"
							@change="change2(form.logistics)"
						></el-input
					></el-col>
				</el-row>
				<el-divider></el-divider>
				<div class="mt-5 text-right">
					<el-button size=" medium" class="mr-5" @click="dialog3 = false">取消</el-button>
					<el-button type="primary" size="medium" @click="updOrder2">确定</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import pagination from "../../components/Pagination/pagination.vue"

export default {
	components: { pagination },
	data() {
		return {
			input: "",
			tableData: [],
			dialog1: false,
			dialog2: false,
			dialog3: false,
			form: {
				id: "",
				avatarUrl: "",
				name: "",
				nickName: "",
				goodName: "",
				num: "",
				coin: "",
				address: "",
				status: "",
				logistics: "",
			},
			isAdd1: false,
			isAdd2: false,
			isAdd3: false,
			total: 0, //总页数
			pages: 1, //当前页数
			limit: 5,
			input2: "",

			id: "",
		}
	},

	mounted() {
		this.getOrder()
	},
	methods: {
		inputLimit(e) {
			let num = e.target.value || ""
			let code = e.which || e.keyCode
			let str = e.key && e.key != "Unidentified" ? e.key : num.substr(num.length - 1)
			console.log("|type:" + e.type + "|code:" + code + "|str:" + str + "|value:" + num)
			//无论任何情况，皆可执行
			if (code == "8") {
				return true
			}
			//没有满足任何一种情况，中断执行
			if (!(/[\d.]/.test(str) || code == "190")) {
				e.returnValue = false
				return false
			}
			if (
				num.length > 12 ||
				(num.indexOf(".") >= 0 && code == "190") ||
				(num.indexOf(".") == num.length - 3 && num.length > 3) ||
				(num.length == 0 && code == "190")
			) {
				e.returnValue = false
				return false
			}
			return true
		},

		closeDialog1() {
			this.form = ""
		},
		closeDialog2() {
			this.form = ""
		},
		closeDialog3() {
			this.form = ""
		},
		changePages(data) {
			console.log(data)
			this.pages = data._currentPage
			this.limit = data._pageSize
			if (!this.input) {
				this.getOrder()
			}
		},
		//获取订单列表
		async getOrder() {
			const res = await this.http.post(
				"admin/Order/getOrder",
				{
					pages: this.pages,
					limit: this.limit,
				},
				false
			)
			if (res.code === 1) {
				console.log(res)
				this.tableData = res.data.order
				this.total = res.data.total
			}
		},
		reset() {
			this.input = ""
			this.pages = 1
			this.getOrder()
		},
		//点击兑换记录详情
		async handleClick(row) {
			this.dialog1 = true
			this.isAdd1 = false
			console.log(row)
			const res = await this.http.post(
				"admin/Order/getOrderDetails",
				{
					orderId: row.id,
				},
				false
			)
			if (res.code === 1) {
				console.log(res)
			}
			this.form = JSON.parse(JSON.stringify(res.data))
		},
		//编辑详情发货
		async delivery(row) {
			this.dialog2 = true
			this.isAdd2 = false
			const res = await this.http.post(
				"admin/Order/getOrderDetails",
				{
					orderId: row.id,
				},
				false
			)
			if (res.code === 1) {
				console.log(res)
				this.form = res.data
				this.id = row.id
			}
		},
		//点击编辑按钮
		async editor(row) {
			this.dialog3 = true
			this.isAdd3 = false
			const res = await this.http.post(
				"admin/Order/getOrderDetails",
				{
					orderId: row.id,
				},
				false
			)
			if (res.code === 1) {
				console.log(res)
				this.form = res.data
				this.id = row.id
			}
		},
		checkVal(val) {
			let zhenz = /^[A-Za-z0-9]+$/
			if (zhenz.test(val)) {
				return true
			}
			return false
		},
		//点击发货按钮后的确认按钮
		async updOrder() {
			let a = {
				input: this.input2,
			}
			if (this.check.checkHasEmpty(a) || !this.checkVal(this.input2)) {
				//调用函数checkHasEmpty() 检查必填字段是否有为空的
				this.$message("请输入正确的物流单号或者填写完整！")
			} else {
				const res = await this.http.post(
					"admin/Order/updOrder",
					{
						orderId: this.id,
						logistics: this.input2,
					},
					false
				)
				if (res.code === 1) {
					console.log(res)
					this.$message.success("填写成功")
					this.input2 = ""
					this.getOrder()
				}
				this.dialog2 = false
			}
		},
		//点击编辑按钮后输入单号回车
		async change2(logistics) {
			this.input2 = logistics
		},
		//点击编辑按钮后点击确认按钮
		async updOrder2() {
			var a = {
				input: this.input2,
			}
			if (this.check.checkHasEmpty(a) || !this.checkVal(this.input2)) {
				//调用函数checkHasEmpty() 检查必填字段是否有为空的
				this.$message("请输入正确的物流单号或者填写完整！")
			} else {
				const res = await this.http.post(
					"admin/Order/updOrder",
					{
						orderId: this.id,
						logistics: this.input2,
					},
					false
				)
				if (res.code === 1) {
					console.log(res)
					this.$message.success("填写成功")
					this.input2 = ""
					if (this.input) {
						this.searchOrder()
					} else {
						this.getOrder()
					}
				}
				this.dialog3 = false
			}
		},
		//点击查询
		clickSearch() {
			this.pages = 1
			if (this.input == "") {
				this.getOrder()
			} else {
				this.searchOrder()
			}
		},
		searchOrder() {},
		async searchOrder() {
			const res = await this.http
				.post(
					"admin/Order/searchOrder",
					{
						orderId: this.input,
						pages: this.pages,
						limit: this.limit,
					},
					false
				)
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.order
				console.log(this.tableData)
				this.total = res.data.total
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.search {
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	border-radius: 50%;
	border: rgb(175, 174, 174) solid 1px;
}
/deep/ .avatar-uploader .el-upload {
	border: 1px dashed hsl(0, 0%, 85%);
	cursor: pointer;
	position: relative;
	overflow: hidden;
	background: rgb(204, 204, 204);
	border-radius: 50%;
}
/deep/.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 66px; //上传图片的宽
	height: 66px; //上传图片的高
	line-height: 66px;
	text-align: center;
}
/deep/ .avatar {
	width: 66px; //图片回显的宽
	height: 66px; //图片回显的高
	display: block;
	border-radius: 50%;
}
.a {
	color: rgba(80, 80, 80, 1);
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 25px;
}
.b {
	color: rgba(0, 0, 0, 1);
	font-size: 20px;
	margin-left: 10px;
	font-weight: lighter;
}
</style>
