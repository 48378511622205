<template>
	<div></div>
</template>

<script>
export default {
	data() {
		return {}
	},
}
</script>

<style></style>
