<template>
	<div class="p-10">
		<div class="text-xl font-bold"><i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>鱼类百科</div>
		<div class="mt-5 flex">
			<div>
				<el-input v-model="input" placeholder="搜索名称" style="width: 170px"></el-input>
			</div>
			<div class="search ml-4 mt-1 cursor-pointer">
				<i class="cuIcon-search"></i>
			</div>
			<div class="search ml-4 mt-1 text-white cursor-pointer" style="background: rgba(117, 199, 77, 1)">
				<i class="el-icon-refresh"></i>
			</div>
			<div class="search ml-4 mt-1 bg-blue-500 text-white cursor-pointer" @click="toFishDetail()">
				<i class="cuIcon-add"></i>
			</div>
		</div>
		<div class="mt-5 border-2">
			<el-table :data="tableData" stripe :header-cell-style="{ background: '#fafafa' }" style="width: 100%">
				<el-table-column label="图片">
					<template slot-scope="scope">
						<img :src="scope.row.img" alt class="h-15 w-20" />
					</template>
				</el-table-column>
				<el-table-column prop="cn" label="名称"></el-table-column>
				<el-table-column prop="latinName" label="拉丁名"></el-table-column>
				<el-table-column prop="order" label="鱼类型"></el-table-column>
				<el-table-column label="操作" width="150">
					<template slot-scope="scope">
						<el-button style="text-decoration: underline" @click="toUpdFishDetail(scope.row)" type="text">详情</el-button>
						<el-button style="text-decoration: underline; color: red" type="text">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<pagination :currentPage="pages" :total="total" @pageChange="changePages"></pagination>
	</div>
</template>

<script>
import pagination from "../../../components/Pagination/pagination.vue"
export default {
	components: { pagination },
	data() {
		return {
			class: this.$route.query.class,
			input: "",
			tableData: [],
			dialog: false,
			inputValue: "",
			type: true,
			total: 0, //总页数
			pages: 1, //当前页数
		}
	},

	mounted() {
		this.getClassDetails()
	},
	methods: {
		//分页
		changePages(data) {
			this.pages = data._currentPage
			if (!this.value) {
				this.getClassDetails()
			} else {
				this.search()
			}
		},
		//获取鱼分类详情
		async getClassDetails() {
			const res = await this.http
				.post("admin/fish/getClassDetails", {
					class: this.class,
					pages: this.pages,
					limit: 5,
				})
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.fish
				this.total = res.data.total
			}
		},
		//跳转新增鱼类
		toFishDetail() {
			this.$router.push({
				path: "fishDetail",
				query: { class: this.class },
			})
		},

		//跳转鱼类编辑
		toUpdFishDetail(row) {
			this.$router.push({
				path: "fishDetail",
				query: { class: this.class, fishId: row.id },
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.search {
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	border-radius: 50%;
	border: rgb(175, 174, 174) solid 1px;
}
</style>
