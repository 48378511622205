<template>
	<div class="p-10">
		<div class="text-xl font-bold"><i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>{{ this.class }}类型</div>
		<div class="mt-5 flex">
			<div>
				<el-input v-model="input" placeholder="搜索名称" style="width: 170px"></el-input>
			</div>
			<div class="search ml-4 mt-1 cursor-pointer">
				<i class="cuIcon-search"></i>
			</div>
			<div class="search ml-4 mt-1 text-white cursor-pointer" style="background: rgba(117, 199, 77, 1)">
				<i class="el-icon-refresh"></i>
			</div>
			<div class="search ml-4 mt-1 bg-blue-500 text-white cursor-pointer" @click="addToolClassDetail()">
				<i class="cuIcon-add"></i>
			</div>
		</div>
		<div class="mt-5 border-2">
			<el-table :data="tableData" stripe :header-cell-style="{ background: '#fafafa' }" style="width: 100%">
				<el-table-column label="图片">
					<template slot-scope="scope">
						<el-image :src="scope.row.img" class="w-16 h-16" fit="fit"></el-image>
					</template>
				</el-table-column>
				<el-table-column prop="cn" label="名称"></el-table-column>
				<el-table-column prop="en" label="外文名"></el-table-column>
				<el-table-column prop="createTime" label="创建时间"></el-table-column>
				<el-table-column label="操作" width="150">
					<template slot-scope="scope">
						<el-button style="text-decoration: underline" @click="handleClick(scope.row)" type="text">编辑</el-button>
						<el-button style="text-decoration: underline; color: red" type="text">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<pagination :currentPage="pages" :total="total" @pageChange="changePages"></pagination>
	</div>
</template>

<script>
import pagination from "../../../components/Pagination/pagination.vue"
export default {
	components: { pagination },
	data() {
		return {
			class: this.$route.query.class,
			input: "",
			tableData: [],
			dialog: false,
			form: { img: 123, status: "", class: "" },
			total: 0, //总页数
			pages: 1, //当前页数
		}
	},

	mounted() {
		this.getClassDetails()
	},
	methods: {
		//分页
		changePages(data) {
			this.pages = data._currentPage
			if (!this.value) {
				this.getClassDetails()
			} else {
				this.search()
			}
		},
		//获取工具分类
		async getClassDetails() {
			const res = await this.http
				.post("admin/tool/getClassDetails", {
					class: this.class,
					pages: this.pages,
					limit: 5,
				})
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.tool
				this.total = res.data.total
			}
		},
		//新建工具
		async addToolClassDetail() {
			this.$router.push({
				path: "toolDetail",
				query: {
					class: this.class,
				},
			})
		},
		//点击编辑
		handleClick(row) {
			this.$router.push({
				path: "toolDetail",
				query: { class: this.class, toolId: row.id },
			})
		},
		//开启&关闭状态
		async updToolClass(row) {
			const res = await this.http
				.post("admin/tool/updClass", {
					classId: row.id,
					status: row.status ? 1 : 0,
				})
				.catch((err) => {
					console.log(err)
				})
		},
	},
}
</script>

<style lang="scss" scoped>
.search {
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	border: rgb(175, 174, 174) solid 1px;
	border-radius: 50%;
}
/deep/ .avatar-uploader .el-upload {
	border: 1px dashed hsl(0, 0%, 85%);
	cursor: pointer;
	position: relative;
	overflow: hidden;
	background: rgb(204, 204, 204);
}
/deep/.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 66px; //上传图片的宽
	height: 66px; //上传图片的高
	line-height: 66px;
	text-align: center;
}
/deep/ .avatar {
	width: 66px; //图片回显的宽
	height: 66px; //图片回显的高
	display: block;
}
</style>
