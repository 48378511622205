<template>
	<div class="p-10">
		<div class="text-xl font-bold"><i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>管理员管理</div>
		<div class="mt-5 flex">
			<div>
				<el-input v-model="input" placeholder="搜索名称" style="width: 170px"></el-input>
			</div>
			<div class="search ml-4 mt-1 cursor-pointer" @click="clickSearch()">
				<i class="cuIcon-search"></i>
			</div>
			<div class="search ml-4 mt-1 text-white cursor-pointer" style="background: rgba(117, 199, 77, 1)" @click="reset()">
				<i class="el-icon-refresh"></i>
			</div>
			<div class="search ml-4 mt-1 bg-blue-500 text-white cursor-pointer" @click="clickAdd()">
				<i class="cuIcon-add"></i>
			</div>
		</div>
		<div class="mt-5 border-2">
			<el-table :data="tableData" stripe :header-cell-style="{ background: '#fafafa' }" style="width: 100%">
				<el-table-column label="头像">
					<template slot-scope="scope">
						<el-image :src="scope.row.avatarUrl" class="w-12 h-12" style="border-radius: 50%" fit="fit"></el-image>
					</template>
				</el-table-column>
				<el-table-column prop="name" label="名称"></el-table-column>
				<el-table-column prop="createTime" label="创建时间"></el-table-column>
				<el-table-column label="操作" width="150">
					<template slot-scope="scope">
						<el-button style="text-decoration: underline" @click="handleClick(scope.row)" type="text">编辑</el-button>
						<el-button style="text-decoration: underline; color: red" type="text" @click="delManager(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 分页器 -->
		<pagination :currentPage="pages" :total="total" @pageChange="changePages"></pagination>

		<!-- 对话框 -->
		<el-dialog :title="isAdd == true ? '新增管理员' : '编辑管理员'" :visible.sync="dialog" width="350px" :close-on-click-modal="false">
			<el-form>
				<el-form-item label="头像" label-width="50px">
					<el-upload
						class="avatar-uploader"
						ref="upload"
						action="#"
						:show-file-list="false"
						:multiple="false"
						:auto-upload="false"
						:on-change="imgSaveToUrl"
					>
						<img v-if="form.avatarUrl" :src="form.avatarUrl" class="avatar" />
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="名称" label-width="50px">
					<el-input v-model="form.name" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="密码" label-width="50px">
					<el-input v-model="form.password" autocomplete="off" type="password"></el-input>
				</el-form-item>
			</el-form>
			<div class="mt-5 text-center">
				<el-button size="small" class="mr-10" @click="dialog = false">取消</el-button>
				<el-button type="primary" size="small" @click="isAdd == true ? addAdmin() : updManager()">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import pagination from "../../components/Pagination/pagination.vue"
export default {
	components: { pagination },
	data() {
		return {
			input: "",
			tableData: [],
			dialog: false,
			form: {
				avatarUrl: "",
				name: "",
				password: "",
			},
			isAdd: false,
			total: 0, //总页数
			pages: 1, //当前页数
			limit: 5,
		}
	},

	mounted() {
		this.getManager()
	},
	methods: {
		//分页
		changePages(data) {
			this.pages = data._currentPage
			this.limit = data._pageSize
			if (!this.input) {
				this.getManager()
			} else {
				this.searchManager()
			}
		},
		//点击查询
		clickSearch() {
			this.pages = 1
			if (this.input == "") {
				this.getManager()
			} else {
				this.searchManager()
			}
		},
		//点击重置
		reset() {
			this.input = ""
			this.pages = 1
			this.getManager()
		},
		//获取管理员
		async getManager() {
			const res = await this.http
				.post(
					"admin/user/getManager",
					{
						managerId: this.$store.state.user.id,
						pages: this.pages,
						limit: this.limit,
					},
					false
				)
				.catch((err) => {
					console.log(err)
				})
			this.tableData = res.data.manager
			this.total = res.data.total
		},
		//点击新增
		clickAdd() {
			this.dialog = true
			this.isAdd = true
			this.form = { avatarUrl: "" }
		},
		//新增管理员
		async addAdmin() {
			var a = {
				avatarUrl: this.form.avatarUrl,
				name: this.form.name,
				password: this.form.password,
			}
			if (this.check.checkHasEmpty(a)) {
				//调用函数checkHasEmpty() 检查必填字段是否有为空的
				this.$message("请将数据填写完整")
			} else {
				const res = await this.http
					.post(
						"admin/user/addManager",
						{
							managerId: this.$store.state.user.id,
							avatarUrl: this.form.avatarUrl,
							name: this.form.name,
							password: this.form.password,
						},
						false
					)
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.$message({
						type: "success",
						message: "创建成功！",
					})
					this.dialog = false
					this.getManager(false)
				} else if (res.code == -1) {
					this.$message({
						type: "error",
						message: res.data,
					})
				}
			}
		},
		//搜索管理员
		async searchManager() {
			const res = await this.http
				.post("admin/user/searchManager", {
					name: this.input,
					pages: this.pages,
					limit: this.limit,
				})
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.manager
				this.total = res.data.total
			}
		},
		//删除管理员
		delManager(row) {
			this.$confirm("此操作将永久删除该管理员, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/user/delManager", {
							managerId: this.$store.state.user.id,
							delManagerId: row.id,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						this.getManager(false)
					}
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					})
				})
		},

		// 图片上传
		async imgSaveToUrl(event) {
			if (this.beforeUploadPicture(event.raw)) {
				var formData = new FormData()
				formData.append("img", event.raw)
				console.log(formData)
				this.http.upload("admin/upload/uploadImg", formData).then((res) => {
					if (res.code == 1) {
						this.form.avatarUrl = res.data
						this.$message.success("上传成功")
						console.log(this.form.avatarUrl)
					}
				})
			}
		},
		// 图片上传之前
		beforeUploadPicture(file) {
			const isJPG = file.type === "image/jpeg"
			const isPNG = file.type === "image/png"
			const isLt10M = file.size / 1024 / 1024 < 10
			if (!isJPG && !isPNG) {
				this.$message.error("上传图片只能是 JPG 或者 PNG 格式!")
			}
			if (isJPG && isPNG) {
				if (!isLt10M) {
					this.$message.error("上传图片大小不能超过 10MB!")
				}
			}
			return (isJPG || isPNG) && isLt10M
		},
		//点击编辑
		handleClick(row) {
			this.dialog = true
			this.isAdd = false
			this.form = JSON.parse(JSON.stringify(row))
		},
		//编辑管理员
		async updManager() {
			var a = {
				avatarUrl: this.form.avatarUrl,
				name: this.form.name,
				password: this.form.password,
			}
			if (this.check.checkHasEmpty(a)) {
				//调用函数checkHasEmpty() 检查必填字段是否有为空的
				this.$message("请将数据填写完整")
			} else {
				const res = await this.http
					.post(
						"admin/user/updManager",
						{
							managerId: this.$store.state.user.id,
							updManagerId: this.form.id,
							avatarUrl: this.form.avatarUrl,
							name: this.form.name,
							password: this.form.password,
						},
						false
					)
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.$message({
						type: "success",
						message: "编辑成功！",
					})
					this.dialog = false
					this.getManager(false)
				} else if (res.code == -1) {
					this.$message({
						type: "error",
						message: res.data,
					})
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.search {
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	border-radius: 50%;
	border: rgb(175, 174, 174) solid 1px;
}
/deep/ .avatar-uploader .el-upload {
	border: 1px dashed hsl(0, 0%, 85%);
	cursor: pointer;
	position: relative;
	overflow: hidden;
	background: rgb(204, 204, 204);
	border-radius: 50%;
}
/deep/.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 66px; //上传图片的宽
	height: 66px; //上传图片的高
	line-height: 66px;
	text-align: center;
}
/deep/ .avatar {
	width: 66px; //图片回显的宽
	height: 66px; //图片回显的高
	display: block;
	border-radius: 50%;
}
</style>
