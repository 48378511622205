<template>
	<div class="bg">
		<div class="bg-white loginframe">
			<div class="flex items-center justify-center">
				<img style="width: 30px; height: 30px;margin-right:10px" src="../assets/img/logo1.png" />
				<span style="font-size: 24px; font-weight: bold;color:rgba(36, 123, 255, 1)">钓鱼系统管理端</span>
			</div>
			<div style="margin-top: 30px;display:flex">
				<img style="width: 30px; height: 30px;margin-right:10px;margin-top:5px" src="../assets/img/user.png" />
				<el-input style="width: 300px" placeholder="账户" v-model="form.name"></el-input>
			</div>
			<div style="margin-top: 20px;display:flex">
				<img style="width: 30px; height: 30px;margin-right:10px;margin-top:5px" src="../assets/img/password.png" />
				<el-input style="width: 300px" placeholder="密码" v-model="form.password" @keyup.enter.native="login()" type="password"></el-input>
			</div>
			<div style="margin-top: 20px;display:flex">
				<img style="width: 30px; height: 30px;margin-right:10px;margin-top:5px" src="../assets/img/check.png" />
				<el-input style="width: 150px" placeholder="验证码" v-model="form.code" @keyup.enter.native="login()"></el-input>
				<img @click="getCode()" style="width: 150px; height:40px;margin-left:10px;" :src="codeUrl" />
			</div>
			<div>
				<el-button type="primary" style="width: 300px; margin-top: 20px" @click="login()">登录</el-button>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			form: {
				name: "",
				password: "",
				checked: true,
				imgUrl: "",
				random: "",
			},
			codeUrl: "",
		}
	},
	methods: {
		//获取&&刷新验证码图片
		getCode() {
			this.form.random = parseInt(Math.random() * 1000000)
			this.codeUrl = "https://diaoyupai.cn//admin/manager/captcha?random=" + this.form.random
		},
		async login() {
			// window.sessionStorage.setItem("isLogin", 1) //  给路由防卫传递信息 判断用户是否登录
			// this.$router.push({
			// 	path: "/statistics/userdata",
			// })
			// 完整登录流程在下面 在开发中请用如下代码
			const res = await this.http
				.post("admin/manager/login", {
					name: this.form.name,
					password: this.form.password,
					code: this.form.code,
					random: this.form.random,
				})
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				// 自动登录;
				if (this.form.checked) {
					localStorage.setItem("form", JSON.stringify(this.form)) //如果勾选自动登录 则将数据存到缓存中
				} else {
					localStorage.removeItem("form") // 如果取消自动登录 则去除缓存中的数据
				}

				window.sessionStorage.setItem("isLogin", 1) //  给路由防卫传递信息 判断用户是否登录

				this.$store.commit("setUser", res.data) //可前往store.js增删改自己所需要的字段

				this.$router.push({
					path: "/statistics/userdata",
				})
			} else {
				this.getCode()
			}
		},
	},

	mounted() {
		this.getCode()
		if (localStorage.getItem("form")) {
			this.form.name = JSON.parse(localStorage.getItem("form")).name
			this.form.password = JSON.parse(localStorage.getItem("form")).password
		}
	},
}
</script>

<style lang="scss" scoped>
.bg {
	background-image: url("../assets/img/bgimg.jpg");
	height: 100vh;
	background-size: cover;
	background-repeat: no-repeat;
	border: 1px solid rgba(14, 43, 75, 1);
}
.loginframe {
	text-align: center;
	width: 400px;
	padding: 40px 50px;
	border-radius: 10px;
	position: absolute;
	top: 50%;
	transform: translate(50%, -50%);
	right: 50%;
}
</style>
