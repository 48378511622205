<template>
	<div id="cateCard" class="w-full" style="height:450px"></div>
</template>

<script>
export default {
	props: {
		monthData: Array,
	},
	data() {
		return {
			me: [
				{ product: "1月", 不顺路: 0, 其他: 0, 太多装备放不下: 0, 距离太远: 0 },
				{ product: "2月", 不顺路: 0, 其他: 0, 太多装备放不下: 0, 距离太远: 0 },
				{ product: "3月", 不顺路: 0, 其他: 0, 太多装备放不下: 0, 距离太远: 0 },
				{ product: "4月", 不顺路: 0, 其他: 0, 太多装备放不下: 0, 距离太远: 0 },
				{ product: "5月", 不顺路: 0, 其他: 0, 太多装备放不下: 0, 距离太远: 0 },
				{ product: "6月", 不顺路: 1, 其他: 0, 太多装备放不下: 0, 距离太远: 0 },
				{ product: "7月", 不顺路: 1, 其他: 0, 太多装备放不下: 0, 距离太远: 0 },
				{ product: "8月", 不顺路: 2, 其他: 0, 太多装备放不下: 0, 距离太远: 0 },
				{ product: "9月", 不顺路: 0, 其他: 0, 太多装备放不下: 0, 距离太远: 1 },
				{ product: "10月", 不顺路: 0, 其他: 0, 太多装备放不下: 0, 距离太远: 2 },
				{ product: "11月", 不顺路: 0, 其他: 0, 太多装备放不下: 0, 距离太远: 0 },
				{ product: "12月", 不顺路: 0, 其他: 0, 太多装备放不下: 0, 距离太远: 0 },
			],
		}
	},
	mounted() {
		this.drawLine()
		console.log(this.monthData[0])
		console.log(this.me[0])
	},
	methods: {
		drawLine() {
			var chartDom = document.getElementById("cateCard")
			var myChart = this.$echarts.init(chartDom)
			var option = {
				legend: { bottom: "bottom" },
				tooltip: {},
				color: "rgb(60, 144, 247)",
				dataset: {
					source: this.monthData,
				},
				grid: {
					top: "6%",
					left: "3%",
				},
				xAxis: { type: "category" },
				yAxis: {},
				series: [
					{ type: "bar", color: "rgba(60, 144, 247, 1)" },
					{ type: "bar", color: "rgba(85, 191, 192, 1)" },
					{ type: "bar", color: "rgba(94, 190, 103, 1)" },
					{ type: "bar", color: "rgba(244, 205, 73, 1)" },
				],
			}
			myChart.setOption(option)
			window.addEventListener("resize", () => {
				myChart.resize()
			})
		},
	},
}
</script>

<style lang="scss" scoped></style>
