import Vue from "vue"
import Router from "vue-router"
import NProgress from "nprogress"
import "nprogress/nprogress.css"

//父容器
import Index from "../views/Index.vue"
import Login from "../views/Login.vue"

// 首页管理
import indexView from "../views/index/View.vue"
import indexPage from "../views/index/Page.vue"

// 账号管理
import accountView from "../views/account/View.vue"
import accountAdmin from "../views/account/AccountAdmin.vue"
import accountNumber from "../views/account/AccountNumber.vue"
import userDetail from "../views/account/UserDetail.vue"
import harvest from "../views/account/Harvest.vue"

//渔获管理
import fishCatchView from '../views/fishCatch/View.vue'
import fishCatch from '../views/fishCatch/fishCatch.vue'
import catchExamine from '../views/fishCatch/catchExamine'
import examineDetail from '../views/fishCatch/examineDetail'

// 钓鱼技巧管理
import courseView from "../views/course/View.vue"
import fishingMethods from "../views/course/methodsSkill/FishingMethods.vue"
import fishingSkill from "../views/course/methodsSkill/FishingSkill.vue"
import fishingTools from "../views/course/fishTools/FishingTools.vue"
import novice from "../views/course/fishSkill/Novice.vue"
import courseDetail from "../views/course/fishSkill/courseDetail.vue"
import fishType from "../views/course/fish/fishType.vue"
import fishEncy from "../views/course/fish/fishEncy.vue"
import fishDetail from "../views/course/fish/fishDetail.vue"
import fishMeSkDetail from '../views/course/methodsSkill/fishMeSkDetail'
import toolEncy from '../views/course/fishTools/toolEncy'
import toolDetail from '../views/course/fishTools/toolDetail'

//任务分组
import taskView from "../views/task/View.vue"
import task from "../views/task/task.vue"

//活动管理
import activityView from "../views/activity/View.vue"
import release from "../views/activity/release.vue"
import activityEdit from "../views/activity/activityEdit.vue"
import activityDetail from "../views/activity/activityDetail.vue"
import activityExamine from "../views/activity/activityExamine.vue"
import actExamineDetail from "../views/activity/actExamineDetail.vue"

//鱼币商城管理
import shopView from "../views/shop/View.vue"
import fishShop from "../views/shop/fishShop.vue"
import forRecord from "../views/shop/forRecord.vue"

//其他管理
import systemView from "../views/system/View.vue"
import list from "../views/system/list.vue"
import groupChat from "../views/system/groupChat.vue"
import helpCenter from "../views/system/helpCenter.vue"
import helpCenterDetail from "../views/system/helpCenterDetail.vue"
import adminQrcode from "../views/system/adminQrcode.vue"
import poster from "../views/system/poster.vue"
import introduction from "../views/system/introduction.vue"
import inform from "../views/system/inform.vue"
import informDetail from "../views/system/informDetail.vue"
import fishMethod from "../views/system/fishMethod"

//数据统计
import statisticsView from "../views/statistics/View.vue"
import userdata from "../views/statistics/userdata.vue"
import fishingdata from "../views/statistics/fishingdata.vue"

Vue.use(Router)
var router = new Router({
  routes: [
    {
      path: "/",
      name: "/",
      component: Index,
      redirect: "/index/page",
      children: [
        //首页管理
        {
          path: "index",
          name: "index",
          component: indexView,
          children: [
            {
              path: "page",
              name: "page",
              component: indexPage,
            },
          ],
        },
        // 账号管理
        {
          path: "account",
          name: "account",
          component: accountView,
          children: [
            {
              path: "accountAdmin",
              name: "accountAdmin",
              component: accountAdmin,
              meta: {
                title: '管理员管理',
                keepAlive: true
              }
            },
            {
              path: "accountNumber",
              name: "accountNumber",
              component: accountNumber,
              meta: {
                title: '账号管理',
                keepAlive: true
              }
            },
            {
              path: "userDetail",
              name: "userDetail",
              component: userDetail,
              meta: {
                title: '用户详情',
                keepAlive: false
              }
            },
            {
              path: "harvest",
              name: "harvest",
              component: harvest,
              meta: {
                title: '渔获详情',
                keepAlive: false
              }
            }
          ],
        },
        //渔获管理
        {
          path: "fishCatch",
          name: "fishCatch",
          component: fishCatchView,
          children: [
            {
              path: "fishCatch",
              name: "fishCatch",
              component: fishCatch,
              meta: {
                title: '渔获管理',
                keepAlive: true,
              }
            },
            {
              path: "catchExamine",
              name: "catchExamine",
              component: catchExamine,
              meta: {
                title: '渔获审核',
                keepAlive: true,
              }
            },
            {
              path: "examineDetail",
              name: "examineDetail",
              component: examineDetail,
              meta: {
                title: '审核信息',
                keepAlive: false,
              }
            }]
        },
        // 技巧管理
        {
          path: "course",
          name: "course",
          component: courseView,
          children: [
            {
              path: "novice",
              name: "novice",
              component: novice,
              meta: {
                title: '钓鱼技巧',
                keepAlive: true,
                mask: true
              }
            },
            {
              path: "fishingMethods",
              name: "fishingMethods",
              component: fishingMethods,
              meta: {
                title: '钓法钓技',
                keepAlive: true
              }
            },
            {
              path: "fishingTools",
              name: "fishingTools",
              component: fishingTools,
              meta: {
                title: '工具装备',
                keepAlive: true
              }
            },
            {
              path: "fishingSkill",
              name: "fishingSkill",
              component: fishingSkill,
            },
            {
              path: "courseDetail",
              name: "courseDetail",
              component: courseDetail,
              meta: {
                title: '钓鱼技巧详情',
                keepAlive: false
              }
            },
            {
              path: "fishType",
              name: "fishType",
              component: fishType,
              meta: {
                title: '鱼类型管理',
                keepAlive: true
              }
            },
            {
              path: "fishEncy",
              name: "fishEncy",
              component: fishEncy,
            },
            {
              path: "fishDetail",
              name: "fishDetail",
              component: fishDetail,
              meta: {
                title: '鱼详情',
                keepAlive: false
              }
            },
            {
              path: "fishMeSkDetail",
              name: "fishMeSkDetail",
              component: fishMeSkDetail,
              meta: {
                title: '钓法钓技详情',
                keepAlive: false
              }
            },
            {
              path: "toolEncy",
              name: "toolEncy",
              component: toolEncy,
            },
            {
              path: "toolDetail",
              name: "toolDetail",
              component: toolDetail,
              meta: {
                title: '工具详情',
                keepAlive: false
              }
            },
          ],
        },
        //任务管理
        {
          path: "task",
          name: "task",
          component: taskView,
          children: [
            {
              path: "task",
              name: "task",
              component: task,
              meta: {
                title: '任务管理',
                keepAlive: false
              }
            },
          ],
        },
        //活动管理
        {
          path: "activity",
          name: "activity",
          component: activityView,
          children: [
            {
              path: "release",
              name: "release",
              component: release,
              meta: {
                title: '活动管理',
                keepAlive: true
              }
            },
            {
              path: "activityEdit",
              name: "activityEdit",
              component: activityEdit,
              meta: {
                title: '活动详情',
                keepAlive: false
              }
            },
            {
              path: "activityDetail",
              name: "activityDetail",
              component: activityDetail,
              meta: {
                title: '活动编辑',
                keepAlive: false
              }
            }, {
              path: "activityExamine",
              name: "activityExamine",
              component: activityExamine,
              meta: {
                title: '活动审核',
                keepAlive: true
              }
            }, {
              path: "actExamineDetail",
              name: "actExamineDetail",
              component: actExamineDetail,
              meta: {
                title: '审核信息',
                keepAlive: false
              }
            }
          ],
        },
        //其他管理
        {
          path: "system",
          name: "system",
          component: systemView,
          children: [
            {
              path: "list",
              name: "list",
              component: list,
              meta: {
                title: '榜单管理',
                keepAlive: true
              }
            },
            {
              path: 'adminQrcode',
              name: 'adminQrcode',
              component: adminQrcode,
              meta: {
                title: '管理员二维码',
                keepAlive: true
              }
            },
            {
              path: "groupChat",
              name: "groupChat",
              component: groupChat,
              meta: {
                title: '本地群聊',
                keepAlive: true
              }
            },
            {
              path: "helpCenter",
              name: "helpCenter",
              component: helpCenter,
              meta: {
                title: '帮助中心',
                keepAlive: true
              }
            },
            {
              path: "helpCenterDetail",
              name: "helpCenterDetail",
              component: helpCenterDetail,
              meta: {
                title: '帮助中心详情',
                keepAlive: false
              }
            }, {
              path: "poster",
              name: "poster",
              component: poster,
              meta: {
                title: '海报管理',
                keepAlive: false
              }
            }, {
              path: "introduction",
              name: "introduction",
              component: introduction,
              meta: {
                title: '鱼塘攻略',
                keepAlive: false
              }
            },
            {
              path: "inform",
              name: "inform",
              component: inform,
              meta: {
                title: '公告通知',
                keepAlive: true
              }
            },
            {
              path: "informDetail",
              name: "informDetail",
              component: informDetail,
              meta: {
                title: '公告通知编辑',
                keepAlive: false
              }
            },
            {
              path: "fishMethod",
              name: "fishMethod",
              component: fishMethod,
              meta: {
                title: '钓法管理',
                keepAlive: true
              }
            }
          ],
        },
        //鱼币商城管理
        {
          path: "shop",
          name: "shop",
          component: shopView,
          children: [
            {
              path: "fishShop",
              name: "fishShop",
              component: fishShop,
              meta: {
                title: '鱼币商城管理',
                keepAlive: false
              }
            },
            {
              path: "forRecord",
              name: "forRecord",
              component: forRecord,
              meta: {
                title: '兑换记录',
                keepAlive: false
              }
            }
          ],
        },
        //数据统计
        {
          path: "statistics",
          name: "statistics",
          component: statisticsView,
          children: [
            {
              path: "userdata",
              name: "userdata",
              component: userdata,
              meta: {
                title: '首页',
                keepAlive: false
              }
            },
            {
              path: "fishingdata",
              name: "fishingdata",
              component: fishingdata,
            },
          ],
        },
      ],
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        title: "登录页面",
      },
    },
  ],
  linkActiveClass: "active",
})

NProgress.configure({ showSpinner: true })
router.beforeEach((to, from, next) => {
  NProgress.start()
  if (to.path === "/login") {
    next()
  }
  if (sessionStorage.getItem("isLogin") != 1 && to.path != "/login") {
    next("/login")
  } else {
    next()
  }
})

router.afterEach(() => {
  NProgress.done()
})
export default router
