<template>
	<div class="p-10">
		<div class=" flex justify-between">
			<div class="text-xl font-bold">
				<i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>渔获详情
			</div>
			<div class=" mr-10">
				<el-button size="medium" style="width:100px" type="primary" @click="changStatus1()"
					>通过</el-button
				>
				<el-button
					size="medium"
					style="width:100px"
					type="danger"
					@click="dialogVisible = true"
					>驳回</el-button
				>
			</div>
		</div>
		<div class=" text-xl font-bold mt-8 ml-8" style="color:rgba(128, 128, 128, 1)">
			渔获内容
		</div>
		<div class=" text-xl mt-4 ml-8">
			{{ harvest.content }}
		</div>
		<div class="flex" v-if="harvest.img">
			<div v-for="(i, index) in harvest.img" :key="index" class="ml-8 mt-8">
				<img class=" w-48 h-48" :src="i" alt="" v-image-preview />
			</div>
		</div>
		<div class=" text-xl font-bold mt-10 ml-8 flex" style="color:rgba(128, 128, 128, 1)">
			<div class=" mr-16">
				时间：<span class=" font-normal text-black">{{ harvest.createTime }}</span>
			</div>
			<div class=" mr-16">
				钓法：<span class=" font-normal text-black">{{ harvest.skill }}</span>
			</div>
			<div class="mr-16">
				单尾最重：<span class=" font-normal text-black">{{ harvest.heaviest }}</span>
			</div>
			<div class="mr-16">
				单尾最长：<span class=" font-normal text-black">{{ harvest.longest }}</span>
			</div>
			<div class="mr-16">
				数量：<span class=" font-normal text-black">{{ harvest.num }}</span>
			</div>
			<div class=" mr-16">
				钓点：<span class=" font-normal text-black">{{ harvest.fPoint }}</span>
			</div>
		</div>
		<el-dialog title="驳回理由" :visible.sync="dialogVisible" width="55%">
			<div style="border-bottom:rgba(229, 229, 229, 1) solid 1px;margin-top: -30px;"></div>
			<div class="flex items-center mt-8">
				<div class=" text-lg mr-5">头像</div>
				<div class="mr-48">
					<img class="img1" :src="harvest.avatarUrl" alt="" />
				</div>
				<div class=" text-lg">发起者：</div>
				<div class=" text-lg mr-5">{{ harvest.name }}</div>
			</div>
			<div class="mt-5">
				<el-input type="textarea" rows="10" placeholder="请输入驳回理由" v-model="reason">
				</el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false" type="danger">取 消</el-button>
				<el-button type="primary" @click="changStatus2()">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import pagination from "../../components/Pagination/pagination.vue"
export default {
	components: { pagination },
	data() {
		return {
			id: this.$route.query.harvestId,
			harvest: "",
			pages: 1,
			dialogVisible: false,
			reason: "",
		}
	},
	mounted() {
		this.getHarvestDetails()
	},
	methods: {
		//获取渔获详情
		async getHarvestDetails() {
			const res = await this.http
				.post(
					"admin/article/getHarvestDetails",
					{
						harvestId: this.id,
						pages: this.pages,
						limit: 2,
					},
					false
				)
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.harvest = res.data.harvest
			}
		},
		//编辑审核状态&&通过
		async changStatus1() {
			const res = await this.http
				.post("admin/article/updHarvest", {
					harvestId: Number(this.id),
					audit: 1,
				})
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.$router.go(-1)
			}
		},
		//编辑审核状态&&驳回
		async changStatus2() {
			if (!this.reason) {
				this.$message("请将数据填写完整")
			} else {
				const res = await this.http
					.post("admin/article/updHarvest", {
						harvestId: Number(this.id),
						audit: -1,
						reason: this.reason,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.$router.push({
						path: "catchExamine",
					})
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.avatar {
	width: 35px;
	height: 35px;
	border-radius: 50%;
}
.zhankai {
	display: block;
}
.yinchang {
	display: none;
}
.img1 {
	width: 65px;
	height: 65px;
	border-radius: 50%;
}
</style>
