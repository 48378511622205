<template>
	<div>
		<el-dialog title="删除理由" :visible="dialogVisible" width="55%" @close="closeDig()">
			<div style="border-bottom:rgba(229, 229, 229, 1) solid 1px;margin-top: -30px;"></div>
			<div class="flex items-center mt-8">
				<div class=" text-lg mr-5">头像</div>
				<div class="mr-48">
					<img class="img1" :src="avatarUrl" alt="" />
				</div>
				<div class=" text-lg">发起者：</div>
				<div class=" text-lg mr-5">{{ name }}</div>
			</div>
			<div class="mt-5">
				<el-input type="textarea" rows="10" placeholder="请输入删除" v-model="reason">
				</el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closeDig()" type="danger">取 消</el-button>
				<el-button type="primary" @click="delActivity()">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {
		avatarUrl: String,
		name: String,
		dialogVisible: {
			type: Boolean,
			default: false,
		},
		id: Number,
	},
	components: {},

	data() {
		return {
			reason: "",
		}
	},
	methods: {
		closeDig() {
			this.$emit("closeDig", false)
		},
		async delActivity() {
			if (!this.reason) {
				//调用函数checkHasEmpty() 检查必填字段是否有为空的
				this.$message("请将数据填写完整")
			} else {
				const res = await this.http
					.post("admin/activity/delActivity", {
						activityId: this.id,
						reason: this.reason,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.$emit("closeDig", false)
					this.reason = ""
				}
			}
		},
	},
}
</script>

<style scoped lang="scss">
.img1 {
	width: 65px;
	height: 65px;
	border-radius: 50%;
}
</style>
