<template>
	<div class="p-10">
		<div class="text-xl font-bold"><i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>管理员二维码</div>
		<div class="mt-5 flex">
			<div>
				<el-input v-model="input" placeholder="搜索名称" style="width: 170px"></el-input>
			</div>
			<div class="search ml-4 mt-1 cursor-pointer">
				<i class="cuIcon-search"></i>
			</div>
			<div class="search ml-4 mt-1 text-white cursor-pointer" style="background: rgba(117, 199, 77, 1)">
				<i class="el-icon-refresh"></i>
			</div>
			<div class="search ml-4 mt-1 bg-blue-500 text-white cursor-pointer" @click="clickAdd()">
				<i class="cuIcon-add"></i>
			</div>
		</div>
		<div class="mt-5 border-2">
			<el-table
				:cell-style="{ 'text-align': 'center' }"
				:header-cell-style="{ 'text-align': 'center', background: '#fafafa' }"
				:data="tableData"
				stripe
				style="width: 100%"
			>
				<!-- <el-table-column prop="name" label="群聊名称"></el-table-column> -->
				<el-table-column label="群聊二维码">
					<template slot-scope="scope">
						<el-image :src="scope.row.qrcode" class="w-14 h-14" fit="fit"></el-image>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="150">
					<template slot-scope="scope">
						<el-button style="text-decoration: underline" @click="handleClick(scope.row)" type="text">编辑</el-button>
						<el-button style="text-decoration: underline; color: red" type="text" @click="delChat(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<pagination :currentPage="pages" :total="total" @pageChange="changePages"></pagination>

		<el-dialog :title="isAdd ? '编辑管理员二维码' : '新增管理员二维码'" :visible.sync="dialog" width="400px" :close-on-click-modal="false">
			<!-- <div class="flex items-center">
				<div class="font-bold mr-5 w-24" style="color: rgba(128, 128, 128, 1); text-align: right">
					本地群聊名称
				</div>
				<div>
					<el-input v-model="form.name" placeholder="请输入" style="width: 250px"></el-input>
				</div>
			</div> -->
			<!-- <div class="flex mt-6 items-center">
				<div class="font-bold mr-5 w-24" style="color: rgba(128, 128, 128, 1); text-align: right">
					地区
				</div>
				<div>
					<el-input v-model="form.area" placeholder="请输入" style="width: 250px"></el-input>
				</div>
			</div> -->
			<div class=" font-bold flex items-center" style="color: rgba(128, 128, 128, 1)">
				<div style="padding-top: 3px">上传二维码</div>
				<div>
					<i class="el-icon-upload" style="font-size: 30px; padding-left: 10px"></i>
				</div>
			</div>
			<div style="width: 100%; height: 160px; border: black solid 1px" class="mt-3">
				<div class="text-center addUpload">
					<el-upload ref="upload" action="#" :show-file-list="false" :multiple="false" :auto-upload="false" :on-change="imgSaveToUrl">
						<img v-if="form.qrcode" :src="form.qrcode" class="imgSize"/>
						<i v-else class="el-icon-plus"></i
					></el-upload>
				</div>
			</div>
			<div class="mt-5 text-center">
				<el-button size="small" class="mr-10" @click="dialog = false">取消</el-button>
				<el-button type="primary" size="small" @click="isAdd ? updChat() : addChat()">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import pagination from "../../components/Pagination/pagination.vue"
export default {
	components: { pagination },
	data() {
		return {
			input: "",
			tableData: [],
			isAdd: false,
			dialog: false,
			form: {
				// name: "",
				qrcode: "",
				// area: "",
			},
			total: 0, //总页数
			pages: 1, //当前页数
			limit: 5,
		}
	},

	mounted() {
		this.getChat()
	},
	activated() {
		if (!this.value) {
			this.getChat()
		} else {
			this.search()
		}
	},
	methods: {
		//分页
		changePages(data) {
			this.pages = data._currentPage
			this.limit = data._pageSize
			if (!this.value) {
				this.getChat()
			} else {
				this.search()
			}
		},
		// seeDetail(row) {
		// 	this.$router.push({ path: "userDetail" })
		// },

		//获取管理员二维码
		async getChat() {
			const res = await this.http
				.post(
					"admin/chat/getQrcode",
					{
						pages: this.pages,
						limit: this.limit,
					},
					false
				)
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.qrcode
				this.total = res.data.total
			}
		},
		//点击新增
		clickAdd() {
			this.dialog = true
			this.isAdd = false
			this.form = { qrcode: "" }
		},
		//点击编辑
		handleClick(row) {
			this.dialog = true
			this.isAdd = true
			this.form = JSON.parse(JSON.stringify(row))
			console.log(this.form)
		},
		//添加本地群聊
		async addChat() {
			if (this.check.checkHasEmpty(this.form)) {
				//调用函数checkHasEmpty() 检查必填字段是否有为空的
				this.$message("请将数据填写完整")
			} else {
				const res = await this.http
					.post("admin/chat/addQrcode", {
						qrcode: this.form.qrcode,
						// area: this.form.area,
						// name: this.form.name,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.dialog = false
					this.getChat()
				}
			}
		},
		//编辑本地群聊
		async updChat() {
			if (this.check.checkHasEmpty(this.form)) {
				//调用函数checkHasEmpty() 检查必填字段是否有为空的
				this.$message("请将数据填写完整")
			} else {
				const res = await this.http
					.post("admin/chat/updQrcode", {
						qrcodeId: this.form.id,
						qrcode: this.form.qrcode,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.dialog = false
					this.getChat()
				}
			}
		},
		//删除本地群聊
		async delChat(row) {
			this.$confirm("此操作将永久删除该本地群聊, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/chat/delQrcode", {
							qrcodeId: row.id,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						this.getChat()
					}
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					})
				})
		},
		// 图片上传
		async imgSaveToUrl(event) {
			if (this.beforeUploadPicture(event.raw)) {
				var formData = new FormData()
				formData.append("img", event.raw)
				console.log(formData)
				this.http.upload("admin/upload/uploadImg", formData).then((res) => {
					if (res.code == 1) {
						this.form.qrcode = res.data
						this.$message.success("上传成功")
					}
				})
			}
		},
		// 图片上传之前
		beforeUploadPicture(file) {
			const isJPG = file.type === "image/jpeg"
			const isPNG = file.type === "image/png"
			const isLt10M = file.size / 1024 / 1024 < 10
			if (!isJPG && !isPNG) {
				this.$message.error("上传图片只能是 JPG 或者 PNG 格式!")
			}
			if (isJPG && isPNG) {
				if (!isLt10M) {
					this.$message.error("上传图片大小不能超过 10MB!")
				}
			}
			return (isJPG || isPNG) && isLt10M
		},
	},
}
</script>

<style lang="scss" scoped>
.search {
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	border-radius: 50%;
	border: rgb(175, 174, 174) solid 1px;
}
.addUpload {
	height: 160px;
	width: 150px;
	line-height: 160px;
	font-size: 50px;
	margin: auto;
}
.imgSize {
	width: 160px;
	height: 157px;
}
</style>
