import { render, staticRenderFns } from "./activityDetail.vue?vue&type=template&id=5f7c22e1&scoped=true&"
import script from "./activityDetail.vue?vue&type=script&lang=js&"
export * from "./activityDetail.vue?vue&type=script&lang=js&"
import style0 from "./activityDetail.vue?vue&type=style&index=0&id=5f7c22e1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f7c22e1",
  null
  
)

export default component.exports