<template>
	<div class="p-10">
		<div class="text-xl font-bold">
			<i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>公告通知
		</div>
		<div class="mt-5 flex">
			<div>
				<el-input v-model="input" placeholder="搜索名称" style="width: 170px"></el-input>
			</div>
			<div class="search ml-4 mt-1 cursor-pointer" @click="clickSearch()">
				<i class="cuIcon-search"></i>
			</div>
			<div
				class="search ml-4 mt-1 text-white cursor-pointer"
				style="background: rgba(117, 199, 77, 1)"
				@click="reset()"
			>
				<i class="el-icon-refresh"></i>
			</div>
			<div class="search ml-4 mt-1 bg-blue-500 text-white cursor-pointer" @click="toAdd()">
				<i class="cuIcon-add"></i>
			</div>
		</div>
		<div class="mt-5 border-2">
			<el-table
				:data="tableData"
				stripe
				:header-cell-style="{ background: '#fafafa' }"
				style="width: 100%"
			>
				<el-table-column prop="title" label="公告通知" width="300px"></el-table-column>
				<el-table-column label="启动状态">
					<template slot-scope="scope">
						<el-switch
							v-model="scope.row.status"
							active-value="1"
							inactive-value="0"
							active-color="#13ce66"
							inactive-color="#bebebe"
							@change="updSkill(scope.row)"
						></el-switch>
					</template>
				</el-table-column>
				<el-table-column prop="createTime" label="创建时间"></el-table-column>
				<el-table-column label="操作" width="150">
					<template slot-scope="scope">
						<el-button
							style="text-decoration:underline"
							type="text"
							@click="toDetail(scope.row)"
							>编辑</el-button
						>
						<el-button
							style="text-decoration:underline;color:red"
							type="text"
							@click="delNotice(scope.row)"
							>删除</el-button
						>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<pagination :currentPage="pages" :total="total" @pageChange="changePages"></pagination>
	</div>
</template>

<script>
import pagination from "../../components/Pagination/pagination.vue"
export default {
	components: { pagination },

	data() {
		return {
			input: "",
			pages: 1,
			total: 0,
			limit: 5,
			tableData: [],
		}
	},

	mounted() {
		this.getNotice()
	},

	activated() {
		if (!this.input) {
			this.getNotice()
		} else {
			this.searchNoticeDet()
		}
	},
	methods: {
		//分页
		changePages(data) {
			this.pages = data._currentPage
			this.limit = data._pageSize
			if (!this.input) {
				this.getNotice()
			} else {
				this.searchNoticeDet()
			}
		},

		//点击查询
		clickSearch() {
			this.pages = 1
			console.log(!this.input)
			if (this.input == "") {
				this.getNotice()
			} else {
				this.searchNoticeDet()
			}
		},

		//刷新
		reset() {
			this.input = ""
			this.pages = 1
			this.getNotice()
		},
		//点击新增
		toAdd() {
			this.$router.push({
				path: "informDetail",
			})
		},
		//点击编辑
		toDetail(row) {
			this.$router.push({
				path: "informDetail",
				query: { id: row.id },
			})
		},
		//获取公告
		async getNotice() {
			const res = await this.http
				.get("admin/notice/getNotice", {
					pages: this.pages,
					limit: this.limit,
				})
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.data
				this.total = res.data.total
			}
		},

		//搜索公告
		async searchNoticeDet() {
			const res = await this.http
				.post("admin/notice/searchNoticeDet", {
					title: this.input,
					pages: this.pages,
					limit: this.limit,
				})
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.data
				this.total = res.data.total
			}
		},

		//开启&关闭状态
		async updSkill(row) {
			let flag = row.status
			row.status == "1" ? (row.status = "0") : (row.status = "1")
			this.$confirm("是否改变该状态", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/notice/updNotice", {
							noticeId: row.id,
							status: flag,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						flag == "1" ? (row.status = "1") : (row.status = "0") // 这一步很重要，row.showState会根据flag的值开启或关闭开关
					}
				})
				.catch(() => {
					this.$message({ type: "info", showClose: true, message: "已取消修改！" })
				})
		},
		//删除公告
		async delNotice(row) {
			this.$confirm("此操作将永久删除该公告, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/notice/delNotice", {
							noticeId: row.id,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						this.getNotice()
					}
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					})
				})
		},
	},
}
</script>

<style scoped lang="scss">
.search {
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	border-radius: 50%;
	border: rgb(175, 174, 174) solid 1px;
}
</style>
