<template>
	<div class="p-10">
		<div class=" flex justify-between">
			<div class="text-xl font-bold">
				<i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>渔获详情
			</div>
			<div class=" mr-10">
				<el-button size="medium" style="width:100px" type="info" @click="toBack()"
					>返回</el-button
				>
			</div>
		</div>
		<div class=" text-xl font-bold mt-8 ml-8" style="color:rgba(128, 128, 128, 1)">
			渔获内容
		</div>
		<div class=" text-xl mt-4 ml-8">
			{{ harvest.content }}
		</div>
		<div class="flex" v-if="harvest.img">
			<div v-for="(i, index) in harvest.img" :key="index" class="ml-8 mt-8">
				<img class=" w-48 h-48" :src="i" alt="" />
			</div>
		</div>
		<div class=" text-xl font-bold mt-10 ml-8 flex" style="color:rgba(128, 128, 128, 1)">
			<div class=" mr-16">
				时间：<span class=" font-normal text-black">{{ harvest.createTime }}</span>
			</div>
			<div class=" mr-16">
				钓法：<span class=" font-normal text-black">{{ harvest.skill }}</span>
			</div>
			<div class="mr-16">
				单尾最重：<span class=" font-normal text-black">{{ harvest.heaviest }}</span>
			</div>
			<div class="mr-16">
				单尾最长：<span class=" font-normal text-black">{{ harvest.longest }}</span>
			</div>
			<div class="mr-16">
				数量：<span class=" font-normal text-black">{{ harvest.num }}</span>
			</div>
			<div class=" mr-16">
				钓点：<span class=" font-normal text-black">{{ harvest.fPoint }}</span>
			</div>
		</div>
		<div class=" text-xl font-bold mt-10 ml-8 flex" style="color:rgba(128, 128, 128, 1)">
			<div class=" mr-20">
				点赞数：<span class=" font-normal text-black">{{ harvest.favorNum }}</span>
			</div>
			<div class=" mr-20">
				评论数：<span class=" font-normal text-black">{{ harvest.commentNum }}</span>
			</div>
			<div class="mr-16">
				热门状态：<el-switch
					v-model="harvest.hot"
					active-value="1"
					inactive-value="0"
					active-color="#13ce66"
					inactive-color="#bebebe"
					@change="changStatus0($event)"
				></el-switch>
			</div>
			<div class="mr-20">
				精选状态：<el-switch
					v-model="harvest.boutique"
					active-value="1"
					inactive-value="0"
					active-color="#13ce66"
					inactive-color="#bebebe"
					@change="changStatus1($event)"
				></el-switch>
			</div>
		</div>
		<div class="text-xl font-bold mt-20">
			<i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>评论
		</div>
		<div v-if="isBlank"><el-empty description="该渔获还没有评论"></el-empty></div>
		<div v-else class="mt-5">
			<div
				class=" w-4/5 mt-10"
				style="border:rgba(200, 200, 200,0.7) solid 1px"
				v-for="(item, index) in comment"
				:key="index"
			>
				<div class="flex items-center m-5">
					<div class="mr-6"><img class="avatar" :src="item.avatarUrl" alt="" /></div>
					<div>{{ item.name ? item.name : item.nickName }}</div>
					<div class="ml-10">{{ item.createTime }}</div>
					<div
						class=" text-red-500 mr-20"
						style="margin-left:auto;"
						@click="clickDel(item)"
					>
						删除
					</div>
				</div>
				<div class="m-5">{{ item.content }}</div>
				<div class="flex items-center m-5">
					<div>点赞数：{{ item.favortNum }}</div>
					<div class="ml-10">回复数：{{ item.commentNum }}</div>
					<div
						class="ml-10 text-blue-500"
						@click="isShow['isShow' + index] = !isShow['isShow' + index]"
					>
						{{ isShow["isShow" + index] ? "收起" : "展开" }}
					</div>
				</div>
				<div>
					<div :class="isShow['isShow' + index] ? 'zhankai' : 'yinchang'">
						<div
							class="ml-20 mb-5"
							v-for="(i, index1) in reply['reply' + index]"
							:key="index1"
						>
							<div>
								<span class="mr-5" style="color:rgba(118, 188, 255, 1)">{{
									i.name ? i.name : i.nickName
								}}</span
								><span class="mr-5">回复</span
								><span class="mr-5" style="color:rgba(118, 188, 255, 1)">{{
									i.rName ? i.rName : i.rNickName
								}}</span
								><span class="mr-5">{{ i.createTime }}</span>
								<span
									class=" text-red-500 mr-20"
									style="text-align: right;"
									@click="clickDel(i)"
									>删除</span
								>
							</div>
							<div class="ml-10 mt-3">{{ i.content }}</div>
						</div>
						<pagination
							:currentPage="pages['pages' + index]"
							:total="total['total' + index]"
							:isShow="false"
							@pageChange="index == 0 ? changePages0($event) : changePages1($event)"
						></pagination>
					</div>
				</div>
			</div>
			<div class=" w-5/6">
				<pagination
					:isShow="false"
					:currentPage="pages1"
					:pageSize="2"
					:total="total1"
					@pageChange="changePages2"
				></pagination>
			</div>
		</div>
		<el-dialog title="删除原因" :visible.sync="dialogVisible" width="30%">
			<div style="border-bottom:rgba(229, 229, 229, 1) solid 1px;margin-top: -30px;"></div>
			<div class="flex mt-5">
				<div style="width: 80px;">删除原因</div>
				<el-input
					type="textarea"
					rows="20"
					placeholder="请输入驳回理由"
					v-model="reason"
				></el-input>
			</div>
			<span slot="footer" class="" style="display:block;text-align:center;">
				<el-button
					style="margin-right: 50px;"
					@click=";(dialogVisible = false), (reason = '')"
					type="danger"
					>取 消</el-button
				>
				<el-button style="margin-left: 50px;" type="primary" @click="delComment()"
					>确 定</el-button
				>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import pagination from "../../components/Pagination/pagination.vue"
export default {
	components: { pagination },
	data() {
		return {
			id: this.$route.query.harvestId,
			value: "1",
			activeNames: ["1"],
			isShow: {
				isShow0: false,
				isShow1: false,
			},
			pages: {
				pages0: 1,
				pages1: 1,
			},
			pages1: 1,
			total: {
				total0: 1,
				total1: 1,
			},
			total1: 1,
			harvest: {},
			comment: [],
			commentId1: {
				id: "",
				isOne: true,
			},
			commentId2: {
				id: "",
				isOne: false,
			},
			reply: {
				reply0: [],
				reply1: [],
			},
			isBlank: false,
			dialogVisible: false,
			delCommentData: {
				id: null,
				openid: null,
			},
			reason: "",
		}
	},
	mounted() {
		// console.log(this.$route)
		this.getHarvestDetails()
	},
	methods: {
		//分页
		changePages0(data) {
			this.pages.pages0 = data._currentPage
			this.getComment(this.commentId1)
		},
		changePages1(data) {
			this.pages.pages1 = data._currentPage
			this.getComment(this.commentId2)
		},
		changePages2(data) {
			this.pages1 = data._currentPage
			this.getHarvestDetails()
			this.isShow.isShow0 = false
			this.isShow.isShow1 = false
		},
		//获取渔获详情
		async getHarvestDetails() {
			const res = await this.http
				.post(
					"admin/article/getHarvestDetails",
					{
						harvestId: this.id,
						pages: this.pages1,
						limit: 2,
					},
					false
				)
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.harvest = res.data.harvest
				this.comment = res.data.comment
				this.total1 = res.data.total
				if (res.data.comment.length == 0) {
					this.isBlank = true
				} else if (res.data.comment.length == 1) {
					this.commentId1.id = res.data.comment[0].id
					this.getComment(this.commentId1)
				} else {
					this.commentId1.id = res.data.comment[0].id
					this.commentId2.id = res.data.comment[1].id
					this.getComment(this.commentId1)
					this.getComment(this.commentId2)
				}
			}
		},
		//获取回复
		async getComment(id) {
			const res = await this.http
				.post(
					"admin/article/getComment",
					{
						harvestId: this.id,
						commentId: id.id,
						pages: id.isOne ? this.pages.pages0 : this.pages.pages1,
						limit: 4,
					},
					false
				)
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				if (id.isOne) {
					this.reply.reply0 = res.data.comment
					this.total.total0 = res.data.total
				} else {
					this.reply.reply1 = res.data.comment
					this.total.total1 = res.data.total
				}
			}
		},
		//编辑详情
		async changStatus0(event) {
			this.harvest.hot = event
			const res = await this.http
				.post("admin/article/updHarvest", {
					harvestId: this.id,
					hot: this.harvest.hot,
				})
				.catch((err) => {
					console.log(err)
				})
		},
		async changStatus1(event) {
			this.harvest.boutique = event
			const res = await this.http
				.post("admin/article/updHarvest", {
					harvestId: this.id,
					boutique: this.harvest.boutique,
				})
				.catch((err) => {
					console.log(err)
				})
		},
		//点击删除
		clickDel(e) {
			this.dialogVisible = true
			this.delCommentData.id = e.id
			this.delCommentData.openid = e.openid
			console.log(this.delCommentData)
		},
		//删除评论
		async delComment() {
			if (!this.reason) {
				this.$message("请将数据填写完整")
			} else {
				const res = await this.http
					.post("admin/article/delComment", {
						attachId: this.delCommentData.id,
						openid: this.delCommentData.openid,
						reason: this.reason,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.dialogVisible = false
					this.reason = ""
					this.getHarvestDetails()
				}
			}
		},
		toBack() {
			this.$router.go(-1)
		},
	},
}
</script>

<style lang="scss" scoped>
.avatar {
	width: 35px;
	height: 35px;
	border-radius: 50%;
}
.zhankai {
	display: block;
}
.yinchang {
	display: none;
}
</style>
