export default {
    data() {
        return {
            dialogVisible: false,
            name: '',
            avatarUrl: '',
            actId: null
        }
    },
    mounted() {

    },
    methods: {
        //点击删除活动
        delActivity(row) {
            this.dialogVisible = true
            this.name = row.name ? row.name : row.nickName
            this.avatarUrl = row.avatarUrl
            this.actId = Number(row.id)
        },
        closeDig(data) {
            this.dialogVisible = data
            if (this.$route.path == '/activity/activityEdit') {
                this.$router.push({
                    path: 'release'
                })
                return
            }
            this.getActivity()
        }
    }
}