<template>
	<div class="p-10">
		<div class="flex justify-between text-xl font-bold">
			<div><i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>{{ type + "详情" }}</div>
			<div class="text-center">
				<el-button type="primary" class="w-32 text-white" @click="typeof id == 'undefined' ? addSkill() : updSkill()">保存</el-button>
				<el-button type="info" class="w-32 text-white" @click="toBack()">返回</el-button>
			</div>
		</div>
		<div class="flex ml-5 mt-6">
			<div class=" mt-5 font-bold text-xl">封面：</div>
			<div>
				<div class="ml-3 mt-5">
					<el-upload
						class="activity-uploader flex"
						ref="upload"
						action="#"
						:show-file-list="false"
						:multiple="false"
						:auto-upload="false"
						:on-change="imgSaveToUrl"
					>
						<i v-if="!fishSkillDetail.img" class="el-icon-camera-solid activity-uploader-icon"></i>
						<img v-else :src="fishSkillDetail.img" class="activity" />
					</el-upload>
				</div>
			</div>
		</div>
		<div class="flex ml-5 mt-10 text-xl items-center">
			<div class="font-bold">钓法钓技名称：</div>
			<div>
				<el-input v-model="fishSkillDetail.skill"></el-input>
			</div>
		</div>
		<div class="flex ml-5 mt-10 text-xl items-center">
			<div class="font-bold">标题：</div>
			<div>
				<el-input v-model="fishSkillDetail.title"></el-input>
			</div>
		</div>
		<div class="flex ml-5 mt-10 text-xl items-center">
			<div class="font-bold">时间：</div>
			<div>
				<el-date-picker
					v-model="fishSkillDetail.releaseTime"
					type="datetime"
					placeholder="选择日期时间"
					@change="changeData"
					value-format="yyyy-MM-dd HH:mm:ss"
				>
				</el-date-picker>
			</div>
		</div>
		<div class="flex ml-5 mt-10 items-center">
			<div class="text-xl font-bold">分类：</div>
			<div>
				<div>
					<el-cascader v-model="value" :options="data" :props="optionProps" @change="handleChange"></el-cascader>
				</div>
			</div>
		</div>
		<div class="text-xl font-bold mt-20"><i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>{{ type + "内容" }}</div>
		<!-- 编辑器 -->
		<wangEdit :content="midData" @getContent="getContent"></wangEdit>
	</div>
</template>

<script>
import wangEdit from "../../../components/wangEdit/wangEdit.vue"
export default {
	components: {
		wangEdit,
	},
	data() {
		return {
			id: this.$route.query.id,
			type: "钓法钓技",
			fishSkillDetail: {
				img: "",
				title: "",
				content: "",
				releaseTime: "",
				class: "",
				type: "",
				skill: "",
			},
			value: [],
			data: [],
			optionProps: {
				value: "class",
				label: "class",
				children: "children",
				expandTrigger: "hover",
			},
			midData: "",
		}
	},
	mounted() {
		this.getCourseDetails()
		this.getSkillClass()
	},
	methods: {
		//钓法&钓技详情
		async getCourseDetails() {
			if (typeof this.id === "undefined") {
				return
			}
			const res = await this.http
				.post(
					"admin/skill/getSkillDetails",
					{
						skillId: this.id,
					},
					false
				)
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				if (res.data.type == "0") {
					let type = "钓法"
					this.value.push(res.data.class, type)
				} else {
					let type = "钓技"
					this.value.push(res.data.class, type)
				}
				this.fishSkillDetail = res.data
				this.midData = res.data.content
			}
		},
		//获取钓法钓技分类
		async getSkillClass() {
			const res = await this.http.post("admin/skill/getSkillClass", {}, false).catch((err) => {
				console.log(err)
			})
			if (res.code == 1) {
				this.data = JSON.parse(JSON.stringify(res.data))
			}
		},
		// 图片上传
		async imgSaveToUrl(event) {
			if (this.beforeUploadPicture(event.raw)) {
				var formData = new FormData()
				formData.append("img", event.raw)
				this.http.upload("admin/upload/uploadImg", formData).then((res) => {
					if (res.code == 1) {
						this.fishSkillDetail.img = res.data
						this.$message.success("上传成功")
					}
				})
			}
		},
		// 图片上传之前
		beforeUploadPicture(file) {
			const isJPG = file.type === "image/jpeg"
			const isPNG = file.type === "image/png"
			const isLt10M = file.size / 1024 / 1024 < 10
			if (!isJPG && !isPNG) {
				this.$message.error("上传图片只能是 JPG 或者 PNG 格式!")
			}
			if (isJPG && isPNG) {
				if (!isLt10M) {
					this.$message.error("上传图片大小不能超过 10MB!")
				}
			}
			return (isJPG || isPNG) && isLt10M
		},
		//联级选择器
		handleChange(value) {
			console.log(value)
			this.fishSkillDetail.class = value[0]
			this.fishSkillDetail.type = value[1]
		},
		//获取富文本
		getContent(data) {
			this.fishSkillDetail.content = data
		},
		//新增钓法钓技
		async addSkill() {
			if (this.check.checkHasEmpty(this.fishSkillDetail)) {
				//调用函数checkHasEmpty() 检查必填字段是否有为空的
				this.$message("请将数据填写完整")
			} else {
				const res = await this.http
					.post("admin/skill/addSkill", {
						skill: this.fishSkillDetail.skill,
						img: this.fishSkillDetail.img,
						title: this.fishSkillDetail.title,
						content: this.fishSkillDetail.content,
						releaseTime: this.fishSkillDetail.releaseTime,
						type: this.fishSkillDetail.type,
						class: this.fishSkillDetail.class,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.$router.go(-1)
				}
			}
		},
		//编辑钓法&钓技内容
		async updSkill() {
			if (this.check.checkHasEmpty(this.fishSkillDetail)) {
				//调用函数checkHasEmpty() 检查必填字段是否有为空的
				this.$message("请将数据填写完整")
			} else {
				const res = await this.http
					.post("admin/skill/updSkill", {
						skillId: this.id,
						skill: this.fishSkillDetail.skill,
						img: this.fishSkillDetail.img,
						title: this.fishSkillDetail.title,
						content: this.fishSkillDetail.content,
						releaseTime: this.fishSkillDetail.releaseTime,
						type: this.fishSkillDetail.type,
						class: this.fishSkillDetail.class,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.$router.go(-1)
				}
			}
		},
		//选择日期
		changeData(event) {
			this.fishSkillDetail.releaseTime = event
		},
		//返回
		toBack() {
			this.$router.go(-1)
		},
	},
}
</script>

<style lang="scss" scoped>
/deep/.activity-uploader .el-upload {
	border: 1px dashed hsl(0, 0%, 85%);
	cursor: pointer;
	position: relative;
	overflow: hidden;
	background: rgba(241, 241, 241, 1);
}
/deep/.activity-uploader-icon {
	font-size: 75px;
	color: #8c939d;
	width: 150px; //上传图片的宽
	height: 150px; //上传图片的高
	line-height: 150px;
	text-align: center;
}
.activity {
	width: 150px; //图片回显的宽
	height: 150px; //图片回显的高
	display: block;
}
</style>
