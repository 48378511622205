<template>
	<div class="p-10">
		<div class="text-xl font-bold flex justify-between">
			<div><i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>工具详情</div>
			<div class="text-center">
				<el-button type="primary" class="w-32 text-white" @click="typeof toolId == 'undefined' ? addTool() : updTool()">保存</el-button>
				<el-button type="info" class="w-32 text-white" @click="toBack()">返回</el-button>
			</div>
		</div>
		<div class="flex ml-5 mt-6">
			<div class="mr-7 mt-5">工具图片</div>
			<div class="flex">
				<div style="text-align:right" v-for="(item, index) in imgArr" :key="index">
					<i class="el-icon-circle-close" @click="delImg(index)"></i>
					<img v-if="imgArr" :src="item" class="activity mr-4" />
				</div>
				<div class="ml-3 mt-5">
					<el-upload
						class="activity-uploader flex"
						ref="upload"
						action="#"
						:show-file-list="false"
						:multiple="false"
						:auto-upload="false"
						:on-change="imgSaveToUrl"
					>
						<i class="el-icon-camera-solid activity-uploader-icon"></i>
					</el-upload>
				</div>
			</div>
		</div>
		<div class="flex ml-5 mt-10" style="color: rgba(128, 128, 128, 1)">
			<div class="flex items-center">
				<div class="mr-5 font-bold">标题：</div>
				<div>
					<el-input v-model="form.cn" placeholder="请输入"></el-input>
				</div>
			</div>
			<div class="flex items-center ml-20">
				<div class="mr-5 font-bold">外文名</div>
				<div>
					<el-input v-model="form.en" placeholder="请输入" style="width: 400px"></el-input>
				</div>
			</div>
		</div>
		<div class="ml-5 mt-10 flex items-center" style="color: rgba(128, 128, 128, 1)">
			<div class="mr-5 font-bold">时间：</div>
			<div>
				<el-date-picker
					v-model="form.releaseTime"
					type="datetime"
					placeholder="选择日期时间"
					@change="changeData"
					value-format="yyyy-MM-dd HH:mm:ss"
				>
				</el-date-picker>
			</div>
		</div>
		<div class="flex ml-5 mt-10 items-center" style="color: rgba(128, 128, 128, 1)">
			<div class="mr-5 font-bold">分类：</div>
			<div>
				<div>
					<el-cascader v-model="value" :options="data" :props="optionProps" @change="handleChange"></el-cascader>
				</div>
			</div>
		</div>
		<div class="text-xl font-bold mt-10"><i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>内容</div>
		<wangEdit :content="midData" @getContent="getContent"></wangEdit>
	</div>
</template>

<script>
import wangEdit from "../../../components/wangEdit/wangEdit.vue"
export default {
	components: {
		wangEdit,
	},
	data() {
		return {
			toolId: this.$route.query.toolId,
			edit: false,
			class: this.$route.query.class,
			form: {
				img: [],
				cn: "",
				en: "",
				class: "",
				type: "",
				releaseTime: "",
				content: "",
			},
			imgArr: [],
			value: [],
			data: [],
			optionProps: {
				value: "class",
				label: "class",
				children: "children",
				expandTrigger: "hover",
			},
			midData: "",
		}
	},
	mounted() {
		this.getTool()
		this.getToolClass()
	},
	methods: {
		//获取富文本
		getContent(data) {
			this.form.content = data
		},
		//获取分类
		async getToolClass() {
			const res = await this.http.post("admin/tool/getToolClass", {}).catch((err) => {
				console.log(err)
			}, false)
			if (res.code == 1) {
				this.data = JSON.parse(JSON.stringify(res.data))
			}
		},
		// 图片上传
		async imgSaveToUrl(event) {
			if (this.beforeUploadPicture(event.raw)) {
				var formData = new FormData()
				formData.append("img", event.raw)
				this.http.upload("admin/upload/uploadImg", formData).then((res) => {
					if (res.code == 1) {
						this.imgArr.push(res.data)
						this.form.img = this.imgArr
						this.$message.success("上传成功")
					}
				})
			}
		},
		// 图片上传之前
		beforeUploadPicture(file) {
			const isJPG = file.type === "image/jpeg"
			const isPNG = file.type === "image/png"
			const isLt10M = file.size / 1024 / 1024 < 10
			if (!isJPG && !isPNG) {
				this.$message.error("上传图片只能是 JPG 或者 PNG 格式!")
			}
			if (isJPG && isPNG) {
				if (!isLt10M) {
					this.$message.error("上传图片大小不能超过 10MB!")
				}
			}
			return (isJPG || isPNG) && isLt10M
		},
		//删除图片数组
		delImg(index) {
			this.imgArr.splice(index, 1)
			console.log(this.imgArr)
		},
		//新建工具
		async addTool() {
			if (this.check.checkHasEmpty(this.form)) {
				//调用函数checkHasEmpty() 检查必填字段是否有为空的
				this.$message("请将数据填写完整")
			} else {
				const res = await this.http
					.post("admin/tool/addTool", {
						img: JSON.stringify(this.form.img),
						cn: this.form.cn,
						en: this.form.en,
						releaseTime: this.form.releaseTime,
						class: this.form.class,
						type: this.form.type,
						content: this.form.content,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.$router.go(-1)
				}
			}
		},
		//选择时间
		changeData(event) {
			this.form.releaseTime = event
			console.log(this.form.releaseTime)
		},
		//编辑工具
		async updTool() {
			if (this.check.checkHasEmpty(this.form)) {
				//调用函数checkHasEmpty() 检查必填字段是否有为空的
				this.$message("请将数据填写完整")
			} else {
				const res = await this.http
					.post("admin/tool/updTool", {
						toolId: this.toolId,
						img: JSON.stringify(this.form.img),
						cn: this.form.cn,
						en: this.form.en,
						releaseTime: this.form.releaseTime,
						class: this.form.class,
						type: this.form.type,
						content: this.form.content,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.$router.go(-1)
				}
			}
		},
		//获取工具详情
		async getTool() {
			if (typeof this.toolId == "undefined") {
				return
			}
			const res = await this.http
				.post(
					"admin/tool/getToolDetails",
					{
						toolId: this.toolId,
					},
					false
				)
				.catch((err) => {
					console.log(err)
				})
			this.form = res.data
			this.midData = res.data.content
			this.value.push(res.data.class, res.data.type)
			if (Array.isArray(res.data.img)) {
				this.imgArr = res.data.img
			} else {
				let a = [res.data.img]
				this.imgArr = a
			}
		},
		//联级选择器
		handleChange(value) {
			console.log(value)
			this.form.class = value[0]
			this.form.type = value[1]
		},
		//返回
		toBack() {
			this.$router.go(-1)
		},
	},
}
</script>

<style lang="scss" scoped>
/deep/.activity-uploader .el-upload {
	border: 1px dashed hsl(0, 0%, 85%);
	cursor: pointer;
	position: relative;
	overflow: hidden;
	background: rgba(241, 241, 241, 1);
}
/deep/.activity-uploader-icon {
	font-size: 75px;
	color: #8c939d;
	width: 150px; //上传图片的宽
	height: 150px; //上传图片的高
	line-height: 150px;
	text-align: center;
}
.activity {
	width: 150px; //图片回显的宽
	height: 150px; //图片回显的高
	display: block;
}
</style>
