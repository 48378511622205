<template>
	<div class="p-10">
		<div class="flex justify-between text-xl font-bold">
			<div><i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>公告详情</div>
			<div class="text-center">
				<el-button
					type="primary"
					class="w-32 text-white"
					@click="typeof id == 'undefined' ? addNotice() : updNotice()"
					>保存</el-button
				>
				<el-button type="info" class="w-32 text-white" @click="toBack()">返回</el-button>
			</div>
		</div>

		<div class="flex ml-5 mt-20 text-xl items-center">
			<div class="font-bold">公告标题：</div>
			<div><el-input v-model="courseDetail.title"></el-input></div>
		</div>
		<div class="flex ml-5 mt-20 text-xl items-center">
			<div class="font-bold">时间：</div>
			<div>
				<el-date-picker
					v-model="courseDetail.updateTime"
					type="datetime"
					placeholder="选择日期时间"
					@change="changeData"
					value-format="yyyy-MM-dd HH:mm:ss"
				>
				</el-date-picker>
			</div>
		</div>
		<div class="text-xl font-bold mt-20">
			<i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>公告内容
		</div>
		<wangEdit :content="midData" @getContent="getContent"></wangEdit>
	</div>
</template>

<script>
import wangEdit from "../../components/wangEdit/wangEdit.vue"
export default {
	components: {
		wangEdit,
	},
	data() {
		return {
			id: this.$route.query.id,
			courseDetail: {
				title: "",
				content: "",
				updateTime: "",
			},
			midData: "",
		}
	},
	mounted() {
		this.getNoticeDet()
	},
	methods: {
		//公告详情
		async getNoticeDet() {
			if (typeof this.id === "undefined") {
				return
			}
			const res = await this.http
				.post("admin/notice/getNoticeDet", {
					noticeId: this.id,
				})
				.catch((err) => {
					console.log(err)
				})
			this.courseDetail = res.data
			this.midData = res.data.content
		},
		//获取富文本
		getContent(data) {
			this.courseDetail.content = data
		},
		//编辑公告
		async updNotice() {
			if (this.check.checkHasEmpty(this.courseDetail)) {
				//调用函数checkHasEmpty() 检查必填字段是否有为空的
				this.$message("请将数据填写完整")
			} else {
				const res = await this.http
					.post("admin/notice/updNotice", {
						noticeId: this.id,
						title: this.courseDetail.title,
						content: this.courseDetail.content,
						updateTime: this.courseDetail.updateTime,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.$router.push({
						path: "inform",
					})
				}
			}
		},
		//新增公告
		async addNotice() {
			if (this.check.checkHasEmpty(this.courseDetail)) {
				//调用函数checkHasEmpty() 检查必填字段是否有为空的
				this.$message("请将数据填写完整")
			} else {
				const res = await this.http
					.post("admin/notice/addNotice", {
						title: this.courseDetail.title,
						content: this.courseDetail.content,
						updateTime: this.courseDetail.updateTime,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.$router.push({
						path: "inform",
					})
				}
			}
		},
		//选择日期
		changeData(event) {
			this.courseDetail.updateTime = event
		},
		//返回
		toBack() {
			this.$router.push({
				path: "inform",
			})
		},
	},
}
</script>

<style lang="scss" scoped></style>
