<template>
	<div class="index">
		<Nav ref="nav"></Nav>
		<Header class="header"></Header>
		<div id="content" class="content">
			<keep-alive>
				<router-view v-if="$route.meta.keepAlive"> </router-view>
			</keep-alive>
			<router-view v-if="!$route.meta.keepAlive" />
		</div>
	</div>
</template>

<script>
import Nav from "@/components/Nav"
import Header from "@/components/Header"
export default {
	components: {
		Nav,
		Header,
	},
	data() {
		return {}
	},
	created() {
		document.addEventListener("keydown", (e) => {
			if (e.path[0].outerText == "删除") {
				e.preventDefault()
			}
			// let key = window.event.keyCode
			// if (key === 13) {
			// 	return false
			// }
		})
	},
	methods: {},
}
</script>

<style scoped>
.index {
	background: white;
}
/* 导航栏样式 */
.header {
	position: fixed !important;
}
/* router-view样式 */
.content {
	position: relative;
	top: 100px;
	margin-left: 230px;
}
</style>
