import axios from "axios"
import qs from "qs"
import NProgress from "nprogress"
import { Message } from "element-ui"



axios.defaults.baseURL = "/api"
axios.defaults.timeout = 10000
axios.defaults.headers.post["Content-Type"] =
  "application/json;charset=UTF-8"
axios.interceptors.request.use(
  (config) => {
    var token = window.sessionStorage.getItem(
      "Authorization"
    )
    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  (error) => {
    return Promise.error(error)
  }
)

axios.interceptors.response.use((res) => {
  if (res.data.code === 3001) {
    Message({
      message: "权限不足",
      type: "error",
    })
  }
  return res
})

var uploadRequest = axios.create({
  baseURL: "/api",
  timeout: 10000,
  headers: { "Content-Type": "multipart/form-data" },
})


uploadRequest.interceptors.request.use(
  (config) => {
    var token = window.sessionStorage.getItem(
      "Authorization"
    )
    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  (error) => {
    return Promise.error(error)
  }
)
var http = {
  get(url, params) {
    return new Promise((resolve, reject) => {
      NProgress.start()
      axios
        .get(url, {
          params: params,
        })
        .then((res) => {
          console
          NProgress.done()
          if (res.status === 200 && url === "/login") {
            window.sessionStorage.setItem(
              "Authorization",
              res.headers.token
            )
          }

          resolve(res.data)
        })
        .catch((err) => {
          NProgress.done()
          reject(err.data)
        })
    })
  },
  post(url, params, message = true) {
    return new Promise((resolve, reject) => {
      NProgress.start()
      axios
        .post(url, JSON.stringify(params))
        .then((res) => {
          NProgress.done()
          if (message) {
            Message({
              message: res.data.message,
              type:
                res.data.code === 1 ? "success" : "error",
            })
          }
          resolve(res.data)
        })
        .catch((err) => {
          NProgress.done()
          reject(err.data)
        })
    })
  },
  upload(url, file) {
    return new Promise((resolve, reject) => {
      NProgress.start()
      uploadRequest
        .post(url, file)
        .then((res) => {
          NProgress.done()
          resolve(res.data)
        })
        .catch((err) => {
          NProgress.done()
          reject(err.data)
        })
    })
  },
  download(url) {
    let iframe = document.createElement("iframe")
    iframe.style.display = "none"
    iframe.src = url
    iframe.onload = function () {
      document.body.removeChild(iframe)
    }
    document.body.appendChild(iframe)
  },
  install(Vue) {
    Vue.prototype.http = this
    delete this.install
  },
}
export default http
