<template>
	<div>
		<div class="header">
			<div class="flex justify-between items-center">
				<div>
					<i class="el-icon-s-fold text-4xl" style="margin-left: 10px"></i>
				</div>
				<div class="flex justify-center items-center" style="height: 60px; margin-right: 30px">
					<!-- 头像  -->
					<el-dropdown trigger="click">
						<span class="el-dropdown-link">
							<el-avatar :src="imgUrl"></el-avatar>
							<i class="el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item icon="el-icon-circle-check">
								<span @click="logout()">退出登录</span>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>

					<div class="truncate mr-1" style="font-size: 12px; width: 100px">
						{{ name }}
					</div>
				</div>
			</div>
			<tag-view></tag-view>
		</div>
	</div>
</template>
<script>
import tagView from "../components/tagView.vue"
export default {
	components: {
		tagView,
	},
	data() {
		return {
			imgUrl: this.$store.state.user.imgUrl,
			value: 0, //消息条数
			name: this.$store.state.user.username, //this.$store.state.user.name
		}
	},
	// watch: {
	//   "$store.state.user.name": {
	//     handler() {
	//       this.name = this.$store.state.user.name;
	//     },
	//   },
	//   "$store.state.user.imgUrl": {
	//     handler() {
	//       this.imgUrl = this.$store.state.user.imgUrl;
	//     },
	//   },
	// },
	destroyed() {},
	mounted() {},
	methods: {
		// 退出登录
		logout() {
			sessionStorage.clear()
			this.$router.push({ name: "login" })
		},
		update() {
			// this.$router.push("update");
		},
	},
}
</script>
<style lang="scss" scoped>
.header {
	/* position: fixed; */
	z-index: 100;
	left: 230px;
	top: 0;
	bottom: 0;
	right: 0;
	height: 60px;
	background: white;
	border-left: 1px solid rgb(226, 245, 243);
	border-bottom: 1px solid rgb(226, 245, 243);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
/deep/.el-button--small.is-circle {
	padding: 0px;
	border: none;
}

/deep/.el-table td,
.el-table th {
	text-align: center;
}
/deep/.el-table th {
	text-align: center;
	background: rgb(236, 235, 235);
}
</style>
