import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    user: {
      username: "",
      password: "",
      id: '',
      imgUrl: '',
    },
    node:{
      class:'',
      type:'',
      treeId:''
    },
    visitedView:[{
      name: "userdata",fullPath: "/statistics/userdata",path: "/statistics/userdata",meta:{keepAlive:false,title:'首页'},query: '' }]
  },
  mutations: {
    // 改变登陆状态 1 为登录  0未登录
    changeLogin(state, status) {
      state.token = status
    },
    //存储用户信息到本地  具体看需求
    setUser(state, user) {
      state.user.username = user.name
      state.user.password = user.password
      state.user.id = user.id
      state.user.imgUrl = user.avatarUrl
    },
    //储存列表页面数据
    setNode(state,node){
      state.node.class = node.class
      state.node.type = node.type
      state.node.treeId = node.treeId
    },
    //用户访问过的页面
    addVisitedView(state,view){
      if(state.visitedView.some(v=>v.path===view.path)) return
      state.visitedView.push(view)
    },
    //关闭用户访问过的页面
    delVisitedView(state,view){
      for (const [i, v] of state.visitedView.entries()) {
        if (v.path === view.path) {
          state.visitedView.splice(i, 1)
          break
        }
      }
    }
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage, //可以改成localStore
      reducer(val) {
        //设置想要持久化保存的数据
        return {
          user: val.user,
        }
      },
    }),
  ],
})