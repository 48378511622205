<template>
	<div id="hisFir" class="h-72 w-full" style="height:250px"></div>
</template>

<script>
export default {
	props: {
		monthOnline: Array,
	},
	data() {
		return {
			xData: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"], //X周数据
			seriesData: [0, 2000, 4000, 6000, 8000, 10000], //y轴数据
		}
	},
	watch: {
		monthOnline: {
			handler() {
				this.drawLine()
			},
		},
	},
	mounted() {
		this.drawLine()
	},
	methods: {
		drawLine() {
			var chartDom = document.getElementById("hisFir")
			var myChart = this.$echarts.init(chartDom)
			var option = {
				color: "rgb(60, 144, 247)",
				xAxis: {
					type: "category",
					data: this.xData,
				},
				tooltip: {},
				yAxis: {
					data: this.seriesData,
					type: "value",
				},
				legend: {
					data: ["当月在线人数"],
					bottom: "bottom",
				},
				grid: {
					top: "8%",
					left: "4%",
				},
				series: [
					{
						name: "当月在线人数",
						data: this.monthOnline,
						type: "bar",
					},
				],
			}
			myChart.setOption(option)
			window.addEventListener("resize", () => {
				myChart.resize()
			})
		},
	},
}
</script>

<style></style>
