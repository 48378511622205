<template>
	<div class="pagination">
		<div class="flex justify-end mr-12">
			<div v-if="isShow" class=" flex mt-12 items-center">
				<div class=" w-10">显示</div>
				<el-input
					v-model="limit"
					style="width: 100px;"
					@change="handleSizeChange(limit)"
					type="number"
					onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
				></el-input>
				<div class="ml-2 w-16">页/条</div>
			</div>
			<el-pagination
				background
				@current-change="handleCurrentChange"
				@size-change="handleSizeChange"
				:page-sizes="pageSizesArr"
				:total="total"
				:current-page="currentPage"
				:page-size="page._pageSize"
				:layout="layout"
			></el-pagination>
		</div>
	</div>
</template>

<script>
export default {
	name: "Pagination",
	props: {
		// 总页数
		total: {
			type: Number,
			default: 0,
		},
		// 当前页
		currentPage: {
			type: Number,
			default: 1,
		},
		// 每页显示条数
		pageSize: {
			type: Number,
			default: 5,
		},
		pageSizesArr: {
			type: Array,
			default() {
				return [10, 20, 30, 40]
			},
		},
		layout: {
			type: String,
			default: "prev, pager, next, jumper",
		},
		isShow: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			page: {
				_pageSize: this.pageSize,
				_currentPage: this.currentPage,
				_pageNum: Math.ceil(Number(this.total) / Number(this.pageSize)),
			},
			limit: this.pageSize,
		}
	},
	methods: {
		//  每页查看条数变化
		handleSizeChange(val) {
			if (!Number(val)) {
				this.$message({
					message: "请输入数字",
					type: "warning",
				})
			} else {
				this.page._currentPage = 1
				this.page._pageSize = Number(val)
				this.$emit("pageChange", this.page)
			}
		},
		// 当前页码变化
		handleCurrentChange(val) {
			this.page._currentPage = val
			this.$emit("pageChange", this.page)
		},
	},
}
</script>
<style lang="scss" scoped>
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
	background-color: white;
	color: #409eff;
	border: 1px solid #409eff;
}
/deep/.el-pagination.is-background .btn-next,
.el-pagination.is-background .el-pager li {
	background-color: white;
}
/deep/.el-pagination.is-background .btn-prev {
	background-color: white;
}
/deep/.el-pagination {
	margin-top: 3rem;
}
/deep/.el-input__inner {
	height: 28px;
	text-align: center;
}
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
}
/deep/ input[type="number"] {
	-moz-appearance: textfield !important;
}
</style>
