<template>
	<div class="nav">
		<div class="openMenu">
			<img style="width: 30px; height: 30px; margin-right: 10px" src="../assets/img/logo1.png" />
			<h4 class="flex align-center" style="color: rgba(36, 123, 255, 1); font-weight: bold;font-size:20px">
				后台管理系统
			</h4>
		</div>
		<div class="scorllBox">
			<el-scrollbar style="height: 100%">
				<el-menu
					:router="true"
					class="el-menu-vertical-demo"
					@open="handleOpen"
					@close="handleClose"
					text-color="#000000"
					:default-active="$route.path"
					active-text-color="#247BFF"
					:collapse="isCollapse"
				>
					<el-menu-item :index="navList[0].name">
						<i :class="navList[0].icon"></i>
						<span slot="title">{{ navList[0].label }}</span>
					</el-menu-item>

					<el-submenu :index="navList[1].name">
						<template slot="title">
							<i :class="navList[1].icon"></i>
							<span slot="title">{{ navList[1].label }}</span>
						</template>
						<el-menu-item v-for="(sec, j) in navList[1].children" :key="j + 100" :index="sec.name">{{ sec.label }}</el-menu-item>
					</el-submenu>

					<el-submenu :index="navList[2].name">
						<template slot="title">
							<i :class="navList[2].icon"></i>
							<span slot="title">{{ navList[2].label }}</span>
						</template>
						<el-menu-item v-for="(sec, j) in navList[2].children" :key="j + 100" :index="sec.name">{{ sec.label }}</el-menu-item>
					</el-submenu>

					<el-submenu :index="navList[3].name">
						<template slot="title">
							<i :class="navList[3].icon"></i>
							<span slot="title">{{ navList[3].label }}</span>
						</template>
						<el-menu-item v-for="(sec, j) in navList[3].children" :key="j + 100" :index="sec.name">{{ sec.label }}</el-menu-item>
					</el-submenu>

					<el-submenu :index="navList[4].name">
						<template slot="title">
							<i :class="navList[4].icon"></i>
							<span slot="title">{{ navList[4].label }}</span>
						</template>
						<el-menu-item v-for="(sec, j) in navList[4].children" :key="j + 100" :index="sec.name">{{ sec.label }}</el-menu-item>
					</el-submenu>

					<el-submenu :index="navList[5].name">
						<template slot="title">
							<i :class="navList[5].icon"></i>
							<span slot="title">{{ navList[5].label }}</span>
						</template>
						<el-menu-item v-for="(sec, j) in navList[5].children" :key="j + 100" :index="sec.name">{{ sec.label }}</el-menu-item>
					</el-submenu>

					<el-menu-item :index="navList[6].name">
						<i :class="navList[6].icon"></i>
						<span slot="title">{{ navList[6].label }}</span>
					</el-menu-item>

					<el-submenu :index="navList[7].name">
						<template slot="title">
							<i :class="navList[7].icon"></i>
							<span slot="title">{{ navList[7].label }}</span>
						</template>
						<el-menu-item v-for="(sec, j) in navList[7].children" :key="j + 100" :index="sec.name">{{ sec.label }}</el-menu-item>
					</el-submenu>
				</el-menu>
			</el-scrollbar>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isCollapse: false,
			navList: [
				{
					name: "/statistics/userdata",
					label: "首页",
					icon: "el-icon-s-home",
					isAuthority: true, //此路由是否要权限
				},
				//账号管理
				{
					name: "/account",
					label: "账号管理",
					icon: "el-icon-user-solid",
					isAuthority: false,
					children: [
						{
							name: "/account/accountAdmin",
							label: "管理员管理",
							hasAuthority: true,
						},
						{
							name: "/account/accountNumber",
							label: "账号管理",
							hasAuthority: true,
						},
					],
				},
				//活动管理
				{
					name: "/activity",
					label: "活动管理",
					icon: "el-icon-date",
					isAuthority: false,
					children: [
						{
							name: "/activity/release",
							label: "活动管理",
							hasAuthority: true,
						},
						{
							name: "/activity/activityExamine",
							label: "活动审核",
							hasAuthority: true,
						},
					],
				},
				//渔获管理
				{
					name: "/fishCatch",
					label: "渔获管理",
					icon: "el-icon-c-scale-to-original",
					isAuthority: false,
					children: [
						{
							name: "/fishCatch/fishCatch",
							label: "渔获管理",
							hasAuthority: true,
						},
						{
							name: "/fishCatch/catchExamine",
							label: "渔获审核",
							hasAuthority: true,
						},
					],
				},
				//技巧管理
				{
					name: "/course",
					label: "技巧管理",
					icon: "el-icon-s-ticket",
					isAuthority: false,
					children: [
						{
							name: "/course/Novice",
							label: "钓鱼技巧",
							hasAuthority: true,
						},
						{
							name: "/course/fishingMethods",
							label: "钓法钓技",
							hasAuthority: true,
						},
						// {
						// 	name: "/course/fishingSkill",
						// 	label: "钓技管理",
						// 	hasAuthority: true,
						// },
						{
							name: "/course/fishType",
							label: "鱼类型管理",
							hasAuthority: true,
						},
						{
							name: "/course/fishingTools",
							label: "工具装备",
							hasAuthority: true,
						},
					],
				},
				//鱼币商城
				{
					name: "/shop",
					label: "鱼币商城管理",
					icon: "el-icon-s-shop",
					isAuthority: false,
					children: [
						{
							name: "/shop/fishShop",
							label: "鱼币商城管理",
							hasAuthority: true,
						},
						{
							name: "/shop/forRecord",
							label: "兑换记录",
							hasAuthority: true,
						},
					],
				},
				//任务管理
				{
					name: "/task/task",
					label: "任务管理",
					icon: "el-icon-s-operation",
					isAuthority: false,
				},
				//其他
				{
					name: "/system",
					label: "其他",
					icon: "el-icon-set-up",
					isAuthority: false,
					children: [
						{
							name: "/system/list",
							label: "榜单管理",
							hasAuthority: true,
						},
						{
							name: "/system/adminQrcode",
							label: "管理员群聊管理",
							hasAuthority: true,
						},
						{
							name: "/system/groupChat",
							label: "本地群聊管理",
							hasAuthority: true,
						},
						{
							name: "/system/helpCenter",
							label: "帮助中心管理",
							hasAuthority: true,
						},
						{
							name: "/system/poster",
							label: "海报管理",
							hasAuthority: true,
						},
						{
							name: "/system/introduction",
							label: "鱼塘攻略",
							hasAuthority: true,
						},
						{
							name: "/system/fishMethod",
							label: "钓法管理",
							hasAuthority: true,
						},
						{
							name: "/system/inform",
							label: "公告通知",
							hasAuthority: true,
						},
					],
				},
			],
		}
	},
	computed: {},
	mounted() {},
	methods: {
		handleOpen(key, keyPath) {
			// console.log(key, keyPath);
		},
		handleClose(key, keyPath) {
			// console.log(key, keyPath)
		},
	},
}
</script>

<style lang="scss" scoped>
.nav {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
}

.openMenu {
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 230px;
	background: white;
	box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.5);
	position: relative;
	z-index: 99;
	// margin-bottom: 8px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
	width: 230px;
	min-height: 400px;
}

.el-menu {
	width: 230px;
	height: calc(100vh - #{60px});
}
.el-menu-item {
	color: rgba(36, 123, 255, 1);
}
li.el-menu-item.is-active {
	color: rgba(36, 123, 255, 1);
	background-color: #e5e5e5 !important;
}
/deep/.el-submenu__title {
	color: white;
}
/deep/ .el-scrollbar__wrap {
	// 必要的
	overflow: scroll;
	width: 110%; //隐藏侧边默认滚动条 （达不到效果可以自己试着微调） 如解决不了可尝试用 `margin-right -60px`
	height: 96%; //隐藏底部滚动条  （100%如果达不到效果可以试着用110%）
}
.scorllBox {
	height: 93.5vh;
}
</style>
