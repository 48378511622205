<template>
	<div class="p-10">
		<div class="text-xl font-bold"><i class="cuIcon-titles" style="color:rgba(42, 130, 228, 1)"></i>榜单管理</div>
		<div class="mt-5 flex">
			<div class="mr-5">
				<el-input v-model="input" placeholder="搜索名称" style="width:170px"></el-input>
			</div>
			<div>
				<el-select v-model="value" placeholder="请选择" @change="changeValue">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
				</el-select>
			</div>
			<div class="search ml-4 mt-1 cursor-pointer">
				<i class="cuIcon-search" @click="search()"></i>
			</div>
			<div class="search ml-4 mt-1 text-white cursor-pointer" style="background:rgba(117, 199, 77, 1)" @click="reset()">
				<i class="el-icon-refresh"></i>
			</div>
		</div>
		<div class="mt-5 border-2">
			<el-table :data="tableData" stripe :header-cell-style="{ background: '#fafafa' }" style="width: 100%">
				<el-table-column label="用户名称">
					<template slot-scope="scope">{{ scope.row.name ? scope.row.name : scope.row.nickName }}</template>
				</el-table-column>
				<el-table-column label="榜单类型">{{ value }}</el-table-column>
				<el-table-column label="排名">
					<template slot-scope="scope">{{ isSearch ? scope.row.rank : pages * limit - (limit - 1 - scope.$index) }}</template>
				</el-table-column>
				<el-table-column :label="value" :prop="rankData[value]"> </el-table-column>
				<el-table-column label="操作" width="150">
					<template slot-scope="scope">
						<el-button style="text-decoration:underline" @click="toUserDetail(scope.row)" type="text">详情</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<pagination :currentPage="pages" :total="total" @pageChange="changePages"></pagination>
	</div>
</template>

<script>
import pagination from "../../components/Pagination/pagination.vue"
export default {
	components: { pagination },
	data() {
		return {
			input: "",
			tableData: [],
			total: 0, //总页数
			pages: 1, //当前页数
			limit: 5,
			options: [
				{
					value: "任务",
					label: "任务排行榜",
				},
				{
					value: "等级",
					label: "等级排行榜",
				},
				{
					value: "粉丝",
					label: "粉丝排行榜",
				},
				{
					value: "鱼币",
					label: "鱼币排行榜",
				},
			],
			rankData: {
				任务: "taskNum",
				等级: "level",
				粉丝: "fansNum",
				鱼币: "coin",
			},
			value: "任务",
			isSearch: false,
		}
	},
	mounted() {
		this.getTaskRank()
	},
	methods: {
		//分页
		changePages(data) {
			this.pages = data._currentPage
			this.limit = data._pageSize
			if (!this.input) {
				switch (this.value) {
					case "任务":
						this.getTaskRank()
						break
					case "等级":
						this.getLevelRank()
						break
					case "粉丝":
						this.getFansRank()
						break
					default:
						this.getCoinRank()
						break
				}
			} else {
				this.search()
			}
		},
		changeValue() {
			this.pages = 1
			if (!this.input) {
				switch (this.value) {
					case "任务":
						this.getTaskRank()
						break
					case "等级":
						this.getLevelRank()
						break
					case "粉丝":
						this.getFansRank()
						break
					default:
						this.getCoinRank()
						break
				}
			} else {
				this.searchUser()
			}
		},
		//搜索
		search() {
			this.pages = 1
			if (!this.input) {
				switch (this.value) {
					case "任务":
						this.getTaskRank()
						break
					case "等级":
						this.getLevelRank()
						break
					case "粉丝":
						this.getFansRank()
						break
					default:
						this.getCoinRank()
						break
				}
			} else {
				this.searchUser()
			}
		},
		//搜索用户
		async searchUser() {
			const res = await this.http
				.post("admin/rank/searchUser", {
					name: this.input,
					type: this.value,
					pages: this.pages,
					limit: this.limit,
				})
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.user
				this.total = res.data.total
				this.isSearch = true
			}
		},
		//重置
		reset() {
			this.input = ""
			this.pages = 1
			this.search()
		},
		//获取任务排行榜
		async getTaskRank() {
			const res = await this.http
				.post(
					"admin/rank/getTaskRank",
					{
						pages: this.pages,
						limit: this.limit,
					},
					false
				)
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.rank
				this.total = res.data.total
				if (!this.input) {
					this.isSearch = false
				}
			}
		},
		//获取等级排行榜
		async getLevelRank() {
			const res = await this.http
				.post("admin/rank/getLevelRank", {
					pages: this.pages,
					limit: this.limit,
				})
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.rank
				this.total = res.data.total
				if (!this.input) {
					this.isSearch = false
				}
			}
		},
		//获取粉丝排行榜
		async getFansRank() {
			const res = await this.http
				.post("admin/rank/getFansRank", {
					pages: this.pages,
					limit: this.limit,
				})
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.rank
				this.total = res.data.total
				if (!this.input) {
					this.isSearch = false
				}
			}
		},
		//获取鱼币排行榜
		async getCoinRank() {
			const res = await this.http
				.post("admin/rank/getCoinRank", {
					pages: this.pages,
					limit: this.limit,
				})
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.tableData = res.data.rank
				this.total = res.data.total
				if (!this.input) {
					this.isSearch = false
				}
			}
		},
		//跳转详情
		toUserDetail(row) {
			this.$router.push({
				name: "userDetail",
				query: { id: row.id },
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.search {
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	border-radius: 50%;
	border: rgb(175, 174, 174) solid 1px;
}
</style>
