<template>
	<div class="p-10">
		<div class="text-xl font-bold"><i class="cuIcon-titles" style="color: rgba(42, 130, 228, 1)"></i>工具装备类型</div>

		<div class="flex items-baseline">
			<div class="pr-10" style="border-right:rgba(200, 200, 200, 1) solid 1px;height:650px;width:250px">
				<div class=" flex justify-between items-center mt-5 mb-3 ml-5">
					<div><p class=" font-bold text-xl" style="color:rgba(80, 80, 80, 1)">分类</p></div>
					<div><i class="el-icon-circle-plus text-xl cursor-pointer" @click=";(isClass = 2), addClass()"></i></div>
				</div>
				<el-tree ref="treeBox" :data="data" node-key="id" default-expand-all :expand-on-click-node="true" :highlight-current="true">
					<span
						class="custom-tree-node"
						slot-scope="{ node, data }"
						style="width:150px;font-size:18px"
						@click="data.children ? '' : getTool(node)"
					>
						<span v-if="data" style="margin-top:10px;">{{ data.class }}</span>
						<span style="float:right" @click.stop>
							<el-dropdown trigger="click" size="mini" placement="bottom-start">
								<i class="el-icon-more"></i>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item v-if="data.children" @click.native=";(isClass = 3), addClass(data)"
										>新增子分类</el-dropdown-item
									>
									<el-dropdown-item
										@click.native="data.children ? ((isClass = 2), updClass(data)) : ((isClass = 3), updThreeClass(node))"
										>编辑分类</el-dropdown-item
									>
									<el-dropdown-item @click.native="data.children ? delToolClass(data) : delToolType(node)"
										>删除分类</el-dropdown-item
									>
									<el-dropdown-item>取消</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</span>
					</span>
				</el-tree>
			</div>
			<div class="flex-auto ml-5">
				<div>
					<el-breadcrumb separator-class="el-icon-arrow-right" style="font-size:18px;">
						<el-breadcrumb-item>全部</el-breadcrumb-item>
						<el-breadcrumb-item>工具装备</el-breadcrumb-item>
						<el-breadcrumb-item>{{ !isSearch ? this.class : "搜索" }}</el-breadcrumb-item>
						<el-breadcrumb-item v-if="!isSearch">{{ this.type }}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="mt-5 flex">
					<div>
						<el-input v-model="input" placeholder="搜索名称" style="width: 170px"></el-input>
					</div>
					<div class="search ml-4 mt-1 cursor-pointer" @click="clickSearch()">
						<i class="cuIcon-search"></i>
					</div>
					<div class="search ml-4 mt-1 text-white cursor-pointer" style="background: rgba(117, 199, 77, 1)" @click="reset()">
						<i class="el-icon-refresh"></i>
					</div>
					<div class="search ml-4 mt-1 bg-blue-500 text-white cursor-pointer" @click="toToolDetail()">
						<i class="cuIcon-add"></i>
					</div>
				</div>
				<div class="mt-5 border-2">
					<el-table :data="tableData" stripe :header-cell-style="{ background: '#fafafa' }" style="width: 100%">
						<el-table-column label="工具图片">
							<template slot-scope="scope">
								<el-image :src="scope.row.img" class="w-16 h-16" fit="fit"></el-image>
							</template>
						</el-table-column>
						<el-table-column prop="cn" label="名称"></el-table-column>
						<el-table-column prop="content" label="工具简介">
							<template slot-scope="scope">{{ scope.row.content | getText }}</template>
						</el-table-column>
						<el-table-column prop="createTime" label="创建时间"></el-table-column>
						<el-table-column label="操作" width="150">
							<template slot-scope="scope">
								<el-button style="text-decoration: underline" @click="handleClick(scope.row)" type="text">编辑</el-button>
								<el-button style="text-decoration: underline; color: red" type="text" @click="delTool(scope.row)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<pagination :currentPage="pages" :total="total" @pageChange="changePages"></pagination>
			</div>
		</div>
		<!-- 新增对话框 -->
		<el-dialog :visible.sync="dialog" width="400px">
			<el-form>
				<el-form-item :label="isClass == 2 ? '二级分类名称' : '三级分类名称'" label-width="100px">
					<el-input v-model="formClass" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div class="mt-5 text-center">
				<el-button size="small" class="mr-10" @click="dialog = false">取消</el-button>
				<el-button type="primary" size="small" @click="isClass == 2 ? addToolClass() : addToolType()">确定</el-button>
			</div>
		</el-dialog>

		<!-- 编辑对话框 -->
		<el-dialog :visible.sync="dialog1" width="400px" :close-on-click-modal="false">
			<el-form>
				<el-form-item :label="isClass == 2 ? '二级分类名称' : '三级分类名称'" label-width="100px">
					<el-input v-model="formClass" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div class="mt-5 text-center">
				<el-button size="small" class="mr-10" @click="dialog1 = false">取消</el-button>
				<el-button type="primary" size="small" @click="isClass == 2 ? updToolClass() : updToolType()">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import pagination from "../../../components/Pagination/pagination.vue"
export default {
	components: { pagination },
	data() {
		return {
			input: "",
			tableData: [],
			dialog: false,
			dialog1: false,
			total: 0, //总页数
			pages: 1, //当前页数
			limit: 5,
			data: [],
			formClass: "",
			formData: "",
			isClass: 2,
			class: "",
			type: "",
			parentClass: "",
			isSearch: false,
			treeId: "",
		}
	},
	filters: {
		getText(val) {
			var re1 = new RegExp("<.+?>", "g") //匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
			var msg = val.replace(re1, "") //执行替换成空字符
			return msg
		},
	},
	activated() {
		if (!this.value) {
			this.getToolClass()
		} else {
			this.searchTool()
		}
	},
	mounted() {
		this.getToolClass()
	},
	methods: {
		//分页
		changePages(data) {
			this.pages = data._currentPage
			if (!this.value) {
				this.getToolClass()
			} else {
				this.searchTool()
			}
		},
		//点击查询
		clickSearch() {
			this.pages = 1
			console.log(!this.input)
			if (this.input == "") {
				this.getToolClass()
			} else {
				this.searchTool()
			}
		},
		//点击重置
		reset() {
			this.input = ""
			this.pages = 1
			this.getToolClass()
		},
		//搜索工具
		async searchTool() {
			const res = await this.http
				.post("admin/tool/searchTool", {
					keyword: this.input,
					pages: this.pages,
					limit: this.limit,
				})
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.isSearch = true
				this.tableData = res.data.arr
				this.total = res.data.total
			}
		},
		//新增工具
		toToolDetail() {
			this.$router.push({
				path: "toolDetail",
			})
		},
		//获取工具装备分类
		async getToolClass() {
			const res = await this.http.post("admin/tool/getToolClass", {}, false).catch((err) => {
				console.log(err)
			})
			if (res.code == 1) {
				this.data = JSON.parse(JSON.stringify(res.data))
				if (this.treeId == "") {
					this.class = this.data[0].class
					this.type = this.data[0].children[0].class
					this.treeId = this.data[0].children[0].id
				}
				this.getTool()
			}
		},
		//新增分类
		addClass(data) {
			this.formClass = ""
			this.dialog = true
			if (data) {
				this.formData = data
			}
		},

		//打开编辑二级分类
		updClass(data) {
			console.log(data)
			this.formClass = data.class
			this.dialog1 = true
			if (data) {
				this.formData = data
			}
		},
		//打开编辑三级分类
		updThreeClass(node) {
			this.formClass = node.data.class
			this.parentClass = node.parent.data.class
			this.dialog1 = true
			if (node) {
				this.formData = node.data
			}
		},

		//新增二级分类
		async addToolClass() {
			if (this.formClass.length > 5) {
				this.$message("字数不能超过5个字！")
			} else {
				const res = await this.http
					.post("admin/tool/addToolClass", {
						class: this.formClass,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.getToolClass()
					this.dialog = false
				}
			}
		},

		//新增三级分类
		async addToolType() {
			if (this.formClass.length > 5) {
				this.$message("字数不能超过5个字！")
			} else {
				const res = await this.http
					.post("admin/tool/addToolType", {
						class: this.formData.class,
						type: this.formClass,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.getToolClass()
					this.dialog = false
				}
			}
		},

		//编辑二级分类
		async updToolClass() {
			if (this.formClass.length > 5) {
				this.$message("字数不能超过5个字！")
			} else {
				const res = await this.http
					.post("admin/tool/updToolClass", {
						classId: this.formData.id,
						oldClass: this.formData.class,
						newClass: this.formClass,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.getToolClass()
					this.dialog1 = false
				}
			}
		},

		//编辑三级分类
		async updToolType() {
			if (this.formClass.length > 5) {
				this.$message("字数不能超过5个字！")
			} else {
				const res = await this.http
					.post("admin/tool/updToolType", {
						typeId: this.formData.id,
						oldType: this.formData.class,
						newType: this.formClass,
						class: this.parentClass,
					})
					.catch((err) => {
						console.log(err)
					})
				if (res.code == 1) {
					this.getToolClass()
					this.dialog1 = false
				}
			}
		},

		//删除工具二级分类
		async delToolClass(data) {
			this.$confirm("此操作将永久删除该二级分类, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/tool/delToolClass", {
							class: data.class,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						this.getToolClass()
					}
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					})
				})
		},

		//删除工具三级分类
		async delToolType(node) {
			this.$confirm("此操作将永久删除该三级分类, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/tool/delToolType", {
							class: node.parent.data.class,
							type: node.data.class,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						this.getToolClass()
					}
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					})
				})
		},

		//删除工具
		async delTool(row) {
			this.$confirm("此操作将永久删除该工具, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const res = await this.http
						.post("admin/tool/delTool", {
							toolId: row.id,
						})
						.catch((err) => {
							console.log(err)
						})
					if (res.code == 1) {
						this.getSkill()
					}
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					})
				})
		},

		//点击三级分类查询
		async getTool(node) {
			if (node) {
				this.class = node.parent.data.class
				this.type = node.data.class
				this.treeId = node.data.id
			}
			const res = await this.http
				.post(
					"admin/tool/getTool",
					{
						class: this.class,
						type: this.type,
						pages: this.pages,
						limit: this.limit,
					},
					false
				)
				.catch((err) => {
					console.log(err)
				})
			if (res.code == 1) {
				this.isSearch = false
				this.tableData = res.data.tool
				this.total = res.data.total
				this.$nextTick(() => {
					this.$refs["treeBox"].setCurrentKey(this.treeId)
				})
			}
		},
		//点击编辑
		handleClick(row) {
			this.$router.push({
				path: "toolDetail",
				query: { toolId: row.id },
			})
		},
		// 图片上传
		async imgSaveToUrl(event) {
			if (this.beforeUploadPicture(event.raw)) {
				var formData = new FormData()
				formData.append("img", event.raw)
				console.log(formData)
				this.http.upload("admin/upload/uploadImg", formData).then((res) => {
					if (res.code == 1) {
						this.form.img = res.data
						this.$message.success("上传成功")
					}
				})
			}
		},
		// 图片上传之前
		beforeUploadPicture(file) {
			const isJPG = file.type === "image/jpeg"
			const isPNG = file.type === "image/png"
			const isLt10M = file.size / 1024 / 1024 < 10
			if (!isJPG && !isPNG) {
				this.$message.error("上传图片只能是 JPG 或者 PNG 格式!")
			}
			if (isJPG && isPNG) {
				if (!isLt10M) {
					this.$message.error("上传图片大小不能超过 10MB!")
				}
			}
			return (isJPG || isPNG) && isLt10M
		},
	},
}
</script>

<style lang="scss" scoped>
.search {
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	border: rgb(175, 174, 174) solid 1px;
	border-radius: 50%;
}
/deep/ .avatar-uploader .el-upload {
	border: 1px dashed hsl(0, 0%, 85%);
	cursor: pointer;
	position: relative;
	overflow: hidden;
	background: rgb(204, 204, 204);
}
/deep/.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 66px; //上传图片的宽
	height: 66px; //上传图片的高
	line-height: 66px;
	text-align: center;
}
/deep/ .avatar {
	width: 66px; //图片回显的宽
	height: 66px; //图片回显的高
	display: block;
}
/deep/.el-tree-node__content {
	margin-top: 10px;
}
/deep/.el-breadcrumb::after,
.el-breadcrumb::before {
	display: inline-block;
}
/deep/.el-table .cell {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>
